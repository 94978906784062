import React, { Component } from 'react';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { useSendEmailReport } from '@Services/user';
import { Panel } from '@/ui';

const SendEmailReport = () => {
  const { execute, isLoading } = useSendEmailReport();
  return (
    <Panel
      header="Send General Email Report Manually"
      icon="farEnvelope"
      classNames={{ wrapper: 'max-w-[800px]' }}
      description="Manually send an email report for alarms set by the user."
    >
      <GlobalSearch
        loading={isLoading}
        icon="farUser"
        types={['user']}
        onSubmit={email =>
          email &&
          execute({
            data: {
              email: email as string,
            },
          })
        }
        name="send-email-report"
        size="middle"
        buttonIcon="farEnvelope"
        buttonLabel="Send"
      />
    </Panel>
  );
};

export default SendEmailReport;
