import * as FA from '@Constants/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

export type FAIcons = keyof typeof FA;

interface Props extends Omit<React.ComponentProps<typeof FontAwesomeIcon>, 'icon'> {
  name: FAIcons;
  style?: React.CSSProperties;
  onPress?: () => void;
  loading?: boolean;
}

const FAIcon = ({ name, ...rest }: Props) => {
  if (!name) return null;
  return (
    <FontAwesomeIcon
      {...rest}
      icon={rest.loading ? FA.fadLoader : FA[name]}
      spin={rest.loading}
      className={classNames(rest.onClick ? 'cursor-pointer' : '', rest.className)}
    />
  );
};

export default FAIcon;
