
import { useUpdateArtist, useUpdateArtistCountryAndCity,useUpdateAuthenticityRisk  } from '@Services/meta';
import { ARTIST_TAG_MAPPER } from '@Utils/constants';
import { compressFile, showErrorMessage } from '@Utils';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { EditableData, StaticData, States } from './constants';
import { useStates } from '@Hooks';
import { useGetEntity } from '@Services/search';
import { useUpdateTag } from '@Services/tag';

export interface EditArtistProps extends EditableData, StaticData {
  id: number;
  name: string;
}

export const useArtistInfo = (q: string) => {
  const { data: rawData, refetch: fetchData } = useGetEntity({
    data: {
      q,
    },
  });

  const data = rawData as EditArtistProps;

  const {
    states,
    onDispatchState,
    onChangeState,
    setAllStates: setStates,
    setState,
  } = useStates<States>(
    {
      ...data,
      image_file: undefined,
      override_for_artist_stage_legendary: data.override_stage === 'legendary',
      override_for_artist_stage_superstar: data.override_stage === 'superstar',
    },
    [data]
  );


  const { execute: updateArtist, isLoading } = useUpdateArtist();
  const { handleAddArtistGenreTag, handleDeleteArtistGenreTag, getLinkType } = useUpdateTag(
    'artist',
    data.id,
    () => {}
  );
  const { execute: updateArtistCountryAndCity } = useUpdateArtistCountryAndCity();
  const { execute: updateAuthenticityRisk } = useUpdateAuthenticityRisk();

  const onChangeInput = ({ target: { name, value } }) => setStates({ [name]: value });
  const onChangeCheckBox = (name: string) => setStates(old => ({ [name]: !old[name] }));
  const onChangeSelector = (name: string) => value => {
    setStates({ [name]: value });
  };
  const onChangeGPTSummary = ({ target: { name, value } }) => {
    setStates({ gpt_summary: { ...states.gpt_summary, [name]: value } });
  };

  const executeUpdateTags = () => {
    const executeUpdateTag = (tag: string) => {
      if (Boolean(states[tag]) === Boolean(data[tag])) return;
      if (states[tag]) {
        return handleAddArtistGenreTag(ARTIST_TAG_MAPPER[tag]);
      }
      return handleDeleteArtistGenreTag(
        ARTIST_TAG_MAPPER[tag],
        getLinkType(ARTIST_TAG_MAPPER[tag])
      );
    };

    return Object.keys(ARTIST_TAG_MAPPER).map(tag => executeUpdateTag(tag));
  };

  const executeUpdateCountryAndCity = () => {
    if (states.current_city_id)
      return updateArtistCountryAndCity({
        path: { id: data.id },
        data: { cityId: states.current_city_id, code2: states.code2 },
      });
  };

  const executeUpdateAuthenticityRisk = () => {
    if (states.authenticity_risk_overrides)
      return updateAuthenticityRisk({
        path: { id: data.id, target: 'cm_artist' },
        data: { values: states.authenticity_risk_overrides },
      });
  };

  const onSubmit = async () => {
    // Update metadata
    const metadata = {
      code2: states.code2,
      description: states.cm_artist_description,
      gender: data.hide_gender !== states.hide_gender && !states.hide_gender ? null : states.gender,
      pronoun:
        data.hide_pronoun !== states.hide_pronoun && !states.hide_pronoun
          ? null
          : states.pronoun_title,
      booking_agent: states.booking_agent,
      general_manager: states.general_manager,
      record_label: states.record_label,
      press_contact: states.press_contact,
      image_url: states.image_url,
      is_duplicate: states.is_duplicate ?? false,
      is_non_artist: states.is_non_artist ?? false,
      gpt_summary: states.gpt_summary,
      date_of_birth: states.date_of_birth,
      band: states.band ?? false,
      inactive: states.inactive === data?.inactive ? null : states.inactive,
    };

    // birthday can not be null if date_of_birth is already set
    if (data.date_of_birth && isEmpty(metadata.date_of_birth)) {
      showErrorMessage('Date of birth can not be removed if it is already set');
      return;
    }

    const nowAllowedToBeEmpty = ['date_of_birth', 'code2', 'gender', 'pronoun', 'inactive'];
    const objectTypeData = ['gpt_summary']; // formData not support object

    const formData = new FormData();
    Object.entries(metadata).forEach(([key, value]) => {
      if (objectTypeData.includes(key)) {
        return formData.append(key, JSON.stringify(value));
      }
      if (value === null && nowAllowedToBeEmpty.includes(key)) {
        return;
      }
      formData.append(key, value);
    });

    if (states.image_file) {
      formData.append('image', await compressFile(states.image_file as File));
      formData.delete('image_url');
    }

    await Promise.all([
      ...executeUpdateTags(),
      executeUpdateCountryAndCity(),
      updateArtist({
        query: { id: data.id },
        data: formData,
      }),
      executeUpdateAuthenticityRisk(),
    ]);

    fetchData();
  };

  useEffect(() => {
    // Reset when re-searching
    setStates({ ...data, image_file: undefined });
  }, [data]);

  return {
    onChangeState,
    onDispatchState,
    onChangeInput,
    onChangeCheckBox,
    onChangeSelector,
    onChangeGPTSummary,
    onSubmit,
    onChangeImage: setState('image_file'),
    isFetching: isLoading,
    states,
    setStates,
    props: data,
  };
};
