import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useUser } from '@Services/auth';
import Login from './screens/Login';
import { MENUS } from './routes/menus';
import { generateRoute as generateRoutePath, ROUTE_PATH } from '@Routes/path';
import { Layout } from '@Components/ui';

function App() {
  const { isAuthed } = useUser();
  const loginRoute = `${ROUTE_PATH.LOGIN}?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;

  return (
    <>
      <Switch>
        {MENUS.map(menu => {
          if (menu.items.length === 0) {
            return (
              <Route
                key={menu.name}
                path={menu.route}
                exact
                render={props =>
                  isAuthed ? <Layout>{menu.component}</Layout> : <Redirect to={loginRoute} />
                }
              />
            );
          }

          return menu.items.map(item => {
            return (
              <Route
                key={`${menu.name}-${item.name}`}
                path={generateRoutePath(menu.route, item.name)}
                render={props =>
                  isAuthed ? <Layout>{item.component}</Layout> : <Redirect to={loginRoute} />
                }
              />
            );
          });
        })}

        <Route exact path={ROUTE_PATH.LOGIN} component={Login} />
      </Switch>
    </>
  );
}

export default App;
