'use client';

import React from 'react';
import { SheetMenu } from './sheet-menu';
import { UserNav } from './user-nav';
import { ModeToggle } from './mode-toggle';
import { Button, Flex, Typography } from '..';
import useRoute from '@Routes/useRoute';
import { useUser } from '@Services/auth';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { cn } from '@/lib/utils';
import { SearchCommand } from '@Components/SearchCommand';

const NavTab = ({ className }: { className?: string }) => {
  const { currentItem, currentMenu, onChangeItem } = useRoute();

  return (
    <Flex gap={4} align="center" wrap className={cn(className, 'pl-6 sm:visible hidden')}>
      {currentMenu?.items?.map((item, i) => (
        <p
          className={cn(
            'text-sm text-gray-600 font-medium transition-colors hover:text-primary cursor-pointer',
            item.name === currentItem?.name && 'text-primary'
          )}
          onClick={() => onChangeItem(item.name)}
          key={i}
        >
          <Flex align="center">
            <FAIcon name={item.icon as FAIcons} />
            <p className="font-medium">{item.name}</p>
          </Flex>
        </p>
      ))}
    </Flex>
  );
};

export function Navbar() {
  const { currentRoute, currentMenu } = useRoute();
  const { userData } = useUser();

  return (
    <header className="top-0 z-10 w-full dark:shadow-secondary pt-3">
      <div className="mx-4 sm:mx-8 flex h-14 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
          {currentRoute === '/' ? (
            <Flex>
              <p className="text-muted-foreground text-xl font-medium">
                {new Date().getDay() === 5
                  ? '🎉 Happy Friday, '
                  : new Date().getHours() < 6
                  ? '😴 Good night, '
                  : new Date().getHours() < 12
                  ? '👋🏻 Good morning, '
                  : new Date().getHours() < 14
                  ? '🍔 Happy lunch, '
                  : new Date().getHours() < 18
                  ? '🌞 Good afternoon, '
                  : '🌟 Good evening, '}
                {userData?.name}!
              </p>
            </Flex>
          ) : (
            <>
              <p className="text-muted-foreground text-xl font-medium">{currentMenu?.name}</p>
              <NavTab className="hidden lg:flex" />
            </>
          )}
        </div>

        <div className="flex flex-1 items-center justify-end gap-4">
          <SearchCommand />
          {/* <ModeToggle /> */}
          <UserNav />
        </div>
      </div>
      <NavTab className="flex lg:hidden" />
    </header>
  );
}
