import React from 'react';
import ITEMS, { MenuItem } from './items';
import { FAIcons } from '@Components/ui/fa-icons';
import { ENTITY_ICON } from '@Constants/entity';
import { ONESHEET_PATH, ROUTE_PATH } from './path';
import { G_CLOUD_STORAGE } from 'resource';
import { useHistory } from 'react-router-dom';
import { Group } from './sidebar';
import { Home } from '@Components';
import { getRouteName } from './useRoute';

export const MENUS: MenuType[] = [
  {
    name: 'Home',
    icon: 'fadGrid2',
    route: ROUTE_PATH.HOME,
    component: <Home />,
    items: [],
  },
  {
    name: 'User Manager',
    icon: 'fadUser',
    route: ONESHEET_PATH.USER_MANAGER,
    items: ITEMS.ONESHEET_USER_MANAGER,
    group: 'Onesheet',
  },
  {
    name: 'Team Manager',
    icon: 'farPeople',
    route: ONESHEET_PATH.TEAM_MANAGER,
    items: ITEMS.ONESHEET_TEAM_MANAGER,
    group: 'Onesheet',
  },
  {
    name: 'Keywords',
    icon: 'fadSquareK',
    route: ROUTE_PATH.KEYWORDS,
    items: ITEMS.KEYWORDS,
    group: 'Chartmetric',
  },
  {
    name: 'User Manager',
    icon: 'fadUser',
    route: ROUTE_PATH.USER,
    items: ITEMS.USER,
    group: 'Chartmetric',
  },
  {
    name: 'Request Manager',
    icon: 'fadTableList',
    route: ROUTE_PATH.REQUESTS,
    items: ITEMS.REQUESTS,
    group: 'Chartmetric',
  },
  {
    name: 'Entity Data',
    icon: 'fadDatabase',
    route: ROUTE_PATH.CMDATA,
    items: ITEMS.CM_DATA,
    group: 'Chartmetric',
  },
  {
    name: 'Linking',
    icon: 'fadLink',
    route: ROUTE_PATH.LINKING,
    items: ITEMS.LINKING,
    group: 'Chartmetric',
  },
  {
    name: 'Artist',
    icon: ENTITY_ICON.ARTIST,
    route: ROUTE_PATH.ARTIST,
    items: ITEMS.ARTIST,
    group: 'Chartmetric',
  },
  {
    name: 'Track',
    icon: ENTITY_ICON.TRACK,
    route: ROUTE_PATH.TRACK,
    items: ITEMS.TRACK,
    group: 'Chartmetric',
  },

  {
    name: 'Genre & Mood',
    icon: ENTITY_ICON.GENRE,
    route: ROUTE_PATH.TAG,
    items: ITEMS.TAG,
    group: 'Chartmetric',
  },
  {
    name: 'Label',
    icon: ENTITY_ICON.LABEL,
    route: ROUTE_PATH.LABEL,
    items: ITEMS.LABEL,
    group: 'Chartmetric',
  },
  {
    name: 'Marketing',
    icon: 'fadHeart',
    route: ROUTE_PATH.CAMPAIGN,
    items: ITEMS.MARKETING,
    group: 'Chartmetric',
  },
  {
    name: 'Purchase',
    icon: 'fadCreditCard',
    route: ROUTE_PATH.PURCHASE,
    items: ITEMS.PURCHASE,
    group: 'Chartmetric',
  },
  {
    name: 'Restriction',
    icon: 'fadBan',
    route: ROUTE_PATH.RESTRICTION,
    items: ITEMS.RESTRICTION,
    group: 'Chartmetric',
  },
  {
    name: 'Utility',
    icon: 'fadWrench',
    route: ROUTE_PATH.UTILITY,
    items: ITEMS.UTILITY,
    group: 'Chartmetric',
  },

  {
    name: 'Logs',
    icon: 'fadHistory',
    route: ROUTE_PATH.LOGS,
    items: ITEMS.LOGS,
    group: 'Chartmetric',
  },
];

export type MenuGroup = 'Chartmetric' | 'Onesheet';

export const MENU_GROUP = [
  {
    name: 'Chartmetric',
    image: G_CLOUD_STORAGE.CHARTMETRIC_FULL_LOGO,
    size: {
      width: 130,
      height: 40,
    },
  },
  {
    name: 'Onesheet',
    image: G_CLOUD_STORAGE.ONESHEET,
    size: {
      width: 100,
      height: 30,
    },
  },
] satisfies {
  name: MenuGroup;
  image: string;
  size: { width: number; height: number };
}[];

export interface MenuType {
  name: string;
  icon: FAIcons;
  route: string;
  group?: MenuGroup;

  component?: JSX.Element;
  items: MenuItem[];
}

export const enumerateMenus = (group: MenuGroup) =>
  MENUS.filter(m => m.group === group).reduce<MenuType[]>((all, menu) => {
    all.push(menu);

    return all;
  }, []);

export const useCurrentRoute = () => {
  const history = useHistory();

  const route = `/${history.location.pathname.split('/')[1]}`;
  const menu = MENUS.find(menu => menu.route === route);
  const item = menu?.items.find(item => {
    return getRouteName(menu.route, item.name) === history.location.pathname;
  });

  return { route, menu, item };
};
