import React from 'react';
import { GenrePanelProps, GenrePanelProvider, useGenrePanel } from './context/GenrePanelContext';
import GenreOrder from './componetns/GnereOrderPanel';
import TagGroups from './componetns/GernePanel';
import { Flex, H4, Muted, Panel, Skeleton } from '@/ui';

function EditGenrePanel(props: GenrePanelProps) {
  return (
    <>
      <GenrePanelProvider {...props}>
        <GenreOrder />
        <TagGroups />
      </GenrePanelProvider>
    </>
  );
}
export default EditGenrePanel;