import { showErrorMessage } from '@Utils';
import { useEffect, useState, useRef } from 'react';
import { persistGetToUrl, mutateToUrl, useCache, useStore, axiosInstance } from './helpers';

const ENDPOINT = {
  LOGIN: {
    GOOGLE: '/loginGoogle',
    APPLE: '/loginApple',
    CM: '/login',
  },
  LOGOUT: '/logout',
  AUTH: '/user/auth',
  USER: '/user',
  RESEND_CODE: '/login/resend-code',
  PUSH_TOKEN: '/user/push-token',
  CHECK: '/admin/check',
};

type CMLogin = {
  username: string;
  password: string;
  code?: string;
};

export type User = {
  id: number;
  image_url: string | null;
  email: string;
  name: string;
  firstName: string;
  lastName: string;
  company: string;
  role: string;
  notificationEmailSettings: {
    sendEmail: boolean;
  };
  verified: boolean;
  trusted: boolean;
  ip_addresses: string[];
  source: string | null;
  register_date: string;
  emailReportConfigs: {
    notification: number;
    day_of_the_week: string;
    min_following_spotify_playlist_followers: number;
    sections: {
      general: {
        day_of_the_week: string;
        notification: number;
        min_following_spotify_playlist_followers: number;
      };
      tiktok: {
        notification: number;
      };
      noteworthy_insights: {
        dod_flag: boolean;
        wow_flag: boolean;
      };
    };
    frequency: {
      daily_general: boolean;
      weekly_general: boolean;
      weekly_trending: boolean;
    };
    playlist_additions: boolean;
    playlist_removals: boolean;
  };
  customerInfo: {
    id: string;
    is_deleted_account: boolean;
    account_balance: number;
    discount: string | null;
    description: string | null;
    tax_ids: {
      object: string;
      data: any[];
      has_more: boolean;
      total_count: number;
      url: string;
    };
    addOns: any[];
  };
  sessions: {
    last_date: string;
    avg_daily: number;
    total: number;
  };
  addresses: {
    address: {
      city: string | null;
      country: string;
      line1: string | null;
      line2: string | null;
      postal_code: string | null;
      state: string | null;
    } | null;
    shipping: any | null;
  };
  inTeam: boolean;
  churnInfo: {
    customer_email: string;
    plan: string;
    status: string;
    mrr: number;
    churned_on: string | null;
    activated_on: string | null;
    total_spend: number;
  } | null;
  ccLists: {
    cc: string | null;
    bcc: string | null;
  };
  refreshToken: {
    refresh_token: string | null;
  };
  user_hash: string;
  artistsPerAccount: number;
  teamInfo: {
    admin: boolean;
    verified: boolean;
    maxAccounts: number;
    artistsPerAccount: number;
    managerId: number;
    managerEmail: string;
  };
  subscription_level: number;
  subscription_type: string;
  subscriptionName: string | null;
  spotifyIntegrated: boolean;
  spotifyForArtistsPermissions: {
    isPermitted: boolean;
    isRestricted: boolean;
  };
  last_added_at: string;
  last_removed_at: string;
};

const useAuthLogin = mutateToUrl<{ data: CMLogin }>('POST', ENDPOINT.LOGIN.CM);

const useAuthLogout = persistGetToUrl<NonNullable<unknown>, any>(ENDPOINT.LOGOUT, {
  manual: true,
});

const useGetUser = persistGetToUrl<NonNullable<unknown>, User>(ENDPOINT.USER, {
  isPermanentData: true,
  retry: 0,
});

const useAuth = persistGetToUrl<NonNullable<unknown>, any>(ENDPOINT.AUTH, {
  isPermanentData: true,
  key: 'AUTH',
  retry: 0,
  manual: true,
});

export const useUser = () => {
  const broadcastChannelRef = useRef<BroadcastChannel | null>(null);
  const shouldFetchRef = useRef<boolean>(true);

  const { execute: executeCMLogin, isLoading: isLoadingCMLogin } = useAuthLogin();
  const { data: isAuthed } = useCache('IS_AUTHED');
  const {
    data: authData,
    refetch: fetchAuth,
    isLoading: isLoadingAuth,
    isFetching: isFetchingAuth,
    isFetched: isFetchedAuth,
  } = useAuth({
    disable: !shouldFetchRef.current,
    retry: 0,
    isPermanentData: true,
    manual: true,
  });
  const { data: userData } = useGetUser(
    {},
    {
      disable: !isAuthed,
    }
  );

  const updateCache = useStore();

  const { refetch: executeLogout } = useAuthLogout();

  const checkIsAdmin = async () => {
    return axiosInstance.get(ENDPOINT.CHECK);
  };

  const checkAdminUser = async () => {
    updateCache('IS_AUTHED', true);
    const checkAdmin = await checkIsAdmin();

    if (!checkAdmin) {
      showErrorMessage('You are not an admin');
      logout();
    }
  };

  useEffect(() => {
    if (isFetchedAuth) {
      const newIsAuthed = !!authData;
      if (isAuthed !== newIsAuthed) {
        updateCache('IS_AUTHED', newIsAuthed);
      }
    }
  }, [authData, isFetchedAuth, isAuthed]);

  useEffect(() => {
    broadcastChannelRef.current = new BroadcastChannel('auth_channel');
    const channel = broadcastChannelRef.current;

    channel.postMessage({
      type: 'REQUEST_AUTH_STATUS',
    });

    let timeoutId: NodeJS.Timeout;

    const messageHandler = (event: MessageEvent) => {
      if (event.data.type === 'REQUEST_AUTH_STATUS') {
        if (authData && isAuthed) {
          channel.postMessage({
            type: 'AUTH_STATUS_RESPONSE',
            isAuthed,
            authData,
          });
        }
      } else if (event.data.type === 'AUTH_STATUS_RESPONSE') {
        clearTimeout(timeoutId);
        shouldFetchRef.current = false;
        updateCache('IS_AUTHED', event.data.isAuthed);
        updateCache('AUTH', event.data.authData);
      } else if (event.data.type === 'AUTH_CHANGE') {
        updateCache('IS_AUTHED', event.data.isAuthed);
        updateCache('AUTH', event.data.authData);
      }
    };

    channel.addEventListener('message', messageHandler);

    timeoutId = setTimeout(() => {
      if (shouldFetchRef.current) {
        fetchAuth();
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      channel.removeEventListener('message', messageHandler);
      channel.close();
      broadcastChannelRef.current = null;
    };
  }, []);

  const logout = async () => {
    shouldFetchRef.current = false;

    await executeLogout();
    updateCache('AUTH', null);
    updateCache('IS_AUTHED', false);
    updateCache('USER', null);
    document.cookie = 'Cookie=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    if (broadcastChannelRef.current) {
      broadcastChannelRef.current.postMessage({
        type: 'AUTH_CHANGE',
        isAuthed: false,
        authData: null,
      });
    }

    await new Promise(resolve => setTimeout(resolve, 100));
  };

  const login = async (params: CMLogin) => {
    const request = () => {
      const { username, password, code } = params;
      return executeCMLogin({ data: { username, password, code } });
    };

    try {
      const response = await request();
      const statusCode = Number(response.status);

      if (statusCode === 200) {
        await checkAdminUser();
        if (broadcastChannelRef.current) {
          broadcastChannelRef.current.postMessage({
            type: 'AUTH_CHANGE',
            isAuthed: true,
            authData: response.data,
          });
        }
      }
    } catch {
      logout();
    }
  };

  return {
    login,
    authData,
    userData,
    isAuthed: isAuthed,
    isAutoLoggingIn: isLoadingAuth,
    isLoggingIn: isLoadingCMLogin || isLoadingAuth || isFetchingAuth,
    logout,
  };
};
