import React from 'react';
import ReactTable from 'react-table';
import { DataTable, AntdIcon } from '../../../../shared/Style';
import useModal from '../../../../hooks/useModal';
import { getFormattedLocalDate } from '../../../../utilities';
import { Button, Modal } from 'antd';

const COLUMNS = [
  {
    Header: 'IP Address',
    accessor: 'ip_address',
  },
  {
    Header: 'Last Login',
    accessor: 'last_login',
    Cell: ({ value }) => getFormattedLocalDate(value, true),
  },
  {
    Header: 'First Login',
    accessor: 'first_login',
    Cell: ({ value }) => getFormattedLocalDate(value, true),
  },
  {
    Header: 'Logged In Count',
    accessor: 'logged_count',
  },
];

function MatchedIPsModal({ value }) {
  const { closeModal, modalProps, showModal } = useModal();

  return (
    <>
      {value && <Button icon={<AntdIcon name="list" />} onClick={() => showModal()} />}

      <Modal
        okButtonProps={{ style: { display: 'none' } }}
        cancelText="Close"
        onCancel={closeModal}
        open={modalProps.show}
        title="Matched IP's"
      >
        <DataTable data={value ?? []} columns={COLUMNS} />
      </Modal>
    </>
  );
}

export default MatchedIPsModal;
