import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  GET_LABEL_GROUP: `/admin/get-label-group`,
  EDIT_LABEL_GROUP: `/admin/edit-label-group`,
  LABEL_ASSOCIATION: `/admin/label-association`,
  SEARCH_LABEL: `/label/search`,
};

export const useGetLabelGroup = persistGetToUrl<{
  data: {
    label?: string;
  };
}>(ENDPOINT.GET_LABEL_GROUP, {
  manual: true,
});
export const useEditLabelGroup = mutateToUrl<{
  query: {
    parentLabel: string;
    subLabel?: string;
  };
}>('PUT', ENDPOINT.EDIT_LABEL_GROUP);

export const useGetLabelAssociation = persistGetToUrl<
  {
    data: {
      q: string;
    };
  },
  {
    cm_artist: number;
    labels: {
      cm_artist: number;
      cm_label: string;
      cm_label_id: number;
      cm_sublabel: string;
      cm_sublabel_id: number;
      hidden: boolean;
      manual: boolean;
      type: 'prior' | 'current';
    }[];
  }
>(ENDPOINT.LABEL_ASSOCIATION);

export const useChangeHiddenLabel = mutateToUrl<{
  data: {
    cm_artist: number;
    hidden: boolean;
    label: number;
    manual: boolean;
    subLabel: number;
    type: 'prior' | 'current';
  };
}>('PUT', ENDPOINT.LABEL_ASSOCIATION);

export const useAddLabelAssociation = mutateToUrl<{
  data: {
    cm_artist: number;
    label: number;
    subLabel?: number;
    type: 'prior' | 'current';
    hidden: boolean;
    manual: boolean;
  };
}>('POST', ENDPOINT.LABEL_ASSOCIATION);

export const useSearchLabel = persistGetToUrl<
  {
    data: {
      label: string | number;
    };
  },
  Record<number, { name: string; scroe: number }>
>(ENDPOINT.SEARCH_LABEL);