import React from 'react';
import CampaignContentModal from './CampaignContentModal';
import { Checkbox, DatePicker, Flex, Select, useModal } from '@/ui';
import { Table } from '@Components';
import useCampaign from '../useCampaign';
import { Content } from '../types';
import { getUTCDate, getUTCDateString } from '@Utils/date';
import StripeCouponModal from './StripeCouponModal';
import { isEmpty } from 'lodash';

interface Props {
  includeExpiredDate?: boolean;
}

const AddCampaignSection = ({
  productId,
  priceId,
  coupon,
  enableCoupon,
  expiredDate,
  content,
  isLoading,
  getPlanProductOptions,
  getPlanPriceOptions,
  setAllStates,
  stripeProducts,
  includeExpiredDate,
}: ReturnType<typeof useCampaign> & Props) => {
  const { showModal, closeModal, modalProps } = useModal();

  const handleSelectProduct = value => {
    setAllStates({
      productId: value,
      priceId: '',
    });
  };

  const handleOnChange = name => value => {
    setAllStates({
      [name]: value,
    });
  };

  const handleSelectContent = (content: Content) => {
    setAllStates({
      content,
    });
  };

  const handleSelectCoupon = coupon => () => {
    closeModal();
    setAllStates({
      coupon,
      enableCoupon: true,
    });
  };

  const handleEnableCoupon = name => checked => {
    if (checked) {
      showModal();
    } else {
      setAllStates({
        [name]: checked,
        coupon: undefined,
      });
    }
  };

  return (
    <>
      <Table
        options={[
          {
            label: 'Plan *',
            component: (
              <Flex style={{ width: '100%' }} gap={2}>
                <Select
                  value={productId}
                  loading={isLoading}
                  placeholder="Select Product"
                  options={getPlanProductOptions()}
                  onChange={handleSelectProduct}
                />
                {!isEmpty(productId) && (
                  <Select
                    value={priceId}
                    loading={isLoading}
                    placeholder="Select Price"
                    options={getPlanPriceOptions()}
                    onChange={handleOnChange('priceId')}
                  />
                )}
              </Flex>
            ),
          },
          ...(includeExpiredDate
            ? [
                {
                  label: 'Campaign Expired Date *',
                  component: (
                    <DatePicker
                      timeZone="utc"
                      month={new Date()}
                      value={expiredDate ? getUTCDate(expiredDate) : undefined}
                      onChange={date => setAllStates({ expiredDate: getUTCDateString(date) })}
                    />
                  ),
                },
              ]
            : ([] as any)),
          {
            label: 'Coupon',
            component: (
              <Checkbox
                name="enableCoupon"
                onCheckedChange={handleEnableCoupon('enableCoupon')}
                checked={enableCoupon}
                disabled={isEmpty(priceId)}
              >
                {coupon
                  ? `${coupon.name}(${coupon.id})`
                  : isEmpty(priceId)
                  ? 'Please select plan'
                  : 'Not selected'}
              </Checkbox>
            ),
          },
          {
            label: 'Page Content',
            component: <CampaignContentModal onSelect={handleSelectContent} value={content} />,
          },
        ]}
      />
      <StripeCouponModal
        show={modalProps.show}
        closeModal={closeModal}
        selectedCoupon={coupon}
        onSelectCoupon={handleSelectCoupon}
        priceId={priceId}
        productId={productId}
        products={stripeProducts}
      />
    </>
  );
};

export default AddCampaignSection;
