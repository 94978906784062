import { usePagination } from '@Hooks';

import { addComma } from '@Utils/number';
import { add, capitalize } from 'lodash';
import React, { useMemo, useState } from 'react';
import COLUMNS from './Components/List/columns';
import { PLATFORM_FILTERS, SUPPORT_PLATFORMS, TABS, VIEWS } from './constants';
import {
  LinkCompletenessOptions,
  useGetLinkCompleteness,
  getLinkCompletenessCacheKey,
  useGetLinkCompletenessStatistics,
} from '@Services/linkCompleteness';
import Statistics from './Components/Statistics/Statistics';
import Filters from './Components/List/Filters';
import TableView from './Components/List/TableView';
import CardView from './Components/List/CardView';
import FAIcon from '@Components/ui/fa-icons';
import { ENTITY_ICON } from '@Constants/entity';
import { Badge, Flex, Muted, Panel, ToggleGroup } from '@/ui';
import Tabs from '@Shared/Style/Tabs';

const Content = ({ entity }: { entity: (typeof TABS)[number] }) => {
  const [view, setView] = useState<(typeof VIEWS)[number]>('table');
  const [state, setState] = useState<LinkCompletenessOptions>({
    tier: [1],
    completed: false,
    hasSpotifyURL: undefined,
    hasPandoraURL: undefined,
    hasInstagramURL: undefined,
    hasTiktokURL: undefined,
    hasYoutubeURL: undefined,
    hasShazamURL: undefined,
  });

  const pagination = usePagination({
    limit: 10,
  });

  const { limit, offset } = pagination;

  const params = {
    path: {
      target: entity,
    },
    data: {
      ...Object.keys(state).reduce((acc, key) => {
        if (state[key] !== 'all') {
          acc[key] = state[key];
        }
        return acc;
      }, {}),

      limit,
      offset,
    } as LinkCompletenessOptions & {
      limit: number;
      offset: number;
    },
  };

  const { data, isLoading } = useGetLinkCompleteness(params, {
    disable: params.data.tier?.length === 0,
  });

  const cacheKey = getLinkCompletenessCacheKey(params);

  const onChangeFilter = (key: string, value: any) => {
    setState(old => ({ ...old, [key]: value }));
  };

  return (
    <>
      <Statistics target={entity} state={state} onChangeFilter={onChangeFilter} />
      <Panel
        icon="farBallotCheck"
        header={
          <Flex gap={2} align="center">
            Link Completeness
            {data && <Badge color="blue">{addComma(data.total ?? 0)}</Badge>}
            <ToggleGroup
              type="single"
              options={[
                { label: 'Table', value: 'table' },
                { label: 'Card', value: 'card' },
              ]}
              value={view}
              onValueChange={value => {
                value && setView(value as 'card' | 'table');
              }}
            />
          </Flex>
        }
        aside={<Filters type={entity} state={state} onChange={onChangeFilter} />}
      >
        <Flex gap={1} vertical>
          {entity === 'artist' ? (
            <Flex gap={4}>
              <Flex align="center">
                <Muted>Verified by admin:</Muted>
                <FAIcon name="fasCheckCircle" color="blue" />
              </Flex>
              <Flex align="center">
                <Muted>Has link:</Muted>
                <FAIcon name="fasCheckCircle" color="green" />
              </Flex>
            </Flex>
          ) : (
            <Muted>Currently, we don't track the user who is updating the track link.</Muted>
          )}
          {view === 'table' ? (
            <TableView
              {...pagination}
              data={data}
              isLoading={isLoading}
              entity={entity}
              cacheKey={cacheKey}
            />
          ) : (
            <CardView
              {...pagination}
              data={data}
              isLoading={isLoading}
              entity={entity}
              cacheKey={cacheKey}
            />
          )}
        </Flex>
      </Panel>
    </>
  );
};

const LinkCompleteness = () => {
  return (
    <Tabs
      options={TABS.map(tab => ({
        icon: ENTITY_ICON[tab.toUpperCase()],
        title: capitalize(tab),
        content: <Content entity={tab} />,
      }))}
    />
  );
};
export default LinkCompleteness;
