import React, { Ref, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import uuid4 from 'uuid4';
import { isEmpty } from 'lodash';
import FAIcon, { FAIcons } from '../../components/ui/fa-icons';
import {
  Button,
  BUTTON_VARIANTS,
  CommandEmpty,
  Flex,
  Input,
  Muted,
  Skeleton,
  Textarea,
} from '@/ui';
import { cn } from '@/lib/utils';
import { VariantProps } from 'class-variance-authority';
import { useOnClickOut } from '@Hooks';

export type ActionInputSuggestionOption = {
  label: string;
  value: string | number;
};

interface Props {
  defaultValue?: string | number;
  value?: string | number;
  onChange?:
    | ((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void)
    | undefined;
  placeholder?: string;
  disabled?: boolean;
  description?: string;
  name?: string;
  isTextArea?: boolean;
  id?: string;
  height?: number;
  type?: 'text' | 'number' | 'email';
  icon?: FAIcons;
  clearAfterAction?: boolean;
  action?: {
    icon: FAIcons;
    content?: string;
    onClick: (
      inputElement: React.ChangeEvent<HTMLInputElement>,
      buttonElement: React.MouseEvent<HTMLElement, MouseEvent>,
      value: string | number
    ) => Promise<any> | void;
    className?: string;
    loading?: boolean;
    disabledEmpty?: boolean;
  } & VariantProps<typeof BUTTON_VARIANTS>;
  label?: {
    content: string;
    icon?: FAIcons;
  };
  variant?: 'default' | 'filled' | null;
  className?: string;
  validation?: (value: string | number | undefined) => string | boolean;
  maxLength?: number;
  suggestions?: {
    data: ActionInputSuggestionOption[];
    loading: boolean;
    renderItem: (item: ActionInputSuggestionOption) => React.ReactNode;
  };
}

function ActionInput({
  maxLength,
  defaultValue,
  value: parentValue,
  onChange,
  placeholder,
  disabled,
  description,
  name,
  isTextArea,
  id,
  label,
  height,
  type,
  action,
  icon,
  variant,
  className,
  validation,
  clearAfterAction,
  suggestions,
}: Props) {
  const inputId = useRef(id ?? uuid4());
  const [value, setValue] = useState(defaultValue);
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const closeDropdown = useCallback(() => {
    if (!isOpen) {
      return;
    }
    setIsOpen(false);
  }, [isOpen, setIsOpen]);

  useOnClickOut(closeDropdown, containerRef);

  useEffect(() => {
    if (suggestions?.data?.length || suggestions?.loading) setIsOpen(true);
  }, [suggestions?.data, suggestions?.loading]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onClickAction = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (validation && typeof validation(value) === 'string') {
      return;
    }

    const inputElement = document.getElementById(inputId.current) as HTMLInputElement;
    // eslint-disable-next-line no-unused-expressions
    action?.onClick &&
      Promise.resolve(
        action.onClick(
          {
            target: inputElement,
          } as React.ChangeEvent<HTMLInputElement>,
          e,
          document.getElementById(inputId.current)?.['value']
        )
      ).finally(() => {
        if (clearAfterAction) setValue('');
      });
  };

  return (
    <div className={classNames('ActionInput', className, 'max-w-[1280px] relative')}>
      {isTextArea ? (
        <Textarea
          id={id}
          name={name}
          value={parentValue ?? value}
          style={{ width: '100%', height }}
          onChange={e => onChange?.(e) ?? setValue(e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
        />
      ) : (
        <Flex vertical>
          {label && (
            <Muted>
              {label.icon && <FAIcon name={label.icon} />}
              {label.content}
            </Muted>
          )}
          <Flex gap={2}>
            <Flex vertical gap={2} style={{ width: '100%' }}>
              <Input
                onClick={() => {
                  suggestions?.data?.length && setIsOpen(true);
                }}
                maxLength={maxLength}
                variant={variant}
                type={type ?? 'text'}
                disabled={disabled}
                name={name}
                placeholder={placeholder}
                value={parentValue ?? value}
                onChange={e => {
                  // eslint-disable-next-line no-unused-expressions
                  onChange?.(e) ?? setValue(e.target.value);
                }}
                id={inputId.current}
                prefix={icon && <FAIcon color="lightGray" name={icon} />}
              />
              {validation && typeof validation(value) === 'string' && (
                <p className="text-red-500">{validation(value)}</p>
              )}
            </Flex>
            {action && (
              <Button
                {...action}
                id={`${inputId.current}-button`}
                className={cn(action.className)}
                icon={<FAIcon name={action.icon} />}
                onClick={onClickAction}
                loading={action.loading}
                disabled={
                  (action.disabledEmpty && isEmpty(value)) ||
                  (validation && typeof validation(value) === 'string')
                }
              >
                {action.content}
              </Button>
            )}
          </Flex>
        </Flex>
      )}
      {description && <Muted>{description}</Muted>}
      <ul
        ref={containerRef}
        className={cn(
          'absolute top-full left-0 w-full bg-card shadow-md rounded-lg pt-0 p-2 z-50',
          !isOpen && 'opacity-0 pointer-events-none'
        )}
      >
        {isOpen && suggestions && (
          <>
            <Muted className="p-3">Suggestion</Muted>
            {suggestions?.loading ? (
              <Flex wrap>
                {Array.from({ length: 3 }).map((_, index) => (
                  <Skeleton className="w-24 h-8" key={index} />
                ))}
              </Flex>
            ) : suggestions?.data?.length === 0 ? (
              <CommandEmpty className="my-10" />
            ) : (
              suggestions?.data?.map((item, index) => (
                <Button
                  variant="ghost"
                  onClick={e => {
                    closeDropdown();
                    setValue(item.value);
                    onClickAction(e);
                  }}
                  key={index}
                  asChild
                >
                  <li className="cursor-pointer">{suggestions?.renderItem(item)}</li>
                </Button>
              ))
            )}
          </>
        )}
      </ul>
    </div>
  );
}

export default React.memo(ActionInput);
