import { useStates } from '@Hooks';
import { User } from '@Services/auth';
import {
  TeamManager,
  useAddTeamMember,
  useDeleteTeamMember,
  useGetTeamMembers,
  useUpdateTeamMember,
} from '@Services/team';
import { AntdTable, SearchInput } from '@Shared';
import { AntdIcon, Panel } from '@Shared/Style';
import { getFormattedLocalDate, getStripeCustomerUrl } from '@Utils';
import { Button, Checkbox, Typography } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';

interface Props {
  data: User[];
  type: 'current' | 'past';
  manager: TeamManager;
}

const AdminCell = ({ value, original }: { value: boolean; original: User }) => {
  const { execute } = useUpdateTeamMember();
  const [checked, setChecked] = React.useState(value);

  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = e => {
    const newState = e.target.checked;
    execute({
      data: {
        admin: newState,
        memberUserId: original.id,
        teamManagerUserId: original.teamInfo.managerId,
        verified: original.teamInfo.verified,
      },
    });
    original.teamInfo.admin = newState;
    setChecked(newState);
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};
const JoinedCell = ({ value, original }: { value: boolean; original: User }) => {
  const { execute } = useUpdateTeamMember();
  const [checked, setChecked] = React.useState(value);
  useEffect(() => {
    setChecked(value);
  }, [value]);

  const handleChange = e => {
    const newState = e.target.checked;
    execute({
      data: {
        admin: original.teamInfo.admin,
        memberUserId: original.id,
        teamManagerUserId: original.teamInfo.managerId,
        verified: newState,
      },
    });
    original.teamInfo.verified = newState;
    setChecked(newState);
  };

  return <Checkbox checked={checked} onChange={handleChange} />;
};

const RestoreCell = ({ original, extraData }: { original: User; extraData: TeamManager }) => {
  const { execute, isLoading } = useAddTeamMember();
  const { refetch } = useGetTeamMembers({
    data: {
      q: extraData.email,
    },
  });
  return (
    <Button
      className="green"
      loading={isLoading}
      icon={<AntdIcon name="plus" />}
      onClick={() => {
        execute({
          data: {
            memberEmail: original.email,
            teamManagerUserId: extraData.id,
          },
        }).then(() => refetch());
      }}
    />
  );
};

const DeleteCell = ({ original, extraData }: { original: User; extraData: TeamManager }) => {
  const { execute, isLoading } = useDeleteTeamMember();
  const { refetch } = useGetTeamMembers({
    data: {
      q: extraData.email,
    },
  });
  return (
    <Button
      loading={isLoading}
      className="red"
      icon={<AntdIcon name="delete" />}
      onClick={() => {
        execute({
          query: {
            memberUserId: original.id,
            teamManagerUserId: extraData.id,
          },
        }).then(() => refetch());
      }}
    />
  );
};

const Members = ({ data, type, manager }: Props) => {
  const [filteredData, setFilteredData] = useState<undefined | any[]>(undefined);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  return (
    <Panel title={`${capitalize(type)} Members ${type === 'current' ? `(${data?.length})` : ''}`}>
      <SearchInput originalData={data} onChange={setFilteredData} />
      <br />
      <AntdTable
        extraData={manager}
        data={filteredData}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Verified Email',
            accessor: 'verified',
            Cell: ({ value }) => value && <AntdIcon name={'checkCircle'} color="green" />,
            width: 50,
          },
          {
            Header: 'Registered At',
            accessor: 'register_date',
            Cell: ({ value }) => <div>{getFormattedLocalDate(value)}</div>,
            width: 100,
          },
          {
            Header: 'Added At',
            accessor: 'last_added_at',
            Cell: ({ value }) => <div>{getFormattedLocalDate(value)}</div>,
            width: 100,
          },
          ...(type === 'past'
            ? ([
                {
                  Header: 'Removed At',
                  accessor: 'last_removed_at',
                  Cell: ({ value }) => <div>{getFormattedLocalDate(value)}</div>,
                },
                {
                  Header: 'Restore',
                  Cell: RestoreCell,
                },
              ] as any)
            : [
                {
                  Header: 'Admin',
                  accessor: 'teamInfo.admin',
                  Cell: AdminCell,
                  width: 50,
                },
                {
                  Header: 'Joined',
                  accessor: 'teamInfo.verified',
                  Cell: JoinedCell,
                  width: 50,
                },
                {
                  Header: 'Delete',
                  Cell: DeleteCell,
                },
              ]),
          {
            Header: 'Stripe ID',
            accessor: 'customerInfo.id',
            Cell: ({ value }) => (
              <Typography.Link href={getStripeCustomerUrl(value)}>{value}</Typography.Link>
            ),
            width: 150,
          },
        ]}
      />
    </Panel>
  );
};

export default Members;
