import {
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  Button,
  Flex,
  CommandSeparator,
  CommandTag,
} from '@/ui';
import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import { enumerateMenus } from '@Routes/menus';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import React, { useEffect, useState } from 'react';
import { useStore } from 'zustand';
import { useHistory } from 'react-router-dom';
import { getRouteName } from '@Routes/useRoute';

export function SearchCommand() {
  const { group } = useStore(useSidebarToggle, state => state);
  const menus = enumerateMenus(group);
  const [open, setOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen(open => !open);
      }
    };
    document.addEventListener('keydown', down);
    return () => document.removeEventListener('keydown', down);
  }, []);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="ghost"
        className="bg-white dark:bg-black text-muted-foreground border border-input"
        icon={<FAIcon name="farSearch" />}
      >
        <Flex>
          Search features...
          <code className="bg-muted border border-input px-2 rounded-lg text-xs">⌘ K</code>
        </Flex>
      </Button>
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput placeholder="Type a command or search..." />
        <CommandList className="max-h-[50vh] min-h-[300px]">
          <CommandEmpty>No results found.</CommandEmpty>
          {menus.map(menu => (
            <>
              <CommandGroup key={menu.name} heading={menu.name}>
                {menu.items.map(item => (
                  <CommandItem
                    className="flex-row justify-between cursor-pointer"
                    key={item.name}
                    value={item.keywords?.join(',') + ',' + item.name}
                    onSelect={() => history.push(getRouteName(menu.route, item.name))}
                  >
                    <Flex gap={1} align="center">
                      <FAIcon
                        name={item.icon as FAIcons}
                        className="mr-2 !w-4 !h-4 text-muted-foreground"
                      />
                      {item.name}
                    </Flex>
                    <Flex justify="end">
                      {item.keywords &&
                        item.keywords
                          .slice(0, 5)
                          .map(keyword => <CommandTag>{keyword}</CommandTag>)}
                    </Flex>
                  </CommandItem>
                ))}
              </CommandGroup>
              <CommandSeparator />
            </>
          ))}
        </CommandList>
      </CommandDialog>
    </>
  );
}
