import React, { Component } from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import DataTableSearch from '../../../shared/Table/SearchInput';
import useRequestTable from '../../../hooks/useRequstTable';
import {
  useGetArtistIdentityChangeRequest,
  useSubmitArtistIdentityChangeRequest,
} from '../../../services/request';
import RequestSubmitButton from '../../../shared/Table/SubmitModal';
import { generateCmUrl, generateInternalLink } from '../../../utilities';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { Panel, Badge, Checkbox, Separator, Flex, Radio } from '@/ui';

const SUBMIT_MODAL_COLUMNS = [
  {
    Header: 'Artist',
    accessor: 'name', // String-based value accessors!
    Cell: ({ value }) => <span>{capitalize(value)}</span>,
  },
  {
    Header: 'Id',
    accessor: 'cm_artist',
  },
  {
    Header: 'Gender Change',
    Cell: ({ original }) =>
      original.old_gender_title !== original.gender_title ? (
        <span>{`${original.old_gender_title} → ${original.gender_title}`}</span>
      ) : (
        ''
      ),
  },
  {
    Header: 'Pronoun Change',
    Cell: ({ original }) =>
      original.old_pronoun !== original.pronoun ? (
        <span>{`${original.old_pronoun} → ${original.pronoun}`}</span>
      ) : (
        ''
      ),
  },
  {
    Header: 'Band Change',
    Cell: ({ original }) =>
      original.old_band !== original.band ? (
        <span>{`${original.old_band} → ${original.band}`}</span>
      ) : (
        ''
      ),
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
  },
];

const COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  data,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
}): Column<any>[] => [
  {
    Header: 'Artist',
    accessor: 'name', // String-based value accessors!
    Cell: props => (
      <a href={generateCmUrl('artist', { id: props.original.cm_artist })}>
        {capitalize(props.value)}
      </a>
    ),
    width: 50,
  },
  {
    Header: 'Gender',
    formatter: (_, original) =>
      original.old_gender_title !== original.gender_title &&
      `${original.old_gender_title} → ${original.gender_title}`,
  },
  {
    Header: 'Pronoun',
    formatter: (_, original) =>
      original.old_pronoun !== original.pronoun && `${original.old_pronoun} → ${original.pronoun}`,
  },
  {
    Header: 'Band',
    formatter: (_, original) =>
      original.old_band !== original.band && `${original.old_band} → ${original.band}`,
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
    Cell: props =>
      props.value && (
        <Link
          to={generateInternalLink('user/user-profile', {
            qs: props.value,
          })}
        >
          {props.value}
        </Link>
      ),
  },
  {
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Approve
        <Checkbox
          checked={approves.length === data?.length && data?.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
    accessor: 'id',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value)}
          onClick={() => onClickApprove(value)}
        />
      </div>
    ),
  },
  {
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Decline
        <Checkbox
          checked={declines.length === data?.length && data?.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
    accessor: 'id',
    Cell: ({ value }) => (
      <div style={{ textAlign: 'center' }}>
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value)}
          onClick={() => onClickDecline(value)}
        />
      </div>
    ),
  },
];

function URLUpdateRequestPanel() {
  const {
    data,
    isLoading,
    state,
    onSearch,
    onClickApprove,
    onClickDecline,
    submit,
    isSubmitting,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    selected,
  } = useRequestTable(useGetArtistIdentityChangeRequest, useSubmitArtistIdentityChangeRequest);

  const onClickSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } })
    );

  return (
    <Panel
      icon="farVenusMars"
      aside={<DataTableSearch originalData={data} onChange={onSearch} />}
      header={
        <Flex align="center" gap={2}>
          Artist Identity Change Request
          <Badge color="blue">{data?.length ?? '0'}</Badge>
        </Flex>
      }
      classNames={{
        footer: 'justify-center',
      }}
      footer={
        <RequestSubmitButton
          columns={SUBMIT_MODAL_COLUMNS}
          onClickSubmit={onClickSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      }
    >
      <AntdTable
        loading={isLoading}
        key="table"
        data={!state.filteredData ? data : state.filteredData}
        columns={COLUMNS({
          onClickApprove,
          onClickDecline,
          approves: state.approves,
          declines: state.declines,
          data,
          onClickSelectApproveAll,
          onClickSelectDeclineAll,
        })}
      />
    </Panel>
  );
}

export default React.memo(URLUpdateRequestPanel);
