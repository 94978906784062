import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useUser } from '@Services/auth';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { Button, Flex, Typography, Input, Separator, Panel } from '@/ui';
import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import { AntdIcon } from '@Shared/Style';
import { enumerateMenus } from '@Routes/menus';
import { G_CLOUD_STORAGE } from 'resource';
import Intercom from './Intercom';

function Home() {
  const { group } = useSidebarToggle();

  return (
    <main>
      <Intercom />
      <Flex gap={12} vertical className="mt-4">
        <Flex vertical gap={4}>
          <Panel
            classNames={{
              content:
                'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-5',
              header: 'justify-between',
            }}
            header={'Menus'}
          >
            {enumerateMenus(group).map(
              (menu, index) =>
                menu.name !== 'Home' && (
                  <Flex key={index} className="w-full" vertical gap={3}>
                    <div className="flex items-center space-x-2 bg-decorative px-4 py-2 rounded-md text-decorative-foreground text-sm">
                      <FAIcon name={menu.icon} />
                      <p>{menu.name}</p>
                    </div>
                    <div className="gap-1 flex flex-col px-5">
                      {menu.items &&
                        menu.items.map(item => (
                          <Button
                            variant="ghost"
                            asChild
                            key={item.name}
                            className="w-full flex flex-row items-center gap-1 justify-start"
                          >
                            <Link
                              className="font-normal text-foreground"
                              style={{
                                alignItems: 'center',
                              }}
                              to={`${menu.route}/${item.name.toLowerCase().replaceAll(' ', '-')}`}
                            >
                              <FAIcon name={item.icon as FAIcons} />
                              <span className="truncate">{item.name}</span>
                              {item.labelSuffix}
                              {item.isNew && (
                                <img
                                  alt="new"
                                  style={{ marginLeft: 5, width: 30 }}
                                  src={G_CLOUD_STORAGE.NEW_ICON}
                                />
                              )}
                            </Link>
                          </Button>
                        ))}
                    </div>
                  </Flex>
                )
            )}
          </Panel>
        </Flex>
      </Flex>
    </main>
  );
}

export default Home;
