import { useGetUserTeam } from '@Services/Onesheet/team';
import { ActionInput, Panel, Table } from '@Shared/Style';
import ResponsiveSection from '@Shared/Style/ResponsiveSection';
import { Divider } from 'antd';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import useURLParams from '@Hooks/useURLParams';
import Permission from './components/Permission';
import Team from './components/Team';

function SearchUser() {
  const { loading, data, refetch, fetch } = useGetUserTeam();

  const { params, setParams } = useURLParams({
    qs: value => fetch(value),
  });

  return (
    <>
      <ActionInput
        action={{
          icon: 'farSearch',
          onClick: e => {
            setParams({ qs: e.target.value });
          },
          content: 'Search',
          disabledEmpty: true,
          loading,
        }}
        variant="filled"
        placeholder="Search User"
        icon="farSearch"
        className="top-search-box"
        defaultValue={params.qs}
      />
      {data?.teamId ? (
        <>
          <Divider />
          <Panel title="User">
            <ResponsiveSection>
              <Table
                title="Info"
                options={[
                  {
                    label: 'Email',
                    value: data?.email,
                  },
                  {
                    label: 'User ID',
                    value: data?.userId,
                  },
                  {
                    label: 'Team ID',
                    value: data?.teamId,
                  },
                  {
                    label: 'User Name',
                    value: data?.userName,
                  },
                  {
                    label: 'Is Admin',
                    component: data?.isAdmin ? <Icon color="blue" name="check" /> : '',
                  },
                ]}
              />
              <Permission userId={data.userId} teamId={data.teamId} />
            </ResponsiveSection>
          </Panel>
          <Team key={data.teamId} title="Team" teamId={data.teamId} refetchUser={refetch} />
        </>
      ) : (
        data && (
          <>
            <Divider />
            <Panel title="User">
              <ResponsiveSection>
                <Table
                  title="Info"
                  options={[
                    {
                      label: 'Email',
                      value: data?.email,
                    },
                    {
                      label: 'User ID',
                      value: data?.userId,
                    },
                    {
                      label: 'User Name',
                      value: data?.userName,
                    },
                    {
                      label: 'Registered',
                      component: data?.userId ? (
                        <Icon color="blue" name="check" />
                      ) : (
                        <Icon color="red" name="x" />
                      ),
                    },
                  ]}
                />
                {data?.invitations?.map(invitation => (
                  <Table
                    title="Invitation"
                    options={[
                      {
                        label: 'Team ID',
                        value: invitation.teamId,
                      },
                      {
                        label: 'Team Name',
                        value: invitation.teamName,
                      },
                      {
                        label: 'Invited By',
                        value: invitation.invitedBy,
                      },
                      {
                        label: 'Permissions',
                        value: invitation.permissions,
                      },
                    ]}
                  />
                ))}
              </ResponsiveSection>
            </Panel>
            {data?.invitations?.map(invitation => (
              <Team
                key={data.teamId}
                title="Invited Team"
                teamId={invitation.teamId}
                refetchUser={refetch}
              />
            ))}
          </>
        )
      )}
    </>
  );
}
export default SearchUser;
