import { AntdIcon, Panel } from '@Shared/Style';
import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Button, Divider, Flex, message, Segmented, Typography, Upload } from 'antd';
import { API_BASED_URL } from '@Services/helpers';
import { generateCmUrl, generateInternalLink, showErrorMessage, showInfoMessage } from '@Utils';
import { AntdTable, SearchInput } from '@Shared';
import { Link } from 'react-router-dom';
import FAIcon from '@Components/ui/fa-icons';

const { Dragger } = Upload;

interface Item {
  isrc: string;
  message: string;
  status: string;
  url: string;
  cm_track?: number;
  track_name: string;
}
interface Response {
  succeeded: Item[];
  failed: Item[];
}

const BulkLinking = () => {
  const [response, setResponse] = useState<Record<string, Response>>();
  const [filteredData, setFilteredData] = useState<Item[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<string>('');

  return (
    <Panel
      icon="farLink"
      title="Bulk Linking (Track)"
      description={
        <>
          Only support <code>.xlsx</code> file format having <code>ISRC</code> and <code>URL</code>{' '}
          columns. Please check the template (on the top right) for more details.
        </>
      }
      extra={
        <Button
          className="green"
          type="primary"
          href={
            'https://storage.googleapis.com/cm-app-assets/request/chartmetric-bulk-url-update-example.xlsx'
          }
          icon={<FAIcon name="farFileCsv" />}
        >
          Download Template
        </Button>
      }
    >
      <Dragger
        name="file"
        multiple={false}
        listType="picture"
        accept=".xlsx"
        withCredentials
        action={API_BASED_URL + '/admin/track/bulk-excel'}
        onChange={info => {
          const { status, response } = info.file;
          if (status === 'uploading') {
            setLoading(true);
          }
          if (status === 'done') {
            setLoading(false);
            showInfoMessage(`${info.file.name} file uploaded successfully.`);
            setSelected(info.file.name);
            setResponse(old => {
              return {
                ...old,
                [info.file.name]: response.obj,
              };
            });
          } else if (status === 'error') {
            showErrorMessage(`${info.file.name} file upload failed.`);
          }
        }}
        onRemove={file => {
          setResponse(old =>
            Object.fromEntries(Object.entries(old ?? {}).filter(([key]) => key !== file.name))
          );
          setFilteredData(undefined);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
      <br />
      <br />
      {Object.keys(response ?? {}).length > 0 && (
        <>
          <Typography.Title level={5}>Uploaded Files</Typography.Title>
          <Segmented
            style={{ marginBottom: 16 }}
            options={Object.keys(response ?? {}).map(key => ({
              label: key,
              value: key,
            }))}
            value={selected}
            onChange={setSelected}
          />
        </>
      )}
      {response?.[selected] && (
        <Flex vertical>
          <Typography.Title level={5}>
            Result ({response?.[selected].succeeded.length} succeeded,{' '}
            {response?.[selected].failed.length} failed )
          </Typography.Title>
          <SearchInput
            onChange={setFilteredData}
            originalData={
              [
                ...response?.[selected].failed.map(e => ({ ...e, status: 'error' })),
                ...response?.[selected].succeeded.map(e => ({ ...e, status: 'success' })),
              ] as Item[]
            }
          />
          <br />
          <AntdTable
            loading={loading}
            data={filteredData}
            columns={[
              {
                Header: 'Status',
                accessor: 'status',
                Cell: ({ value, original }) => (
                  <Flex gap={4}>
                    <Typography.Text
                      type={
                        value === 'success'
                          ? 'success'
                          : original.message ===
                            'Track is now updating and will be processed shortly.'
                          ? 'warning'
                          : 'danger'
                      }
                    >
                      <AntdIcon name={value === 'success' ? 'checkCircle' : 'closeCircle'} />{' '}
                      {original.message}
                    </Typography.Text>
                    {original.message === 'Track not found' && (
                      <Link to="/track/create-track" target="_blank">
                        <Button type="link">Create Track</Button>
                      </Link>
                    )}
                  </Flex>
                ),
              },
              {
                Header: 'URL',
                accessor: 'url',
                Cell: ({ value }) => (
                  <Typography.Link href={value} target="_blank" rel="noreferrer">
                    {value}
                  </Typography.Link>
                ),
              },
              {
                Header: 'Track',
                accessor: 'track_name',
                Cell: ({ value, original }) => (
                  <Flex align="center">
                    <Typography.Link
                      target="_blank"
                      href={generateCmUrl('track', { id: original.cm_track })}
                    >
                      {value}
                    </Typography.Link>
                    <Button
                      icon={<FAIcon name="farSearch" color="black" />}
                      type="link"
                      target="_blank"
                      href={generateInternalLink('cmdata/edit-entity', {
                        q: generateCmUrl('track', { id: original.cm_track }),
                      })}
                    />
                  </Flex>
                ),
              },
              {
                Header: 'ISRC',
                accessor: 'isrc',
              },
            ]}
          />
        </Flex>
      )}
    </Panel>
  );
};

export default BulkLinking;
