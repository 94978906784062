import { Button, Flex, Panel } from '@/ui';
import { useTransferFavorites } from '@Services/user';
import { GlobalSearch } from '@Shared';
import FAIcon from '@Components/ui/fa-icons';
import React, { Component } from 'react';

const TransferFavorites = () => {
  const { execute, isLoading } = useTransferFavorites();
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');

  return (
    <Panel
      header="Transfer Account's Favorites To Another Account"
      icon="farArrowRightArrowLeft"
      classNames={{ footer: 'justify-center', wrapper: 'max-w-[1200px]' }}
    >
      <Flex gap={4} justify="center">
        <GlobalSearch
          size="large"
          placeholder="User Email Address"
          name="from"
          onChange={e => setFrom(e)}
          label="From"
          icon="farUser"
          types={['user']}
          style={{ width: '100%' }}
        />
        <FAIcon name="farArrowRight" className="text-gray-500 pt-4" />
        <GlobalSearch
          placeholder="User Email Address"
          name="to"
          onChange={e => setTo(e)}
          label="To"
          icon="farUser"
          types={['user']}
          size="large"
          style={{ width: '100%' }}
        />
        <Button
          className="mt-5"
          icon={<FAIcon name="fasCheckCircle" />}
          loading={isLoading}
          variant="submit"
          onClick={() => {
            execute({
              data: {
                from_email: from,
                to_email: to,
              },
            }).then(() => {
              setFrom('');
              setTo('');
            });
          }}
        >
          Transfer
        </Button>
      </Flex>
    </Panel>
  );
};

export default TransferFavorites;
