import React, { Component, useState } from 'react';
import ReactTable from 'react-table';
import { Select, Flex, Input, Button } from 'antd';
import { Panel, ActionInput, Table, DataTable } from '../../../shared/Style';
import {
  useSetApiLimitOverride,
  useGetApiLimitOverride,
  useDeleteApiLimitOverride,
} from '../../../services/restriction';
import DataTableSearch from '../../../shared/Table/SearchInput';
import useStates from '../../../hooks/useStates';
import FAIcon from '@Components/ui/fa-icons';
import { AntdTable } from '@Shared';

function DeleteButton({ email, refetch }) {
  const { execute, isLoading } = useDeleteApiLimitOverride();

  const deleteEmail = email => {
    execute({ query: { email } }).then(() => refetch({}));
  };

  return (
    <Button
      loading={isLoading}
      icon={<FAIcon name="farTrash" />}
      className="red"
      onClick={() => deleteEmail(email)}
    />
  );
}

const getColumns = refetch => [
  {
    Header: 'Email',
    accessor: 'email',
    width: 200,
    Cell: ({ value }) => (
      <a target="_blank" rel="noopener noreferrer" href={`/user/search-user?qs=${value}`}>
        {value}
      </a>
    ),
  },
  {
    Header: 'User ID',
    accessor: 'id',
  },
  {
    Header: 'Max',
    accessor: 'max',
  },
  {
    Header: 'Duration',
    accessor: 'duration',
  },
  {
    Header: 'Stripe Max',
    accessor: 'original_max',
  },
  {
    Header: 'Stripe Duration',
    accessor: 'original_duration',
  },
  {
    Header: 'Actions',
    accessor: 'email',
    width: 100,
    Cell: props => (
      <Flex justify="center">
        <DeleteButton email={props.value} refetch={refetch} />
      </Flex>
    ),
  },
];

const DURATION_OPTIONS = [
  { label: 'Per-Second', value: '1s' },
  { label: 'Per-Minute', value: '1m' },
  { label: 'Per-Hour', value: '1h' },
  { label: 'Per-Day', value: '1d' },
];

function APILimitOverrides() {
  const { data, refetch, isLoading } = useGetApiLimitOverride();
  const { execute: fetchAddDomain, isLoading: isAddingDomain } = useSetApiLimitOverride();
  const [filteredData, setFilteredData] = useState();
  const { states, setAllStates } = useStates<{
    email: string;
    max: number;
    duration: string;
  }>({
    email: '',
    max: 0,
    duration: 'm',
  });

  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  const onChangeInput = ({ target: { id, value } }) => {
    setAllStates({ [id]: value });
  };

  return (
    <>
      <Table
        panelStyle
        title="Add Limit Override"
        options={[
          {
            label: 'Email',
            labelIcon: 'mail',
            component: <Input id="email" value={states.email} onChange={onChangeInput} />,
          },
          {
            label: 'Max Count',
            labelIcon: 'number',
            component: <Input id="max" type="number" value={states.max} onChange={onChangeInput} />,
          },
          {
            label: 'Duration',
            labelIcon: 'clock',
            component: (
              <Select
                onChange={value => setAllStates({ duration: value })}
                options={DURATION_OPTIONS}
                value={states.duration ?? ''}
              />
            ),
          },
        ]}
        extra={
          <Button
            loading={isAddingDomain}
            onClick={() => fetchAddDomain({ query: states }).then(() => refetch({}))}
            className="green"
            type="primary"
          >
            Set API Limit Override
          </Button>
        }
      />

      <Panel title="Limit Overrides">
        <DataTableSearch originalData={data} onChange={onSearch} />
        <br />
        <AntdTable
          data={filteredData || data}
          columns={getColumns(refetch)}
          loading={isLoading || isAddingDomain}
        />
      </Panel>
    </>
  );
}
export default APILimitOverrides;
