import { useHistory } from 'react-router-dom';
import { MENUS } from './menus';

export const getRouteName = (route: string, subRoute: string) =>
  `${route}/${subRoute.toLowerCase().replaceAll(' ', '-')}`;

const useRoute = () => {
  const currentRoute =
    window.location.pathname.endsWith('/') && window.location.pathname.length > 1
      ? window.location.pathname.slice(0, -1)
      : window.location.pathname;
    

  const getItemRoute = (menuRoute: string, itemName: string) => getRouteName(menuRoute, itemName);

  const currentMenu = MENUS.find(
    menu => menu.route.replaceAll('/', '') === currentRoute.split('/').slice(1, -1).join('')
  )!;

  const currentItem = currentMenu
    ? currentMenu.items.find(item => {
        return getItemRoute(currentMenu.route, item.name) === currentRoute;
      })
    : undefined;

  const history = useHistory();

  const onChangeItem = (itemName: string) => {
    const item = currentMenu.items.find(item => item.name === itemName);
    const path = getItemRoute(currentMenu.route, item!.name);
    history.push(path);
  };

  return {
    onChangeItem,
    currentRoute,
    currentMenu,
    currentItem,
  };
};

export default useRoute;
