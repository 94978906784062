'use client';

import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import { useStore } from '@Hooks/use-store';
import React, { useEffect } from 'react';
import { SidebarToggle } from './sidebar-toggle';
import { Menu } from './menu';

import { cn } from '@/lib/utils';
import GroupDropdown from './group-dropdown';
import { Typography } from '..';

export function Sidebar() {
  const sidebar = useStore(useSidebarToggle, state => state);

  if (!sidebar) return null;

  return (
    <aside
      className={cn(
        'fixed top-0 left-0 z-20 h-screen -translate-x-full lg:translate-x-0 transition-[width] ease-in-out duration-300 ',
        sidebar?.isOpen === false ? 'w-[90px]' : 'w-72'
      )}
    >
      <SidebarToggle isOpen={sidebar?.isOpen} setIsOpen={sidebar?.setIsOpen} />
      <div className="relative flex flex-col px-3 py-4 overflow-y-auto dark:shadow-zinc-800">
        <GroupDropdown />
        <Menu isOpen={sidebar?.isOpen} />
        <div className="absolute bottom-2 overflow-visible">
          <Typography.Muted>{process.env.REACT_APP_VERSION}</Typography.Muted>
        </div>
      </div>
    </aside>
  );
}
