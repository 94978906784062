import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import ReactTable from 'react-table';
import { useDeleteCacheKey } from '@Services/system';
import { useGetStripeCoupons } from '../../../../services/campaign';
import { getDateFormatFromEpoch } from '../../../../utilities';
import DataTableSearch from '../../../../shared/Table/SearchInput';
import { Flex } from 'antd';
import { Button, Modal, Muted } from '@/ui';
import { DataTable } from '@Shared/Style';
import AntdTable, { Column } from '@Shared/Table/AntdTable';

const COUPON_LIST_CACHE_KEY = 'Admin: getStripeCoupons: []';

const COLUMNS = (handleSelectCoupon, productId, couponCode, priceItem): Column<any>[] => [
  {
    Header: 'Name',
    accessor: 'name',
    width: 300,
  },
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'Discount Amount',
    accessor: 'amount_off',
    Cell: props => (props.value ? `${props.value / 100}$` : ''),
  },
  {
    Header: 'Discount Percent',
    accessor: 'percent_off',
    Cell: props => (props.value ? `${props.value}%` : ''),
  },
  {
    Header: 'Type',
    accessor: 'duration',
    Cell: props =>
      props.value === 'repeating' ? `${props.original.duration_in_months} months` : props.value,
  },
  {
    Header: 'Created At',
    accessor: 'created',
    Cell: props => getDateFormatFromEpoch(props.value),
  },
  {
    Header: 'Applicable Product',
    accessor: 'applies_to.products',
    Cell: props => {
      return (
        <Flex vertical>
          {props?.value?.map((product, index) => (
            <span key={index}>{product}</span>
          ))}
        </Flex>
      );
    },
  },

  {
    Header: 'Apply Coupon',
    accessor: 'id',
    Cell: props => {
      // Checking logic whether coupon can be applied to the plan.
      const matchBillingCycle =
        priceItem?.recurring.interval === 'year' && props.original.duration === 'repeating'
          ? props.original.duration_in_months >= 12
          : true;
      const isAppliesTo =
        isEmpty(props.original.applies_to) ||
        props.original.applies_to?.products.includes(productId);
      return (
        isAppliesTo &&
        matchBillingCycle && (
          <Button
            className={props.value === couponCode ? 'green' : ''}
            onClick={handleSelectCoupon(props.original)}
          >
            Select Coupon
          </Button>
        )
      );
    },
  },
];

function StripeCouponModal({
  onSelectCoupon,
  products,
  productId,
  priceId,
  selectedCoupon,
  show,
  closeModal,
}) {
  const {
    data: stripeCoupons,
    isLoading: isLoadingCoupons,
    refetch: fetchCoupons,
  } = useGetStripeCoupons();
  const { execute: deleteCacheKey, isLoading } = useDeleteCacheKey();
  const [filteredData, setFilteredData] = useState<undefined | any[]>(undefined);

  useEffect(() => {
    if (isEmpty(stripeCoupons)) {
      fetchCoupons();
    }
  }, []);

  const getProductItem = productId => products?.products?.find(e => e.id === productId);

  const getPriceItem = (productId, priceId) =>
    products?.prices?.[productId]?.find(e => e.id === priceId);

  const handleClickCreateCoupon = () => {
    window.open('https://dashboard.stripe.com/coupons/create', '_blank');
  };

  const handleClickRefreshCouponList = () => {
    deleteCacheKey({
      query: {
        key: COUPON_LIST_CACHE_KEY,
      },
    }).then(() => fetchCoupons());
  };

  const handleSearch = (filteredData = []) => {
    setFilteredData(filteredData);
  };

  return (
    <Modal
      classNames={{
        wrapper: 'max-w-[80vw]',
      }}
      closeModal={closeModal}
      open={show}
      header={
        <Flex vertical>
          Select Coupon: {productId ? `: ${getProductItem(productId)?.name}` : ''}
          <br />
          <Muted>
            If not found / work certain coupon, the coupon should have promotion code. Please check
            it in the Stripe dashboard.
          </Muted>
          <Flex gap={10} justify="end">
            <Button onClick={handleClickCreateCoupon}>Create Coupon</Button>
            <Button variant="submit" onClick={handleClickRefreshCouponList}>
              Refresh List
            </Button>
          </Flex>
        </Flex>
      }
    >
      {stripeCoupons?.length > 0 ? (
        <>
          <DataTableSearch originalData={stripeCoupons} onChange={handleSearch} />
          <br />
          <AntdTable
            loading={isLoadingCoupons || isLoading}
            key="couponTable"
            data={(filteredData !== undefined ? filteredData : stripeCoupons).filter(
              e => e.valid === true
            )}
            columns={COLUMNS(
              onSelectCoupon,
              productId,
              selectedCoupon?.id,
              getPriceItem(productId, priceId)
            )}
          />
        </>
      ) : (
        <p>No coupon</p>
      )}
    </Modal>
  );
}

export default StripeCouponModal;
