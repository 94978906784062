import React, { useEffect } from 'react';
import Playlist from './Playlist';

import useURLParams from '@Hooks/useURLParams';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Shared';
import EditArtist from './EditArtist';
import { capitalize, isEmpty } from 'lodash';
import EditCurator from './EditCurator';
import EditTrack from './EditTrack';
import EditAlbum from './EditAlbum';
import { H4, Muted, Panel, Separator, Small } from '@/ui';

export type EditEntityProps = {
  q: string;
};

const EditCMData = () => {
  const {
    setParams,
    params: { q },
  } = useURLParams();

  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <>
      <div className="mb-2">
        <H4>Edit {data ? `${capitalize(data.type)} ${data.name}` : 'Metadata'}</H4>
        <Muted>
          Search for an artist, track, playlist, album, or curator and edit their metadata, genres,
          tracks, albums and links.
        </Muted>
      </div>
      <GlobalSearch
        defaultValue={q}
        types={['artist', 'playlist', 'track', 'album', 'curator']}
        className="top-search-box"
        icon="farEdit"
        loading={isFetching}
        onSubmit={q => setParams({ q: q as string })}
      />
      <br />
      {data?.type === 'artist' && <EditArtist q={q} />}
      {data?.type === 'track' && <EditTrack q={q} />}
      {data?.type === 'playlist' && <Playlist q={q} id={data.id} {...data} fetchData={refetch} />}
      {data?.type === 'album' && <EditAlbum q={q} id={data.id} {...data} fetchData={refetch} />}
      {data?.type === 'curator' && <EditCurator q={q} />}
    </>
  );
};

export default EditCMData;
