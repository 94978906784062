'use client';

import * as React from 'react';
import * as ModalPrimitive from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';

import { cn } from '@/lib/utils';

const ModalRoot = ModalPrimitive.Root;

const ModalTrigger = ModalPrimitive.Trigger;

const ModalPortal = ModalPrimitive.Portal;

const ModalClose = ModalPrimitive.Close;

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Overlay
    ref={ref}
    className={cn(
      'fixed inset-0 z-50 bg-black/80  data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
));
ModalOverlay.displayName = ModalPrimitive.Overlay.displayName;

const ModalContent = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <ModalPortal>
    <ModalOverlay />
    <ModalPrimitive.Content
      ref={ref}
      className={cn(
        'fixed left-[50%] top-[50%] z-50 grid w-auto max-w-[50vw] translate-x-[-50%] translate-y-[-50%] gap-4 border bg-background p-5 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-lg',
        className
      )}
      {...props}
    >
      {children}
      <ModalPrimitive.Close className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground">
        <Cross2Icon className="h-4 w-4" />
        <span className="sr-only">Close</span>
      </ModalPrimitive.Close>
    </ModalPrimitive.Content>
  </ModalPortal>
));
ModalContent.displayName = ModalPrimitive.Content.displayName;

const ModalHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-col space-y-1.5 text-center sm:text-left px-1', className)}
    {...props}
  />
);
ModalHeader.displayName = 'ModalHeader';

const ModalFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn('flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 px-1', className)}
    {...props}
  />
);
ModalFooter.displayName = 'ModalFooter';

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Title>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Title
    ref={ref}
    className={cn('text-lg font-semibold leading-none tracking-tight', className)}
    {...props}
  />
));
ModalTitle.displayName = ModalPrimitive.Title.displayName;

const ModalDescription = React.forwardRef<
  React.ElementRef<typeof ModalPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof ModalPrimitive.Description>
>(({ className, ...props }, ref) => (
  <ModalPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));
ModalDescription.displayName = ModalPrimitive.Description.displayName;

const ModalChildren = React.forwardRef<HTMLDivElement, React.ButtonHTMLAttributes<HTMLDivElement>>(
  ({ ...props }, ref) => (
    <div
      ref={ref}
      {...props}
      className={cn('max-h-[70vh] overflow-y-auto flex flex-col p-1', props.className)}
    />
  )
);

ModalChildren.displayName = 'ModalChildren';

// =================================================================================
// useModal
// =================================================================================
export type ModalPropsType<T = any> = {
  show: boolean;
} & T;

const useModal = <T,>() => {
  const [modalProps, setModalProps] = React.useState<ModalPropsType<T>>({ show: false } as never);

  const closeModal = React.useCallback(() => {
    setModalProps(old => ({ ...old, show: false }));
  }, []);

  const showModal = React.useCallback((props?: T) => {
    setModalProps({
      // @ts-ignore
      show: true,
      ...props,
    });
  }, []);

  return { showModal, closeModal, modalProps };
};

interface ModalProps {
  header?: React.ReactNode;
  children: React.ReactNode;
  footer?: React.ReactNode;
  classNames?: {
    wrapper?: string;
    content?: string;
    header?: string;
    footer?: string;
    title?: string;
  };
}

// ModalButton
const ModalButton = ({
  header,
  children,
  trigger,
  footer,
  classNames,
}: ModalProps & {
  trigger: React.ReactNode;
}) => {
  return (
    <ModalRoot>
      <ModalTrigger asChild>{trigger}</ModalTrigger>
      <ModalContent className={classNames?.wrapper}>
        <ModalHeader className={classNames?.header}>
          <ModalTitle className={classNames?.title}>{header}</ModalTitle>
        </ModalHeader>
        <ModalChildren className={classNames?.content}>{children}</ModalChildren>
        {footer && <ModalFooter className={classNames?.footer}>{footer}</ModalFooter>}
      </ModalContent>
    </ModalRoot>
  );
};

const Modal = ({
  children,
  header: title,
  closeModal,
  footer,
  classNames,
  ...rest
}: ModalProps & Omit<ModalPrimitive.DialogProps, 'onOpenChange'> & { closeModal: () => void }) => {
  return (
    <ModalRoot {...rest} onOpenChange={closeModal}>
      <ModalContent className={classNames?.wrapper}>
        <ModalHeader className={classNames?.header}>
          <ModalTitle className={classNames?.title}>{title}</ModalTitle>
        </ModalHeader>
        <ModalChildren className={classNames?.content}>{children}</ModalChildren>
        {footer && <ModalFooter className={classNames?.footer}>{footer}</ModalFooter>}
      </ModalContent>
    </ModalRoot>
  );
};

export {
  ModalButton,
  useModal,
  ModalClose,
  ModalTrigger,
  Modal,
  ModalRoot,
  ModalContent,
  ModalOverlay,
  ModalPortal,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalDescription,
  ModalChildren,
};
