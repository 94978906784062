'use client';

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/lib/utils';
import { Flex } from './flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

 const BUTTON_VARIANTS = cva(
   'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
   {
     variants: {
       variant: {
         default: 'bg-primary text-primary-foreground shadow hover:bg-primary/90',
         outlinePrimary: 'border border-primary text-primary shadow-sm hover:bg-accent',
         destructive:
           'bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90',
         outlineDestructive: 'border border-destructive text-destructive shadow-sm hover:bg-accent',
         outline:
           'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
         secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
         ghost: 'hover:bg-accent hover:text-accent-foreground',
         link: 'text-primary underline-offset-4 hover:underline',
         submit: 'bg-green-500 text-white shadow hover:bg-green-500/90',
         outlineSubmit: 'border border-green-500 text-green-500 shadow hover:bg-accent',
         black: 'bg-black text-white shadow hover:bg-black/90',
         text: '',
       },
       size: {
         default: 'h-9 px-4 py-2',
         sm: 'h-8 rounded-md px-3 text-xs',
         lg: 'h-10 rounded-md px-8',
         icon: 'h-9 w-9',
         auto: 'h-auto px-0 py-0 my-0',
       },
     },
     defaultVariants: {
       variant: 'default',
       size: 'default',
     },
   }
 );

 export interface ButtonProps
   extends React.ButtonHTMLAttributes<HTMLButtonElement>,
     VariantProps<typeof BUTTON_VARIANTS> {
   asChild?: boolean;
   icon?: React.ReactNode;
   loading?: boolean;
 }

 const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
   ({ className, variant, size, asChild = false, ...props }, ref) => {
     const renderChildren = () =>
       asChild ? (
         props.children
       ) : (
         <>
           {props.loading ? (
             <FontAwesomeIcon icon={faSpinner} spin className={props.children ? 'mr-2' : ''} />
           ) : (
             props.icon &&
             React.cloneElement(props.icon as React.ReactElement, {
               className: props.children ? 'mr-2' : '',
             })
           )}
           {props.children}
         </>
       );

     const Comp = asChild ? Slot : 'button';
     return (
       <Comp
         className={cn(
           BUTTON_VARIANTS({
             variant,
             size: !props.children && props.icon ? 'icon' : size,
             className,
           }),
           props.loading ? 'cursor-wait' : '',
           props.disabled ? 'cursor-not-allowed' : ''
         )}
         ref={ref}
         {...props}
         children={renderChildren()}
       />
     );
   }
 );

 export { Button, BUTTON_VARIANTS };
