import React from 'react';
import { connect } from 'react-redux';

import * as actions from '../../../actions';
import { Button, Flex, Input, Panel, Select } from '@/ui';
import { Table } from '@Components';

const mediaOptions = [{ value: 'instagram', label: 'Instagram', icon: 'faInstagram' }];

class MergeSocialStat extends React.Component {
  state = {
    mediaType: undefined,
    primaryId: '',
    secondaryId: '',
    showMainPanel: false,
  };

  handleMediaChange = (value: string) => this.setState({ mediaType: value });

  handlePrimaryIdChange = ({ target: { value } }) => this.setState({ primaryId: value });

  handleSecondaryIdChange = ({ target: { value } }) => this.setState({ secondaryId: value });

  handleButtonClick = () => {
    const { mediaType, primaryId, secondaryId } = this.state;
    // @ts-ignore
    this.props.mergeSocialStat(mediaType, primaryId, secondaryId);
  };

  render() {
    // @ts-ignore
    const { response } = this.props;

    const { mediaType, primaryId, secondaryId, showMainPanel } = this.state;

    return (
      <Panel header="Merge old social media data to current one">
        <Table
          options={[
            {
              label: 'Social Media',
              component: (
                <Select
                  placeholder="Social media"
                  options={mediaOptions}
                  onChange={this.handleMediaChange}
                  value={mediaType}
                />
              ),
            },
            {
              label: 'Primary Id',
              component: (
                <Input
                  value={primaryId}
                  onChange={this.handlePrimaryIdChange}
                  placeholder="Current social id. ex: billieeilish"
                />
              ),
            },
            {
              label: 'Secondary Id',
              component: (
                <Input
                  value={secondaryId}
                  name="secondaryId"
                  placeholder="Old social id. ex: whereareavocados"
                  onChange={this.handleSecondaryIdChange}
                />
              ),
            },
          ]}
        />
        <Flex justify="center">
          <Button
            variant="submit"
            disabled={!mediaType || !primaryId || !secondaryId}
            loading={response.isFetching}
            onClick={this.handleButtonClick}
          >
            Submit
          </Button>
        </Flex>
      </Panel>
    );
  }
}

const mapStateToProps = state => ({ response: state.metadata.update });
export default connect(mapStateToProps, actions)(MergeSocialStat);
