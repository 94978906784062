import React, { useState, Component } from 'react';
import DataTableSearch from '../../../shared/Table/SearchInput';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { Link } from 'react-router-dom';
import { generateInternalLink, getFormattedLocalDate } from '../../../utilities';
import { useAddAdminUser, useDeleteAdminUser, useGetAdminUsers } from '@Services/user';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import FAIcon from '@Components/ui/fa-icons';
import { Button, Flex, ModalButton, ModalClose, Panel, Popconfirm } from '@/ui';

const DeleteCell = ({ original }) => {
  const { execute, isLoading } = useDeleteAdminUser();
  const { refetch } = useGetAdminUsers();

  return (
    <Flex justify="center">
      <Popconfirm
        message={
          <span>
            Are you sure you want to delete <strong>{original.email}</strong> from admin user?
          </span>
        }
        onConfirm={() =>
          execute({
            data: {
              q: original.user_info,
            },
          }).then(() => refetch())
        }
      >
        <Button loading={isLoading} icon={<FAIcon name="farTrash" />} />
      </Popconfirm>
    </Flex>
  );
};

const COLUMNS: Column<any>[] = [
  {
    Header: 'User Id',
    accessor: 'user_info', // String-based value accessors!
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
    filter: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    formatter: value => getFormattedLocalDate(value),
    sorter: true,
  },
  {
    Header: 'Delete',
    Cell: DeleteCell,
  },
];

function AddAdminUserModal() {
  const [inputValue, setInputValue] = useState('');
  const { execute, isLoading } = useAddAdminUser();
  const { refetch } = useGetAdminUsers();

  return (
    <ModalButton
      trigger={
        <Button variant="submit" icon={<FAIcon name="farPlus" />}>
          Add Admin User
        </Button>
      }
      header="Add Trusted User"
      footer={
        <ModalClose asChild>
          <Button
            disabled={!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)}
            variant="submit"
            onClick={() =>
              execute({
                data: {
                  q: inputValue,
                },
              }).then(() => refetch())
            }
            loading={isLoading}
            icon={<FAIcon name="farPlus" />}
          >
            Add
          </Button>
        </ModalClose>
      }
    >
      <GlobalSearch
        placeholder="Search for user"
        onChange={value => setInputValue(value)}
        loading={isLoading}
        types={['user']}
        icon="farUser"
        size="middle"
      />
    </ModalButton>
  );
}

const AdminUsers = () => {
  const { data, isLoading } = useGetAdminUsers();
  const [filteredData, setFilteredData] = useState<any[]>();

  return (
    <Panel header="Admin Users" icon="farUserGroupCrown" aside={<AddAdminUserModal />}>
      <Flex vertical gap={4}>
        <DataTableSearch originalData={data} onChange={e => setFilteredData(e)} size="large" />
        <AntdTable loading={isLoading} data={filteredData} columns={COLUMNS} pageSize={15} />
      </Flex>
    </Panel>
  );
};

export default AdminUsers;
