import React, { useEffect, useState, useCallback, useMemo } from 'react';
import ReactTable, { Column as TableColumn, SortingRule } from 'react-table';
import { Empty, Spin } from 'antd';
import uuid4 from 'uuid4';
import Pagination from '@Shared/Table/Paignation';

export interface Column<T> extends Omit<TableColumn, 'accessor' | 'Cell'> {
  accessor?: keyof T;
  Cell?: ({ original, value }: { original: T; value: any }) => React.ReactNode;
}

interface Props<T> {
  key?: string;
  loading?: boolean;
  columns: Column<T>[];
  data: T[];
  defaultPageSize?: number;
  defaultSorted?: SortingRule[];
  sortable?: boolean;
  lazyPaginationOptions?: {
    total: number;
    limit: number;
    offset: number;
    handleChangePage: (page: number) => void;
    handleChangePageSize: (pageSize: number) => void;
  };
}

/**
 * @Deprecated use 'AntdTable' instead
 */
function DataTable<T>({
  key,
  loading,
  columns,
  data,
  lazyPaginationOptions,
  defaultPageSize = 20,
  defaultSorted = [],
  sortable = true,
}: Props<T>) {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(lazyPaginationOptions?.limit ?? defaultPageSize);
  const [pages, setPages] = useState(0);
  const [sorted, setSorted] = useState<SortingRule[]>(defaultSorted);

  useEffect(() => {
    const totalItems = lazyPaginationOptions?.total ?? data?.length ?? 0;
    setPages(Math.ceil(totalItems / pageSize));
  }, [lazyPaginationOptions?.total, data?.length, pageSize]);

  const handlePageChange = useCallback(
    (newPage: number) => {
      setPage(newPage);
      lazyPaginationOptions?.handleChangePage(newPage);
    },
    [lazyPaginationOptions]
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      setPageSize(newPageSize);
      lazyPaginationOptions?.handleChangePageSize(newPageSize);
    },
    [lazyPaginationOptions]
  );

  const handleSortedChange = useCallback((newSorted: SortingRule[]) => {
    setSorted(newSorted);
  }, []);

  const emptyArray = useMemo(
    () =>
      Array.from({ length: lazyPaginationOptions?.total ?? 0 }, (_, i) => data[i] ?? {}) as any[],
    [lazyPaginationOptions?.total]
  );

  // TODO: improve performance
  const getData = useMemo(() => {
    if (lazyPaginationOptions) {
      const { offset, total } = lazyPaginationOptions;
      const newData = emptyArray;
      newData.splice(offset, data.length, ...data);
      return newData;
    }
    return data ?? [];
  }, [
    data,
    lazyPaginationOptions?.limit,
    lazyPaginationOptions?.offset,
    lazyPaginationOptions?.total,
  ]);

  return (
    <ReactTable
      key={key ?? uuid4()}
      loading={loading}
      // @ts-ignore
      columns={columns}
      data={getData}
      PaginationComponent={props => (
        <Pagination {...props} total={lazyPaginationOptions?.total ?? data?.length} />
      )}
      onSortedChange={handleSortedChange}
      sorted={sorted}
      page={page}
      pageSize={pageSize}
      sortable={sortable}
      pages={pages}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      noDataText={!loading && <Empty />}
      showFilters
      loadingText={
        <Spin tip="Loading" size="large">
          <div className="content" />
        </Spin>
      }
    />
  );
}


/**
 * @deprecated use 'AntdTable' instead
 */
export default React.memo(DataTable) as typeof DataTable;
