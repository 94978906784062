import { Divider, Tag, Button, Typography } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';
import { ActionInput, AntdIcon, BrandIcon, ResponsiveSection } from '../../shared/Style';
import {
  checkFormatForAlbum,
  checkFormatForArtist,
  checkFormatForTrack,
  convertUrl,
} from './utils';
import { PLATFORMS_COLOR, DSP_PLATFORMS } from '@Utils/constants';
import { useVerifyDSP } from '@Services/linking';
import { UpdateLinkingMetaData } from './types';
import LinkSuggestions from './LinkSuggestions';
import { useArtistLinking, useAlbumLinking, useTrackLinking, useCuratorLinking } from './hooks';
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Panel,
  Flex,
  CardDescription,
  SectionTitle,
} from '@/ui';

type Meta = {
  isrc?: string;
  artistName?: string;
  trackName?: string;
  streamingType?: string;
};

type Callback = (url: string) => void;

export type ExternalLinksPanelProps = {
  urls: Record<string, any>;
  id: number;
  callback: Callback;
  panelStyle?: boolean;
} & (
  | {
      type: any;
      meta?: never;
    }
  | {
      type: 'artist';
      meta: Pick<UpdateLinkingMetaData, 'artistName'>;
    }
  | {
      type: 'track';
      meta: Omit<UpdateLinkingMetaData, 'streamingType'>;
    }
  | {
      type: 'album';
      meta?: Omit<UpdateLinkingMetaData, 'streamingType'>;
    }
  | {
      type: 'curator';
      meta: Pick<UpdateLinkingMetaData, 'streamingType'>;
    }
);

const VerifyButton = ({
  platform,
  id,
  callback,
}: {
  platform: string;
  id: number;
  callback: Callback;
}) => {
  const { execute: verifyDSP, isLoading: isLoadingVerifyDSP } = useVerifyDSP();

  const handleVerify = (platform: string) => {
    verifyDSP({
      data: {
        urlTarget: 'cm_artist',
        targetId: id,
        urlSource: platform,
      },
    }).then(() => callback(platform));
  };

  if (platform !== 'anghami')
    return (
      <Button
        loading={isLoadingVerifyDSP}
        onClick={() => handleVerify(platform)}
        className="green"
        icon={<AntdIcon name="checkCircle" />}
        style={{ marginLeft: 8 }}
      >
        Verify
      </Button>
    );
  return null;
};

function ExternalLinksPanel({
  urls,
  type,
  meta,
  id,
  callback,
  panelStyle = true,
}: ExternalLinksPanelProps) {
  const { onAdd, onDelete, isLoading } = {
    artist: useArtistLinking,
    album: useAlbumLinking,
    track: useTrackLinking,
    curator: useCuratorLinking,
  }[type](id, callback, meta as UpdateLinkingMetaData);

  const handleUpdate = (
    _: React.ChangeEvent<HTMLInputElement>,
    _2: React.MouseEvent<HTMLElement, MouseEvent>,
    value: string | number
  ) => onAdd(convertUrl(value as string).trim() as string);

  const handleDelete = (url: string) => {
    onDelete(url);
  };

  const render = () => (
    <Flex vertical gap={4}>
      <ActionInput
        placeholder="Social URL. ex: https://www.deezer.com/us/artist/4050763"
        action={{
          icon: 'farPlus',
          content: 'Add Link',
          onClick: handleUpdate,
          loading: isLoading,
          disabledEmpty: true,
        }}
        clearAfterAction
        validation={
          {
            artist: checkFormatForArtist,
            track: checkFormatForTrack,
            album: checkFormatForAlbum,
          }[type]
        }
      />
      <LinkSuggestions callback={callback} id={id} type={type} urls={urls} meta={meta} />
      <Flex vertical>
        <SectionTitle>Links</SectionTitle>
        <ResponsiveSection>
          {Object.entries(urls)
            .sort((a, b) => (a[0].toLowerCase() > b[0].toLowerCase() ? 1 : -1))
            .map(
              ([platform, value]) =>
                value?.url?.length > 0 && (
                  <Card>
                    <CardHeader className="bg-decorative py-0 flex flex-row items-center text-decorative-foreground h-[55px]">
                      <Flex gap={2} align="center" className="w-full" justify="between">
                        <Flex gap={4}>
                          <BrandIcon platform={platform as any} style={{ width: 16, height: 16 }} />
                          {capitalize(platform)}
                        </Flex>
                        <Flex align="center" gap={4}>
                          {value.is_verified && <AntdIcon name="checkCircle" color="blue" />}
                          {value.is_verified && value.modifier_email}{' '}
                          {!value.is_verified && type === 'artist' && (
                            <VerifyButton platform={platform} id={id} callback={callback} />
                          )}
                        </Flex>
                      </Flex>
                    </CardHeader>
                    <CardContent className="py-4">
                      {platform === 'anghami' && (
                        <CardDescription className="mb-4">
                          Anghami does not support verification and delete currently. we will
                          support very soon.
                        </CardDescription>
                      )}
                      <Flex vertical gap={2}>
                        {urls[platform]?.url.map(url => (
                          <Tag
                            key={url}
                            className="hoverable"
                            style={{
                              maxWidth: '100%',
                              width: 'fit-content',
                            }}
                            color={PLATFORMS_COLOR[platform]}
                            onClick={() => window.open(url, '_blank')}
                          >
                            <Flex gap={2}>
                              <div
                                style={{
                                  width: !(type === 'curator' && DSP_PLATFORMS.includes(platform))
                                    ? 'calc(100% - 20px)'
                                    : '100%',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {url}
                              </div>
                              {!(type === 'curator' && DSP_PLATFORMS.includes(platform)) &&
                                !(type === 'track' && platform === 'instagram') &&
                                platform !== 'anghami' && (
                                  <XMark
                                    url={url}
                                    id={id}
                                    callback={handleDelete}
                                    meta={{ streamingType: type }}
                                  />
                                )}
                            </Flex>
                          </Tag>
                        ))}
                      </Flex>
                    </CardContent>
                  </Card>
                )
            )}
        </ResponsiveSection>
      </Flex>
    </Flex>
  );

  if (panelStyle)
    return (
      <Panel
        header="DSP & Social Links"
        description={
          <>
            {type === 'artist'
              ? 'Social stats will be refreshed when new link added'
              : type === 'track'
              ? `Track linking has an issue with Data side. Please send a request to
          #customer-data-issue channel on Slack if you have any issue.`
              : null}
            {type === 'track' ? (
              <>
                <br />
                Pandora track urls do not work —to add Pandora data, add the Pandora artist url to
                the artist.
              </>
            ) : undefined}
          </>
        }
      >
        {render()}
      </Panel>
    );

  return render();
}

const XMark = ({ url, id, callback, meta }) => {
  const { onDelete, isLoading } = {
    artist: useArtistLinking,
    album: useAlbumLinking,
    track: useTrackLinking,
    curator: useCuratorLinking,
  }[meta?.streamingType](id, callback, meta);

  return (
    <Button
      style={{ padding: 0, margin: 0, height: 'auto' }}
      type="text"
      loading={isLoading}
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        onDelete(url);
      }}
      icon={<AntdIcon name="close" />}
    ></Button>
  );
};

export default ExternalLinksPanel;
