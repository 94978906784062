import React, { useMemo } from 'react';


import { TooltipRoot, TooltipContent, TooltipProvider, TooltipTrigger, Tooltip } from '@/ui';
import FAIcon from '../../components/ui/fa-icons';

interface Props {
  children?: React.ReactNode;
  title?: any;
  color?: string;
}

/* <WhatsThis />
============================================================================= */
function WhatsThis({ children }: Props) {
  return (
    <Tooltip content={children}>
      <FAIcon name="fasQuestionCircle" className="cursor-pointer flex px-1 " />
    </Tooltip>
  );
}

export default WhatsThis;
