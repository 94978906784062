import { Segments } from '@Shared/Style';
import React from 'react';
import Info from './Track/Info';
import { EditEntityProps } from '.';
import { isEmpty } from 'lodash';
import { useGetEntity } from '@Services/search';
import SimilarTracks, { SimilarTracksList } from './Track/SimilarTracks';
import Album from './Track/Album';
import TagPanel from '@Components/edit-genre-panel';
import MoodPanel from '@Shared/MoodPanel/MoodPanel';
import { ENTITY_ICON } from '@Constants/entity';
import { ExternalLinksPanel } from '@Components';
import { Panel } from '@/ui';

const EditTrack = ({ q }: EditEntityProps) => {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <Segments
      options={[
        {
          icon: 'farInfoCircle',
          title: 'Information',
          content: (
            <>
              <Info q={q} />
              <Album q={q} />
            </>
          ),
        },

        {
          icon: 'farLink',
          title: 'DSP & Social Links',
          content: (
            <>
              <Panel
                header="Similar Tracks (Related ISRC)"
                description={`
                    Sharing social URLs with other tracks with "related ISRCs". It has exceptions. for Instance, TikTok, where if one ISRC of a track got TikTok data, we still wouldn't show it for the others.`}
              >
                <SimilarTracksList q={q} />
              </Panel>
              <br />
              <ExternalLinksPanel
                type="track"
                id={data.id}
                urls={data.urls}
                meta={{
                  artistName: data.artists?.map(artist => artist.name).join(', '),
                  isrc: data.isrc,
                  trackName: data.name,
                }}
                callback={() => refetch()}
              />
            </>
          ),
        },
        {
          title: 'Genre & Mood',
          icon: ENTITY_ICON.GENRE,
          content: (
            <>
              <TagPanel
                entity="track"
                id={data.id}
                tags={data.tags.map(({ id, name }) => ({
                  name,
                  id,
                  type: 'main',
                  hidden: false,
                }))}
                callback={refetch}
              />
              <MoodPanel id={data.id} moods={data.moods} callback={refetch} />
            </>
          ),
        },
        {
          icon: ENTITY_ICON.TRACK,
          title: 'Similar Tracks',
          content: <SimilarTracks q={q} />,
        },
      ]}
    />
  );
};

export default EditTrack;
