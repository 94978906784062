import { Empty, Input, InputProps, List, Segmented, Spin, Typography } from 'antd';
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import styles from '../GlobalSearch/GlobalSearch.module.scss';
import { useDebounceValue, useOnClickOut } from '@Hooks';

export type InputSuggestionOption = {
  label: string;
  value: string | number;
};

interface Props extends Omit<InputProps, 'value' | 'onChange'> {
  suggestions: InputSuggestionOption[];
  onSearch: (value: string | number, option: InputSuggestionOption) => void;
  renderItem: (item: InputSuggestionOption) => React.ReactNode;
  loading: boolean;
  width?: number;
  title?: string;
}

/**
 * @deprecated use actionInput with suggestions
 */
const InputSuggestion = ({
  loading,
  renderItem,
  suggestions: rawSuggestions,
  onSearch,
  defaultValue,
  width = 300,
  title = 'Suggestions',
  ...rest
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<InputSuggestionOption>();
  const debouncedValue = useDebounceValue<InputSuggestionOption>(value, 500);
  const [suggestions, setSuggestions] = useState<InputSuggestionOption[]>(rawSuggestions);
  const containerRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const closeDropdown = useCallback(() => {
    if (!isOpen) {
      return;
    }
    setIsOpen(false);
  }, [isOpen, setIsOpen]);
  useOnClickOut(closeDropdown, containerRef);

  useLayoutEffect(() => {
    if (disabled) setIsOpen(false);
    if (loading) return setIsOpen(true);
    if (rawSuggestions.length && JSON.stringify(rawSuggestions) !== JSON.stringify(suggestions)) {
      setSuggestions(rawSuggestions);
      setIsOpen(true);
      return;
    }
    setIsOpen(false);
  }, [loading, rawSuggestions, disabled]);

  useEffect(() => {
    if (debouncedValue?.value) onSearch(debouncedValue?.value, debouncedValue);
  }, [debouncedValue]);

  const handleClickSuggestion = (item: InputSuggestionOption) => {
    setDisabled(true);
    onSearch(item.value, item);
    setValue(item);
    setIsOpen(false);
    setTimeout(() => {
      setDisabled(false);
    }, 500);
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue as any);
    }
  }, [defaultValue]);

  return (
    <div className={styles.wrapper}>
      <Input
        {...rest}
        value={value?.label}
        onChange={e =>
          setValue(
            suggestions.find(item => item.label.toLowerCase() === e.target.value.toLowerCase()) || {
              label: e.target.value,
              value: e.target.value,
            }
          )
        }
        allowClear
      />
      {isOpen && (
        <ul
          className={styles.suggestionContainer}
          style={{
            maxWidth: width,
          }}
          ref={containerRef}
        >
          <Typography.Text type="secondary" className="p-3">
            {title}
          </Typography.Text>
          {loading ? (
            <Spin className="my-10" />
          ) : suggestions?.length === 0 ? (
            <Empty className="my-10" />
          ) : (
            suggestions?.map((item, index) => (
              <List.Item
                onClick={() => {
                  handleClickSuggestion(item);
                }}
                key={index}
              >
                {renderItem(item)}
              </List.Item>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default InputSuggestion;
