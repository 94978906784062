import React, { useState, useRef, useCallback, useEffect } from 'react';
import { SearchInput } from '@Shared';
import { AntdIcon } from '@Shared/Style';
import { generateCmUrl } from '@Utils';
import { PLATFORMS_COLOR } from '@Utils/constants';
import { Flex, Avatar, Typography, Button, Tag, Empty, Divider, Spin } from 'antd';
import { capitalize } from 'lodash';
import { useEditArtistGenreBatch } from '../../Context';
import {
  InfiniteLoader,
  List as VirtualList,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
  Index,
} from 'react-virtualized';
import { useTagList } from '@Services/tag';
import Country from '@Shared/Style/Country';

const ArtistSelection = () => {
  const { isLoading, artists } = useEditArtistGenreBatch();
  const { tagMap } = useTagList();

  const getTagName = useCallback(
    (id: number) => {
      const genres = tagMap.main.concat(tagMap.sub).concat(tagMap.mainV2);
      const genreData = genres.find(g => g.id === Number(id));
      return capitalize(genreData?.name);
    },
    [tagMap]
  );

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const rowRenderer = ({ index, key, style, parent }: ListRowProps) => {
    const item = artists[index];
    if (!item) return null;

    return (
      <CellMeasurer
        cache={cache.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style} onLoad={measure}>
            <Flex vertical key={item.id}>
              <Flex
                gap="small"
                align="center"
                className="hoverable"
                justify="space-between"
                style={{ width: '100%' }}
              >
                <Flex gap="small" align="center">
                  <Avatar src={item.image_url} size="large" />
                  <Typography.Link
                    strong
                    style={{ fontSize: 16 }}
                    target="_blank"
                    href={generateCmUrl('artist', { id: item.id })}
                  >
                    {item.name}
                  </Typography.Link>{' '}
                  |
                  <Country value={item.code2} showName={false} />
                </Flex>
              </Flex>

              <Flex wrap style={{ marginTop: 8, gap: 4 }}>
                {item.cm_tags
                  .filter(tag => {
                    return (
                      (tagMap.main.find(e => e.id === tag.id) ||
                        tagMap.sub.find(e => e.id === tag.id) ||
                        tagMap.mainV2.find(e => e.id === tag.id)) &&
                      !tag.hidden
                    );
                  })
                  .slice(0, 5)
                  .map(tag => {
                    return (
                      <Tag color={PLATFORMS_COLOR.chartmetric} key={tag.id}>
                        {capitalize(getTagName(tag.id))}
                      </Tag>
                    );
                  })}
              </Flex>
              {index < artists.length - 1 && <Divider />}
            </Flex>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <>
      <Typography.Text type="secondary">Up to 100 artists displayed</Typography.Text>
      <div style={{ minHeight: '100%', marginTop: 8 }}>
        {isLoading ? (
          <Flex justify="center" align="center" style={{ height: 100 }}>
            <Spin tip="Loading" size="large" />
          </Flex>
        ) : artists?.length > 0 ? (
          <AutoSizer>
            {({ width, height }) => (
              <VirtualList
                style={{ padding: 8 }}
                height={height}
                deferredMeasurementCache={cache.current}
                rowHeight={cache.current.rowHeight}
                rowCount={artists.length}
                width={width}
                rowRenderer={rowRenderer}
                overscanRowCount={5}
              />
            )}
          </AutoSizer>
        ) : (
          <Empty description="No Artist Selected" style={{ marginTop: '24px' }} />
        )}
      </div>
    </>
  );
};

export default ArtistSelection;
