import { useGetTeams } from '@Services/Onesheet/team';
import { DataTable, Panel } from '@Shared/Style';
import { generateInternalLink } from '@Utils';
import React from 'react';
import { Link } from 'react-router-dom';

function TeamList() {
  const { data, loading } = useGetTeams();

  return (
    <Panel title="Teams">
      <DataTable
        loading={loading}
        data={data ?? []}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Team ID',
            accessor: 'id',
            Cell: ({ value }) => value,
          },
          {
            Header: 'Owner ID',
            accessor: 'owner',
            Cell: ({ value }) => value,
          },
        ]}
      />
    </Panel>
  );
}

export default TeamList;
