import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';

// CSS
import 'react-table/react-table.css';
import './styles/sanitize.scss';
import './styles/antd.scss';
import './styles/index.scss';
import './styles/globals.css';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Amplify } from 'aws-amplify';
import storeConfig, { history } from './store';
import App from './app';
import { AWS_CONFIG } from './aws-exports';
import { ConfigProvider } from 'antd';
import { ANTD_THEME } from 'styles/antd';
import { ToastProvider } from '@/lib/Toast';

Amplify.configure(AWS_CONFIG);

//Configs
const store = storeConfig();
const client = new QueryClient();

ReactDOM.render(
  <ConfigProvider theme={ANTD_THEME}>
    <QueryClientProvider client={client}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <BrowserRouter>
            <ToastProvider>
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </ToastProvider>
          </BrowserRouter>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById('root')
);
