import { SuggestionItem } from '@Services/system';
import React from 'react';
import { G_CLOUD_STORAGE } from 'resource';
import styles from '../GlobalSearch.module.scss';
import { AntdIcon } from '@Shared/Style';
import { HUMAN_ENTITY } from '@Constants/entity/index';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Flex,
  H4,
  Muted,
  Separator,
  Skeleton,
} from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import Country from '@Shared/Style/Country';

interface Props {
  list: SuggestionItem[];
  type: string;
  isLoading: boolean;
  platform?: string;
  onClick: (type: string, id: number, platform?: string) => (e: any) => void;
}

function ItemList({ list, type, isLoading, onClick, platform }: Props) {
  return (
    <Flex vertical gap={0}>
      {(isLoading ? Array.from({ length: 5 }).map((_, i) => ({ key: type + i })) : list).map(
        (item, index) =>
          isLoading ? (
            <div className="flex items-center space-x-4 h-[76px] px-4" key={index}>
              <Skeleton className="h-10 w-10 rounded-full" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
              </div>
            </div>
          ) : (
            <>
              <Button
                variant="ghost"
                className="h-[76px] justify-start px-4"
                size="auto"
                onClick={onClick(type, item?.id, platform)}
                key={item?.id}
              >
                <Flex align="center" gap={4}>
                  <Avatar className={HUMAN_ENTITY.includes(type) ? 'rounded-full' : 'rounded-lg'}>
                    <AvatarImage src={item.imageUrl} />
                    <AvatarFallback>
                      <FAIcon name="fadUser" />
                    </AvatarFallback>
                  </Avatar>
                  <Flex vertical gap={3}>
                    <Flex
                      align="center"
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <p className="text-base font-normal">{item?.name}</p>
                      {item?.targetExtras?.verified && (
                        <FAIcon name="fasCheckCircle" color="blue" />
                      )}
                    </Flex>
                    <Flex
                      align="center"
                      gap={4}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <Muted>
                        ID:{item?.id}
                        {item?.targetExtras?.ownerName
                          ? `, By ${item?.targetExtras.ownerName}`
                          : ''}
                      </Muted>
                      {item?.targetExtras?.code2 && (
                        <Country value={item.targetExtras.code2} showName={false} />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              </Button>
              {index !== list.length - 1 && <Separator />}
            </>
          )
      )}
    </Flex>
  );
}

export default ItemList;
