import { zodResolver } from '@hookform/resolvers/zod';
import React from 'react';
import { useForm, SubmitHandler, Path } from 'react-hook-form';
import { z } from 'zod';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  TooltipRoot,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from './ui';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { PopoverTrigger } from '@radix-ui/react-popover';
import { cn } from '@/lib/utils';
import { WhatsThis } from '@Shared';

interface FormProps<T extends z.ZodObject<any, any>> {
  schema: T;
  options: {
    name: Path<z.TypeOf<T>>;
    label: string;
    component: React.ReactNode;
    description?: string;
  }[];
  onSubmit: SubmitHandler<z.infer<T>>;
  loading?: boolean;
}

const FormTable = <T extends z.ZodObject<any, any>>({
  schema,
  options,
  onSubmit,
  loading,
}: FormProps<T>) => {
  const form = useForm<z.infer<T>>({
    resolver: zodResolver(schema),
  });

  return (
    <Form {...form}>
      <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
        {options.map(option => (
          <FormField
            key={String(option.name)}
            control={form.control}
            name={option.name}
            render={({ field }) => (
              <FormItem className="grid grid-cols-3 items-center">
                <FormLabel className="text-right">{option.label}</FormLabel>
                <div className="flex flex-col col-span-2 !mt-0">
                  <FormControl>
                    {React.cloneElement(option.component as React.ReactElement, { ...field })}
                  </FormControl>
                  <FormMessage />
                </div>
                {option.description && <FormDescription>{option.description}</FormDescription>}
              </FormItem>
            )}
          />
        ))}
        <Button
          loading={loading}
          variant="submit"
          icon={<FAIcon name="farCircleCheck" />}
          type="submit"
          className="float-right"
          disabled={form.formState.isSubmitting || !form.formState.isValid}
        >
          Submit
        </Button>
      </form>
    </Form>
  );
};

interface TableProps {
  options: {
    label: React.ReactNode;
    component: React.ReactNode;
    labelIcon?: FAIcons;
    description?: string;
  }[];
  description?: string;
  title?: string;
  aside?: React.ReactNode;
  className?: string;
  withoutBorder?: boolean;
}

const Table = ({ options, aside, title, description, className, withoutBorder }: TableProps) => {
  const renderContent = () => {
    return options.map((option, index) => (
      <div className="grid grid-cols-3 items-center border border-decorative">
        <div
          className={cn(
            'flex items-center space-x-2 bg-decorative px-4 py-3 text-decorative-foreground text-sm col-span-1 h-full',
            index === 0 ? 'rounded-tl-md' : index === options.length - 1 ? 'rounded-bl-md' : ''
          )}
        >
          {option.labelIcon && <FAIcon name={option.labelIcon} />}
          <p>{option.label}</p>
          {/* TODO: */}
          {option.description && <WhatsThis>{option.description}</WhatsThis>}
        </div>
        <p className="text-sm col-span-2 px-2 py-2">{option.component}</p>
      </div>
    ));
  };

  if (withoutBorder) return <div>{renderContent()}</div>;

  return (
    <Card className={className}>
      {title || aside ? (
        <CardHeader className="justify-between flex flex-row items-center">
          <CardTitle>{title}</CardTitle>
          {aside}
        </CardHeader>
      ) : (
        <div className="h-6" />
      )}
      <CardContent className="pt-0">{renderContent()}</CardContent>
    </Card>
  );
};

export { FormTable, Table };
