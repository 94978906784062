import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { showInfoMessage } from '../../../utilities';
import DataTableSearch from '../../../shared/Table/SearchInput';
import { useDeleteCoupon, useGetCoupons } from '../../../services/purchase';
import useModal from '../../../hooks/useModal';
import { AntdIcon, DataTable } from '../../../shared/Style';
import UpdateModal from './UpdateModal';
import { Button, Select, Modal, Panel, Flex } from '@/ui';
import AddCouponModal from './AddCouponModal';
import ApplyCouponModal from './ApplyCouponModal';
import { AntdTable } from '@Shared';
import FAIcon from '@Components/ui/fa-icons';

const TYPE_OPTIONS = [
  {
    value: false,
    label: 'Valid Coupons',
  },
  {
    value: true,
    label: 'Expired Coupons',
  },
];

function ManageCoupon() {
  const COLUMNS = [
    {
      Header: 'Code',
      accessor: 'code', // String-based value accessors!
    },
    {
      Header: 'Expriation Date',
      accessor: 'expiration_date',
      Cell: props => moment(props.value).format('l'),
    },
    {
      Header: 'Days For Free Trial',
      accessor: 'num_of_days',
    },
    {
      Header: 'Total Active Users',
      accessor: 'total_active_users',
    },
    {
      Header: 'Total Inactive Users',
      accessor: 'total_inactive_users',
    },
    {
      Header: 'URL',
      accessor: 'code',
      Cell: props => (
        <Button
          variant="outline"
          size="icon"
          icon={<FAIcon name="farCopy" />}
          id={props.value}
          onClick={copyJoinURL(props.value)}
        />
      ),
    },
    {
      Header: 'Users',
      accessor: 'user_emails',
      Cell: props =>
        props.value && (
          <Button
            variant="outline"
            size="icon"
            onClick={e => {
              e.stopPropagation();
              showEmailModal({ emails: props.value });
            }}
            icon={<FAIcon name="faListUl" />}
          />
        ),
    },
    {
      Header: 'Actions',
      accessor: 'id',
      Cell: props => (
        <Flex justify="center" gap={2}>
          <Button
            variant="outline"
            size="icon"
            icon={<FAIcon name="farEdit" color="blue" />}
            onClick={e => {
              e.stopPropagation();
              showUpdateModal({ id: props.value });
            }}
          />
          <Button
            variant="outline"
            size="icon"
            icon={<FAIcon name="farTrash" color="red" />}
            onClick={() => deleteCoupon({ query: { id: props.value } }).then(() => refetch())}
          />
        </Flex>
      ),
    },
  ];

  const {
    closeModal: closeEmailModal,
    modalProps: emailModalProps,
    showModal: showEmailModal,
  } = useModal<{ emails: string[] }>();
  const {
    closeModal: closeUpdateModal,
    modalProps: updateModalProps,
    showModal: showUpdateModal,
  } = useModal<{ id: number }>();

  const [expired, setExpired] = useState(false);
  const { data, refetch, isLoading } = useGetCoupons({
    data: {
      expired,
    },
  });

  useEffect(() => {
    refetch();
  }, [expired]);

  const { execute: deleteCoupon } = useDeleteCoupon();

  const [filteredData, setFilteredData] = useState<object | undefined>(undefined);

  const onChange = value => {
    if (expired !== value) {
      setExpired(value);
    }
  };

  const copyJoinURL = id => e => {
    navigator.clipboard.writeText(`https://app.chartmetric.com/join/trial/${id}`);
    showInfoMessage('Copied!');
  };

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const { showModal, modalProps, closeModal } = useModal();

  return (
    <>
      <Panel
        header="Coupon List"
        aside={
          <Flex gap={2}>
            <ApplyCouponModal refetch={refetch} />
            <AddCouponModal refetch={refetch} />
          </Flex>
        }
      >
        <Flex gap={2} justify="between">
          <Select
            className="w-[170px]"
            placeholder="Select Product"
            options={TYPE_OPTIONS}
            onChange={onChange}
            value={expired}
          />
          <DataTableSearch originalData={data} onChange={setFilteredData} className="w-[300px]" />
        </Flex>
        <br />
        <AntdTable loading={isLoading} data={filteredData ?? data} columns={COLUMNS} />
      </Panel>
      <Modal header="User Emails" open={emailModalProps.show} closeModal={closeEmailModal}>
        {emailModalProps?.['emails']?.map((email, i) => (
          <Flex>
            {i + 1}. <p style={{ marginBottom: '4px' }}>{email}</p>
          </Flex>
        ))}
      </Modal>
      <UpdateModal
        refetch={refetch}
        setClose={closeUpdateModal}
        open={updateModalProps.show}
        {...data?.find(e => e.id === updateModalProps['id'])}
      />
    </>
  );
}

export default ManageCoupon;
