'use client';

import * as React from 'react';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '@/lib/utils';
import { Flex } from './flex';
import { Button } from './button';

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverAnchor = PopoverPrimitive.Anchor;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = 'center', sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        'z-50 w-72 rounded-md border bg-popover p-4 text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        className
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const Popconfirm = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> &
    React.ButtonHTMLAttributes<HTMLButtonElement> & {
      loading?: boolean;
      header?: React.ReactNode;
      message: React.ReactNode;
      onConfirm: () => void;
    }
>(({ className, children, align = 'center', sideOffset = 4, loading, ...props }, ref) => (
  <Popover>
    <PopoverTrigger asChild>{children}</PopoverTrigger>
    <PopoverContent className="max-w-80">
      <div className="grid gap-4">
        <div className="space-y-2">
          {props.header && <h4 className="font-medium leading-none">{props.header}</h4>}
          <p className="text-sm text-muted-foreground">{props.message}</p>
        </div>
      </div>
      <Flex justify="end" className="mt-1">
        <Button variant="submit" onClick={props.onConfirm} size="sm" loading={loading}>
          Confirm
        </Button>
      </Flex>
    </PopoverContent>
  </Popover>
));

export { Popconfirm, Popover, PopoverTrigger, PopoverContent, PopoverAnchor };
