import { useAddArtistMetadata } from '@Services/artist';
import { GlobalSearch } from '@Shared';
import { Panel } from '@Shared/Style';
import React from 'react';

const AddMetadata = () => {
  const { execute, isLoading } = useAddArtistMetadata();

  return (
    <Panel title="Add Metadata" icon="farPlus">
      <GlobalSearch
        types={['artist']}
        buttonIcon="farPlus"
        buttonLabel="Add"
        loading={isLoading}
        onSubmit={value => {
          execute({ data: { q: value as string } });
        }}
      />
    </Panel>
  );
};

export default AddMetadata;
