import React, { useState } from 'react';
import AntdTable from './AntdTable';
import FAIcon from '@Components/ui/fa-icons';
import { Badge, Button, Modal } from '@/ui';

interface Props {
  onClickSubmit: () => Promise<void>;
  approveRequest: number[];
  declineRequest: number[];
  data: any[];
  loading: boolean;
  selected: number;
  columns: {
    Header: string | React.JSX.Element;
    accessor?: string;
    Cell?: any;
    width?: number;
  }[];
}

function RequestSubmitButton({
  onClickSubmit,
  approveRequest,
  declineRequest,
  data,
  loading,
  columns,
  selected,
}: Props) {
  const [open, setOpen] = useState(false);
  const selectedData = data.filter(
    c => approveRequest.includes(c.id) || declineRequest.includes(c.id)
  );
  return (
    <>
      <Button
        icon={<FAIcon name="fasCheckCircle" />}
        variant="submit"
        onClick={() => setOpen(true)}
        disabled={approveRequest.length === 0 && declineRequest.length === 0}
      >
        SUBMIT ({selected ?? 0})
      </Button>
      <Modal
        closeModal={() => setOpen(false)}
        open={open}
        header={`Confirm handling requests (${selectedData.length})`}
        footer={
          <Button
            onClick={() => {
              onClickSubmit().finally(() => setOpen(false));
            }}
            loading={loading}
            variant="submit"
            icon={<FAIcon name="fasCheckCircle" />}
          >
            Submit
          </Button>
        }
      >
        <AntdTable
          columns={[
            ...columns,
            {
              Header: 'Status',
              accessor: 'id',
              Cell: ({ value }) => (
                <Badge variant={approveRequest.includes(value) ? 'success' : 'destructive'}>
                  {approveRequest.includes(value) ? 'Approve' : 'Decline'}
                </Badge>
              ),
            },
          ]}
          data={selectedData}
        />
      </Modal>
    </>
  );
}

export default RequestSubmitButton;
