import { useCallback, useMemo } from 'react';
import { mutateToUrl, persistGetToUrl } from './helpers';
import { capitalize } from 'lodash';
import { ARTIST_TAG_MAPPER } from '@Utils/constants';

const ENDPOINT = {
  ARTIST_COUNT: '/genre/:id/artist/count',
  ARTIST_LIST: '/genre/:id/artists/:platform',
  UPDATE_GENRE_BATCH: '/admin/artists/tags/by-filter',
  INFO: '/genre/:id',
  GROUP: '/genre/:id/relatives',
  ALL_TAGS: '/admin/tag/list',
  UPDATE_RELATION: '/admin/tag/relatives',
  CHANGE_HIDDEN_ALL_ARTISTS: '/admin/artists/tag/hidden',
  CREATE: '/admin/tag',
  TAG: '/admin/:entity/:id/tag',
  GENRE_ORDER: '/admin/genre/:entity/:id/order',
};

export interface Tag {
  id: number;
  name: string;
  type: TagType;
  source: string;
}

export const useGetGenreArtistCount = persistGetToUrl<{
  path: {
    id: number;
  };
}>(ENDPOINT.ARTIST_COUNT, { manual: true });

export const useInsertArtistTagBatch = mutateToUrl<{
  data: {
    code2s?: string[];
    tagFilters?: number[];
    tags: {
      id: number;
      linkType: LinkType;
    }[];
  };
}>('POST', ENDPOINT.UPDATE_GENRE_BATCH);

export const useDeleteArtistTagBatch = mutateToUrl<{
  data: {
    code2s?: string[];
    tagFilters?: number[];
    tags: {
      id: number;
      linkType: LinkType;
    }[];
  };
}>('DELETE', ENDPOINT.UPDATE_GENRE_BATCH);

export const useGetGenreInfo = persistGetToUrl<
  {
    path: {
      id: number;
    };
  },
  {
    id: number;
    name: string;
    counts: {
      artists: number;
      tracks: number;
    };
    type: string;
    source: string;
  }
>(ENDPOINT.INFO);

interface GenreGroupGenreItem {
  id: number;
  name: string;
  source: string;
  type: string;
  url: string;
}

export const useGetGenreGroup = persistGetToUrl<
  {
    path: {
      id: number;
    };
  },
  {
    parents: GenreGroupGenreItem[];
    children: GenreGroupGenreItem[];
  }
>(ENDPOINT.GROUP);

export const useGetAllTags = persistGetToUrl<{}, Tag[]>(ENDPOINT.ALL_TAGS);

export const useInsertTagRelation = mutateToUrl<{
  data: {
    parent: number;
    child: number;
  };
}>('POST', ENDPOINT.UPDATE_RELATION);

export const useHiddenTagFromAllArtists = mutateToUrl<{
  data: {
    tagId: number;
    hidden: boolean;
  };
}>('PATCH', ENDPOINT.CHANGE_HIDDEN_ALL_ARTISTS);

export const useCreateTag = mutateToUrl<{
  data: {
    name: string;
    type: string;
    imageUrl?: string;
    parents: number[];
    children: number[];
  };
}>('POST', ENDPOINT.CREATE);

export type LinkType =
  | 'cm_artist_genre'
  | 'custom_main_genre'
  | 'custom_main_genre_v2'
  | 'cm_artist_genre_v2'
  | 'custom_sub_genre'
  | 'priority_artist'
  | 'hidden'
  | 'artist_career';
export type InternalTagType = 'main' | 'mainV2' | 'sub' | 'artist' | 'artistPriority' | 'others';
export type TagType =
  | 'genre'
  | 'genre_v2'
  | 'artist'
  | 'artist_priority'
  | 'other'
  | 'artist_stage';

export const convertInternalTagTypeToLinkType = (type: InternalTagType): LinkType => {
  return (
    ({
      main: 'custom_main_genre',
      mainV2: 'custom_main_genre_v2',
      sub: 'custom_sub_genre',
      priority: 'priority_artist',
      hidden: 'hidden',
      career: 'artist_career',
      artist: 'artist',
    }[type] as LinkType) ?? 'hidden'
  );
};

export const useTagList = () => {
  const { data, isLoading } = useGetAllTags();

  const { artistTags, mainGenres, mainGenresV2, otherTags, subGenres, artistPriorityTags } = (
    data ?? []
  ).reduce<{
    mainGenres: Tag[];
    mainGenresV2: Tag[];
    subGenres: Tag[];
    artistTags: Tag[];
    artistPriorityTags: Tag[];
    otherTags: Tag[];
  }>(
    (all, tag) => {
      if (tag.source === 'cm' && tag.type === 'genre') {
        all.mainGenres.push(tag);
        return all;
      }
      if (tag.source === 'cm' && tag.type === 'genre_v2') {
        all.mainGenresV2.push(tag);
        return all;
      }

      if (tag.source === 'spotify' && tag.type === 'genre') {
        all.subGenres.push(tag);
        return all;
      }

      if (tag.name.includes('priority_artist')) {
        all.artistPriorityTags.push(tag);
        return all;
      }

      if (tag.type === 'artist' || tag.type === 'artist_stage') {
        all.artistTags.push(tag);
        return all;
      }

      all.otherTags.push(tag);
      return all;
    },
    {
      mainGenres: [],
      mainGenresV2: [],
      subGenres: [],
      artistTags: [],
      artistPriorityTags: [],
      otherTags: [],
    }
  );

  const tagMap = useMemo(
    () => ({
      main: mainGenres,
      mainV2: mainGenresV2,
      sub: subGenres,
      artist: artistTags,
      artistPriority: artistPriorityTags,
      ...otherTags?.reduce<Record<string, Tag[]>>((all, tag) => {
        all[tag.type] = all[tag.type] ?? [];
        all[tag.type].push(tag);
        return all;
      }, {}),
    }),
    [mainGenres, subGenres, artistTags, artistPriorityTags, otherTags, mainGenresV2]
  );

  const tagOptions = useMemo(
    () =>
      Object.keys(tagMap).map(type => ({
        label: capitalize(type),
        value: type,
      })),
    [tagMap]
  );

  const getTagById = useCallback(
    (id: number) => {
      return Object.entries(tagMap)
        .flatMap(([type, tag]) =>
          tag.map(tag => ({ ...tag, internalTagType: type as InternalTagType }))
        )
        .find(({ id: tagId }) => tagId === id);
    },
    [tagMap]
  );

  const hiddenTags: (keyof typeof ARTIST_TAG_MAPPER)[] = [
    'hide_birthday',
    'hide_gender',
    'hide_pronoun',
    'hide_cover_image',
  ];

  const careerTags: (keyof typeof ARTIST_TAG_MAPPER)[] = [
    'override_for_artist_stage_legendary',
    'override_for_artist_stage_superstar',
  ];

  const getLinkType = useCallback(
    (tagId: number) => {
      if (Object.values(ARTIST_TAG_MAPPER).find(id => id === tagId)) {
        if (
          Object.entries(ARTIST_TAG_MAPPER)
            .filter(([key, value]) => hiddenTags.includes(key as keyof typeof ARTIST_TAG_MAPPER))
            .find(([key, value]) => value === tagId)
        ) {
          return 'hidden';
        }
        if (
          Object.entries(ARTIST_TAG_MAPPER)
            .filter(([key, value]) => careerTags.includes(key as keyof typeof ARTIST_TAG_MAPPER))
            .find(([key, value]) => value === tagId)
        ) {
          return 'artist_career';
        }
      }

      const type = Object.entries(tagMap).find(([key, tags]) =>
        tags.find(tag => tag.id === tagId)
      )?.[0];

      return convertInternalTagTypeToLinkType(type as InternalTagType);
    },
    [tagMap]
  );

  return {
    getTagById,
    tagMap,
    tagOptions,
    isLoading,
    getLinkType,
  };
};

export const useAddArtistTag = mutateToUrl<UpdateTagOptions>('POST', ENDPOINT.TAG);
export const useHiddenArtistTag = mutateToUrl<UpdateTagOptions>('PATCH', ENDPOINT.TAG);
export const useDeleteArtistTag = mutateToUrl<UpdateTagOptions>('DELETE', ENDPOINT.TAG);

type UpdateTagOptions = {
  path: {
    id: number;
    entity: 'artist' | 'track';
  };
  query: {
    tag: number;
    type?: LinkType;
  };
};

export const useUpdateTag = (entity: 'artist' | 'track', id: number, callback: () => void) => {
  const { execute: addArtistTag, isLoading: isAdding } = useAddArtistTag();
  const { execute: hiddenArtistTag, isLoading: isHiding } = useHiddenArtistTag();
  const { execute: deleteArtistTag, isLoading: isDeleting } = useDeleteArtistTag();
  const { getLinkType } = useTagList();

  const handleAddArtistGenreTag = async (tag: number, linkType?: LinkType) => {
    await addArtistTag({
      path: { id, entity },
      query: { tag, type: entity === 'artist' ? linkType ?? getLinkType(tag) : undefined },
    });
    return callback();
  };

  const handleDeleteArtistGenreTag = async (tag: number, linkType: LinkType) => {
    await deleteArtistTag({
      path: { id, entity },
      query: { tag, type: entity === 'artist' ? linkType : undefined },
    });
    return callback();
  };

  const handleHiddenArtistGenreTag = async (tag: number, linkType: LinkType) => {
    await hiddenArtistTag({
      path: { id, entity },
      query: { tag, type: entity === 'artist' ? linkType : undefined },
    });
    return callback();
  };

  return {
    handleAddArtistGenreTag,
    handleDeleteArtistGenreTag,
    handleHiddenArtistGenreTag,
    isAdding,
    isDeleting,
    isHiding,
    getLinkType,
  };
};

export const SUPPORTED_GENRE_ORDERING_ENTITIES = ['artist'];

export const useGetGenreOrder = persistGetToUrl<
  {
    path: {
      id: number;
      entity: (typeof SUPPORTED_GENRE_ORDERING_ENTITIES)[number];
    };
  },
  number[]
>(ENDPOINT.GENRE_ORDER);

export const useUpdateGenreOrder = mutateToUrl<{
  path: { id: number; entity: (typeof SUPPORTED_GENRE_ORDERING_ENTITIES)[number] };
  data: { order: number[] };
}>('PATCH', ENDPOINT.GENRE_ORDER);