import React, { useState } from 'react';
import { Badge, Flex, Panel, Separator } from '@/ui';
import { getFormattedLocalDate } from '@/utilities';
import { useGetClaimRequestStatus } from '@/services/request';
import { ActionInput } from '@Shared/Style';
import { capitalize } from 'lodash';

function CheckClaimStatus() {
  const [checkInputValue, setCheckInputValue] = useState('');
  const { refetch, isLoading, data } = useGetClaimRequestStatus({
    path: { code: checkInputValue },
  });

  return (
    <Panel header="Check Claim Status" icon="farCircleCheck">
      <ActionInput
        icon="fadTag"
        placeholder="Put verification code to check status"
        value={checkInputValue}
        onChange={e => setCheckInputValue(e.target.value)}
        action={{
          icon: 'fasCheckCircle',
          content: 'Check',
          variant: 'submit',
          loading: isLoading,
          onClick: () => refetch(),
        }}
      />
      {data &&
        data.map(claim =>
          claim ? (
            <Flex gap={2}>
              <Badge
                className="w-[100px]"
                color={
                  claim.status === 'accepted'
                    ? 'success'
                    : claim.status === 'denied'
                    ? 'error'
                    : 'default'
                }
              >
                {capitalize(claim.status)}
              </Badge>
              <Flex>
                {claim.status === 'pending' ? (
                  'Requested'
                ) : (
                  <>
                    Reviewed by <b>{claim.reviewed_by}</b>
                  </>
                )}{' '}
                at
                <b> {getFormattedLocalDate(claim.modified_at)}</b>
              </Flex>
            </Flex>
          ) : (
            'Not found'
          )
        )}
    </Panel>
  );
}

export default CheckClaimStatus;
