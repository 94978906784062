import React from 'react';
import { useAddSimilarTrack, useUpdateSimilarTrack } from '@Services/track';
import { generateCmUrl } from '@Utils';
import { EditEntityProps } from '..';
import { useGetEntity } from '@Services/search';
import { isEmpty } from 'lodash';
import { GlobalSearch } from '@Shared';
import { Badge, Flex, Panel, Separator } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';

function SimilarTracks({ q }: EditEntityProps) {
  const { isLoading: isAdding, execute: addSimilarTrack } = useAddSimilarTrack();
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <Panel header="Similar Tracks (Related ISRC)">
      <GlobalSearch
        types={['track']}
        size="middle"
        buttonIcon="farPlus"
        buttonLabel="Add"
        icon="farPlus"
        loading={isAdding}
        // TODO: clear after
        onSubmit={(q: string) => {
          const id = q.split('track')[1].split('/')[1];
          addSimilarTrack({
            query: {
              trackId1: Number(id),
              trackId2: Number(data.id),
            },
          }).then(() => {
            refetch();
          });
        }}
      />
      {data?.similarTracks.length > 0 && (
        <>
          <Separator />
          <SimilarTracksList q={q} />
        </>
      )}
    </Panel>
  );
}

export function SimilarTracksList({ q }: EditEntityProps) {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );
  const { isLoading: isUpdating, execute: updateSimilarTrack } = useUpdateSimilarTrack();
  const similarTracks = data?.similarTracks || [];

  return (
    <Flex wrap gap={8}>
      {similarTracks.map(({ id: tagId, hidden, manual }) => (
        <Badge
          onClick={() => {
            window.open(generateCmUrl('track', { id: tagId }));
          }}
          style={{
            width: 'auto',
            cursor: 'pointer',
          }}
          color="success"
        >
          <FAIcon
            loading={isUpdating}
            name={hidden ? 'farEyeSlash' : 'farEye'}
            onClick={() => {
              updateSimilarTrack({
                query: {
                  trackId1: data.id,
                  trackId2: tagId,
                  hidden: !hidden,
                },
              }).then(() => refetch());
            }}
          />
          {tagId}
        </Badge>
      ))}
    </Flex>
  );
}

export default SimilarTracks;
