import { ActionInput, Panel, WhatsThis } from '@Shared/Style';
import FAIcon from '@Components/ui/fa-icons';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import InputSuggestion, { InputSuggestionOption } from '@Shared/InputSuggestion/InputSuggestion';
import { useGetKeywordCounts, useGetKeywordSuggestions } from '@Services/keywords';
import Playlists from './KeywordSearchPlaylists';
import Artists from './KeywordSearchArtists';
import Tracks from './KeywordSearchTracks';
import useURLParams from '@Hooks/useURLParams';
import { isEmpty } from 'lodash';
import { Flex, H3, H4, Muted } from '@/ui';

const KeywordSearch = () => {
  const {
    params: { q },
    setParams,
  } = useURLParams();
  const [suggestionQ, setSuggestionQ] = useState<string>();
  const [duration, setDuration] = useState<'weekly' | 'monthly'>('weekly');
  const { data: suggestions, isLoading } = useGetKeywordSuggestions(
    {
      data: {
        q: suggestionQ ? suggestionQ.replaceAll(' ', '').toLowerCase() : '',
        limit: 50,
        offset: 0,
      },
    },
    {
      disable: isEmpty(suggestionQ),
    }
  );

  const { data: counts } = useGetKeywordCounts(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
      },
    },
    {
      disable: !q,
    }
  );

  const handleChangeInput = useCallback(
    (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSuggestionQ(value.target.value);
    },
    [setSuggestionQ]
  );

  const handleSearch = useCallback(
    (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setParams({ q: String(value.target.value) });
    },
    [setParams]
  );

  const suggestionsData = useMemo(
    () => suggestions?.map(e => ({ value: e, label: e })),
    [suggestions]
  );

  return (
    <Flex vertical gap={2}>
      <ActionInput
        defaultValue={q}
        suggestions={{
          data: suggestionsData ?? [],
          loading: isLoading,
          renderItem: item => <div>{item.label}</div>,
        }}
        onChange={handleChangeInput}
        action={{
          loading: isLoading,
          icon: 'farSearch',
          onClick: handleSearch,
          content: 'Search',
          variant: 'submit',
        }}
        placeholder="Search Keyword"
        className="w-[450px] max-w-[100%]"
      />
      {q && (
        <Flex vertical gap={4} className="mt-4">
          <H3 className="capitalize">{q}</H3>
          <Muted>
            Weekly increase in new playlists made/edited to have this word: {counts?.playlists_diff}
          </Muted>
          <Flex vertical gap={3} className="mb-4">
            <H4 className="mt-4">
              Entities added to These Playlists Most in Past Week
              <WhatsThis>
                This will be its own table, with each row being an artist associated with the
                keyword. They are ranked according to how often then appear on the playlists with
                this keyword
              </WhatsThis>
            </H4>
            <Panel.ResponsiveBody>
              <Artists q={q} />
              <Tracks q={q} />
            </Panel.ResponsiveBody>
          </Flex>
          <Playlists q={q} />
        </Flex>
      )}
    </Flex>
  );
};

export default KeywordSearch;
