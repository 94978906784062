'use client';

import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import React from 'react';
import { useStore } from 'zustand';
import { Footer } from './footer';
import { Sidebar } from './sidebar';
import { ContentLayout } from './content-layout';
import { cn } from '@/lib/utils';
import { useCurrentRoute } from '@Routes/menus';

export default function Layout({ children }: { children: React.ReactNode }) {
  const sidebar = useStore(useSidebarToggle, state => state);

  if (!sidebar) return null;

  return (
    <div className="bg-bg-gradient">
      <Sidebar />
      <div
        className={cn(
          'min-h-[100vh] transition-[margin-left] ease-in-out duration-300 ',
          sidebar?.isOpen === false ? 'lg:ml-[90px]' : 'lg:ml-72'
        )}
      >
        <ContentLayout>{children}</ContentLayout>
      </div>
    </div>
  );
}
