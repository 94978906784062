import ClaimedEntityUsersModal from '@/pages/request-manager/claim/claimed-entities/ClaimedEntityUsersModal';
import { useGetClaimedEntities } from '@Services/meta';
import { AntdTable } from '@Shared';
import Country from '@Shared/Style/Country';
import { generateCmUrl } from '@Utils';
import { TARGET_MAPPER } from '@Utils/constants';
import { Flex } from 'antd';
import React from 'react';
import { Panel } from '@/ui';
import EntityCell from '@Shared/Table/EntityCell';

type Data = {
  code2: string;
  image_url: string;
  name: string;
  target: string;
  id: number;
};

function Claims({ q }) {
  const { data, isLoading } = useGetClaimedEntities({
    data: {
      search: q,
      limit: 100,
      offset: 0,
      target: 'cm_artist',
    },
  });

  return (
    <Panel header="Claims">
      <AntdTable<Data>
        loading={isLoading}
        data={data?.data}
        columns={[
          {
            Header: 'Name',
            accessor: 'name',
            Cell: ({ original, value }) => <EntityCell entity="artist" value={original} />,
          },
          {
            Header: 'Users',
            Cell: ClaimedEntityUsersModal,
          },
        ]}
      />
    </Panel>
  );
}

export default Claims;
