/**
 * Version 240807
 * For native mobile app & admin tool
 */

import axios, { AxiosError, AxiosResponse } from 'axios';
import { isEmpty, merge } from 'lodash';
import { showErrorMessage, showInfoMessage, useMessage } from '../utilities';
import qs from 'qs';
import { useCallback, useEffect, useRef } from 'react';
import {
  MutationKey,
  QueryClient,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';

export const API_BASED_URLs = {
  staging: 'https://staging-api.chartmetric.com',
  development: 'http://localhost:3021',
  production: 'https://staging-api.chartmetric.com',
  test: 'https://test-api.chartmetric.com',
};

interface Response {
  error: string;
  message: string;
  obj: any;
  data: any;
}

// @ts-ignore
export const API_BASED_URL = API_BASED_URLs[process.env.REACT_APP_ENV || process.env.NODE_ENV];
export const API_BASED_URL_FOR_TEST =
  API_BASED_URLs[process.env.NODE_ENV === 'development' ? 'development' : 'test'];

const AXIOS_CONFIGS = {
  baseURL: API_BASED_URL,
  withCredentials: true,
  transformResponse: [
    response => {
      try {
        const json = JSON.parse(response);
        return json;
      } catch (e) {
        return {
          error: e,
        };
      }
    },
  ],
};

const AXIOS_TEST_CONFIGS = {
  baseURL: API_BASED_URL_FOR_TEST,
  withCredentials: true,
  transformResponse: [
    response => {
      try {
        const json = JSON.parse(response);
        return json;
      } catch (e) {
        return {
          error: e,
        };
      }
    },
  ],
};

export const axiosInstance = axios.create(AXIOS_CONFIGS);

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

const getData = (data: any) => {
  if (data?.total) return data;
  if (data?.message) {
    console.warn(data?.message);
  }
  return data?.data || data?.obj || data?.message || data || null;
};

type PathParams = Record<string, string | number>;
type BodyParams = Record<string, unknown> | FormData;
type QueryParams = Record<string, unknown>;

interface MutationParams {
  path?: PathParams;
  data?: BodyParams;
  query?: QueryParams;
}

interface MutationUpdateOptions {
  updateQuery?: { key: string; data: (res: any) => any }[];
  requestQuery?: string[];
  deleteQuery?: string[];
}

interface PersistParams {
  path?: PathParams;
  data?: QueryParams;
}

type MutateMethod = 'POST' | 'PUT' | 'PATCH' | 'DELETE';
type Method = 'GET' | MutateMethod | 'PREFETCH';

type PersistGetToUrlOptions<T, R> = {
  key?: ((params: T) => string | any[]) | string | any[];
  isPermanentData?: boolean;
  noCache?: boolean;
  parse?: (data: any) => R;
  takeLatest?: boolean;
  manual?: boolean;
  defaultParams?: DeepPartial<T>;
  paramsMapper?: (params: T) => T;
  retry?: number;
};

type MutateToUrlOptions<T> = {
  defaultParams?: Partial<T>;
  paramsMapper?: (params: T) => T;
};

type MutateFetchOptions<T> = { params?: T; options?: MutationUpdateOptions } | undefined;

type QueryOptions<R> = {
  disable?: boolean;
  onSuccess?: (data: R, queryClient: QueryClient) => void;
  manual?: boolean;
};

type MutateOptions = {
  loadingMessage?: string;
};

export const persistGetToUrl =
  <T extends PersistParams | undefined = undefined, R = any>(
    endpoint: string,
    options?: PersistGetToUrlOptions<T, R>
  ) =>
  (q?: T, queryOptions?: QueryOptions<R>) => {
    const baseQuery = merge({}, options?.defaultParams, q);
    const query = options?.paramsMapper ? options.paramsMapper(baseQuery) : baseQuery;

    const queryClient = useQueryClient();

    const successCallback = useRef(queryOptions?.onSuccess);
    if (queryOptions?.onSuccess) successCallback.current = queryOptions?.onSuccess;

    const refinedEndpoint = getUrlWithPathParams(endpoint, query?.path, query?.data);

    const cacheKey = options?.key
      ? typeof options.key === 'function'
        ? options.key(query)
        : options.key
      : [refinedEndpoint, query?.data as any];

    if (queryOptions?.disable) return useQuery('', () => null, { enabled: false });

    return useQuery({
      queryKey: cacheKey,
      queryFn: ({ signal }) => {
        if (queryOptions?.disable) return null;
        if (options?.takeLatest) {
          const existingQuery = queryClient.getQueryState(cacheKey);
          if (existingQuery?.isFetching) {
            queryClient.cancelQueries(cacheKey);
          }
        }
        return axiosInstance
          .get(refinedEndpoint, {
            signal,
            // data: query?.data,
          })
          .then(res => {
            if (res.status === 400 || (res?.data as any)?.error) {
              throw new Error(res?.data?.error);
            }
            const data = options?.parse ? options.parse(res?.data) : getData(res?.data);
            return data as R;
          })
          .catch(e => {
            showErrorMessage(getErrorMessage(e));
            throw new Error(e);
          });
      },
      enabled: !options?.manual && !queryOptions?.manual,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: options?.retry === undefined ? 1 : options.retry,
      staleTime: options?.noCache ? 0 : options?.isPermanentData ? undefined : 1000 * 60 * 60,
      onSuccess: data => queryOptions?.onSuccess?.(data!, queryClient),
    });
  };

export const mutateToUrl =
  <T extends MutationParams>(
    method: MutateMethod,
    endpoint: string,
    options?: MutateToUrlOptions<T>
  ) =>
  (mutateOption?: MutateOptions) => {
    const controller = new AbortController();
    const queryClient = useQueryClient();
    const mutationKey: MutationKey = `${method}:${endpoint}`;
    const originalCachedData: Record<string, any> = {};

    const { showLoading, showSuccessMessage, showErrorMessage, destroyMessage } =
      useMessage(mutationKey);

    const { mutateAsync, data, isSuccess, isLoading, isError } = useMutation({
      mutationKey,
      mutationFn: (q: MutateFetchOptions<T>) => {
        const baseQuery = merge({}, options?.defaultParams, q?.params);
        const query = options?.paramsMapper ? options.paramsMapper(baseQuery) : baseQuery;
        const endpointWithQuery = getUrlWithPathParams(endpoint, query?.path, query.query);

        return axiosInstance[method.toLowerCase()](
          endpointWithQuery,
          ...(method === 'DELETE'
            ? [
                {
                  data: q?.params?.data,
                  signal: controller.signal,
                },
              ]
            : [
                q?.params?.data,
                {
                  signal: controller.signal,
                },
              ])
        );
      },
      onMutate: variables => {
        showLoading(mutateOption?.loadingMessage);

        const options = variables?.options;
        if (options?.updateQuery) {
          options.updateQuery.forEach(q => {
            const data = queryClient.getQueryData(q.key);
            const res = getData(data);

            originalCachedData[q.key] = queryClient.getQueryData(q.key);
            queryClient.setQueryData(q.key, q.data(res));
          });
        }
        if (options?.deleteQuery) {
          options.deleteQuery.forEach(q => {
            originalCachedData[q] = queryClient.getQueryData(q);
            queryClient.removeQueries(q);
          });
        }
      },
      onSuccess: (data, variables) => {
        const options = variables?.options;

        if (typeof getData(data?.['data']) === 'string') {
          showSuccessMessage(getData(data?.['data']));
        }

        if (options?.requestQuery) {
          options.requestQuery.forEach(q => {
            originalCachedData[q] = queryClient.getQueryData(q);
            queryClient.invalidateQueries(q);
          });
        }
      },
      onSettled: () => {
        destroyMessage();
      },
      onError: e => {
        queryClient.setQueryData(mutationKey, originalCachedData[mutationKey]);
        showErrorMessage(getErrorMessage(e));
      },
    });

    const cancel = () => {
      controller.abort();
      queryClient.cancelQueries(mutationKey);
    };

    const execute = (q?: T, options?: MutationUpdateOptions) =>
      mutateAsync({
        params: q,
        options,
      }) as Promise<AxiosResponse<Response, any>>;

    return {
      execute,
      data: getData(data),
      isSuccess,
      isLoading,
      isError,
      mutationKey,
      cancel,
    };
  };

const getUrlWithPathParams = (
  endpoint: string,
  pathParams?: Record<string, string | number>,
  queryParams?: Record<string, unknown>
) => {
  const res = endpoint
    .split('/')
    .map(s => {
      if (s.includes(':')) {
        const key = s.substring(1);
        return pathParams?.[key];
      }
      return s;
    })
    .filter(Boolean)
    .join('/');

  if (queryParams) {
    const queryString = qs.stringify(queryParams, { encode: true });
    return `${res}${queryString ? `?${queryString}` : ''}`;
  }
  return res;
};

export const persistGetToUrlBatch =
  <T extends PersistParams | undefined = undefined, R = any>(
    endpoint: string,
    options?: PersistGetToUrlOptions<T, R>
  ) =>
  (queries?: T[], queryOptions?: QueryOptions<R>) => {
    const queryClient = useQueryClient();

    if (!queries) return null;

    return useQueries(
      queries?.map(query => {
        const baseQuery = merge({}, options?.defaultParams, query);
        const queryParams = options?.paramsMapper ? options.paramsMapper(baseQuery) : baseQuery;
        const refinedEndpoint = getUrlWithPathParams(endpoint, queryParams?.path);

        const cacheKey = options?.key
          ? typeof options.key === 'function'
            ? options.key(queryParams)
            : options.key
          : [refinedEndpoint, queryParams?.data as unknown];

        return {
          queryKey: cacheKey,
          queryFn: async () => {
            if (queryOptions?.disable) return null;

            if (options?.takeLatest) {
              const existingQuery = queryClient.getQueryState(cacheKey);
              if (existingQuery?.isFetching) {
                queryClient.cancelQueries(cacheKey);
              }
            }

            return axiosInstance
              .get(refinedEndpoint, queryParams?.data)
              .then(res => {
                if (res.status === 400 || (res?.data as unknown)?.['error']) {
                  throw new Error(res?.data?.error);
                }
                if (options?.parse) {
                  return options.parse(res?.data);
                }

                return getData(res?.data) as R;
              })
              .catch(e => {
                showErrorMessage(getErrorMessage(e));
                throw new Error(e);
              });
          },
          enabled: !options?.manual,
          refetchOnMount: !options?.isPermanentData,
          refetchOnWindowFocus: true,
          refetchOnReconnect: true,
          retry: options?.retry === undefined ? 3 : options.retry,
          staleTime: options?.isPermanentData ? undefined : 1000 * 60 * 60,
        };
      }) || []
    );
  };

/**
 * It's not the hook.
 */
export const prefetchQueries =
  <T extends PersistParams | undefined = undefined, R = any>(
    endpoint: string,
    options?: PersistGetToUrlOptions<T, R>
  ) =>
  (queryClient: QueryClient, q?: T[]) => {
    q?.forEach(qs => {
      const baseQuery = merge({}, options?.defaultParams, qs);
      const query = options?.paramsMapper ? options.paramsMapper(baseQuery) : baseQuery;
      const refinedEndpoint = getUrlWithPathParams(endpoint, query?.path);

      const cacheKey = options?.key
        ? typeof options.key === 'function'
          ? options.key(query)
          : options.key
        : [refinedEndpoint, query?.data as any];

      queryClient.prefetchQuery(cacheKey, {
        queryFn: () =>
          axiosInstance
            .get(refinedEndpoint, query?.data)
            .then(res => {
              if (res.status === 400 || (res?.data as any)?.error) {
                throw new Error(res?.data?.error);
              }

              const data = options?.parse ? options.parse(res?.data) : getData(res?.data);
              return data as R;
            })
            .catch(e => {
              showErrorMessage(getErrorMessage(e));
              throw new Error(e);
            }),
      });
    });
  };

// FIXME: refactor this
export const useCache = <T = any>(cacheKey: string) => {
  const queryClient = useQueryClient();
  return useQuery(cacheKey, () => queryClient.getQueryData(cacheKey) as T);
};
// FIXME: refactor this

export const useStore = <T = any>() => {
  const queryClient = useQueryClient();

  const update = (key: string, data: T) => {
    queryClient.setQueryData(key, data);
  };

  return update;
};

// FIXME: refactor this

export const useCustomQuery = <T = any>(cacheKey: string | any[]) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(cacheKey) as T;
  const update = useCallback(
    (data: T) => queryClient.setQueryData(cacheKey, data),
    [queryClient, cacheKey]
  );

  return { data, update };
};

const getErrorMessage = (e: unknown) =>
  e?.['response']?.data?.error || e?.['response']?.data?.message;

