'use client';

import React, { useEffect } from 'react';
import { ScrollArea, TooltipRoot, TooltipContent, TooltipProvider, TooltipTrigger } from '@/ui';
import { Ellipsis, LogOut } from 'lucide-react';
import { CollapseMenuButton } from './collapse-menu-button';
import { Link, useHistory } from 'react-router-dom';
import { useUser } from '@Services/auth';
import FAIcon from '@Components/ui/fa-icons';
import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import { cn } from '@/lib/utils';
import { Button } from '../button';
import { checkActiveMenu, checkActiveSubmenu, getSidebarMenus } from '@Routes/sidebar';
import { MENUS } from '@Routes/menus';

interface MenuProps {
  isOpen: boolean | undefined;
}

export function Menu({ isOpen }: MenuProps) {
  const { logout } = useUser();
  const { group } = useSidebarToggle();

  return (
    <ScrollArea className="h-[calc(100vh-74px)]">
      <nav className="mt-8">
        <ul className="flex flex-col items-start space-y-1 px-2">
          {getSidebarMenus(MENUS)
            .filter(menu => menu.group === group || !menu.group)
            .map(({ menus }, index) => (
              <li className={cn('w-full', group && 'mb-4')} key={index}>
                {menus.map(({ href, label, icon: Icon, submenus }, index) =>
                  submenus.length === 0 ? (
                    <div className="w-full " key={index}>
                      <TooltipProvider disableHoverableContent>
                        <TooltipRoot delayDuration={100}>
                          <TooltipTrigger asChild>
                            <Button
                              variant={checkActiveMenu(href) ? 'default' : 'ghost'}
                              className={cn(
                                'w-full justify-start h-10 mb-1 rounded-xl hover:bg-primary hover:bg-opacity-10 hover:text-primary-foreground',
                                checkActiveMenu(href) ? ' font-semibold' : 'text-gray-400'
                              )}
                              asChild
                            >
                              <Link to={href} className="text-foreground">
                                <span className={cn('flex', isOpen === false ? '' : 'mr-4')}>
                                  <FAIcon name={Icon} className={cn('w-[16px] h-[16px]')} />
                                </span>
                                <p
                                  className={cn(
                                    'max-w-[200px] truncate',
                                    isOpen === false
                                      ? '-translate-x-96 opacity-0'
                                      : 'translate-x-0 opacity-100'
                                  )}
                                >
                                  {label}
                                </p>
                              </Link>
                            </Button>
                          </TooltipTrigger>
                          {isOpen === false && (
                            <TooltipContent side="right">{label}</TooltipContent>
                          )}
                        </TooltipRoot>
                      </TooltipProvider>
                    </div>
                  ) : (
                    <div className="w-full" key={index}>
                      <CollapseMenuButton
                        route={href}
                        active={checkActiveSubmenu(href, submenus)}
                        icon={Icon}
                        label={label}
                        submenus={submenus}
                        isOpen={isOpen}
                      />
                    </div>
                  )
                )}
              </li>
            ))}
        </ul>
      </nav>
    </ScrollArea>
  );
}
