import React, { useMemo } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { capitalizeEveryWord } from '@Utils';
import { SUPPORTED_GENRE_ORDERING_ENTITIES, useUpdateGenreOrder } from '@Services/tag';
import { useGenrePanel } from '../context/GenrePanelContext';
import { getTagColor } from '../context/constants';
import FAIcon from '@Components/ui/fa-icons';
import { EntityTag } from '../context/types';
import { Panel, Button, Badge, Flex, Tooltip } from '@/ui';

const GenreOrder = () => {
  const { entity, orders, groupedTags, onEditOrderGenre, onChangeOrder, id } = useGenrePanel();
  const { execute, isLoading } = useUpdateGenreOrder({
    loadingMessage: 'Updating order...',
  });

  if (!SUPPORTED_GENRE_ORDERING_ENTITIES.includes(entity)) {
    return null;
  }

  const genres = useMemo(
    () =>
      orders
        .map(id =>
          Object.values(groupedTags)
            .flatMap(e => Object.values(e))
            .flat()
            .filter(e => !e.hidden)
            .find(e => e.id === id)
        )
        .filter(Boolean) as EntityTag[],
    [orders, groupedTags]
  );

  const handleOnDragEnd = result => {
    if (!result.destination) {
      return;
    }

    const newOrders = Array.from(orders);
    const [reorderedItem] = newOrders.splice(result.source.index, 1);
    newOrders.splice(result.destination.index, 0, reorderedItem);

    onChangeOrder(newOrders);
  };

  return (
    <Panel
      header="Ordering"
      aside={
        <Button
          variant="submit"
          icon={<FAIcon name={'farEdit'} />}
          onClick={() =>
            execute({
              path: { entity, id },
              data: { order: orders },
            })
          }
          loading={isLoading}
        >
          Save
        </Button>
      }
      description={
        <>
          The order is for displaying in productions. You can change the order by dragging the tags
          and add or delete. It will reflect to production by data-side scheduling.
        </>
      }
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="genres" direction="horizontal">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex p-2 overflow-auto border-border border gap-2"
            >
              {genres.map((genre, index) => (
                <Draggable key={genre.id} draggableId={genre.id.toString()} index={index}>
                  {provided => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <Badge
                        key={id}
                        style={{
                          backgroundColor: getTagColor(
                            genre.internalTagType,
                            genre.hidden,
                            genre.source
                          ),
                        }}
                      >
                        <Flex align="center" gap={4}>
                          {capitalizeEveryWord(genre.name.replaceAll('_', ' '))}
                          <FAIcon
                            name={'fasCircleXmark'}
                            onClick={() => onEditOrderGenre(genre.id)}
                          />
                        </Flex>
                      </Badge>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Panel>
  );
};

export default GenreOrder;
