import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  getFormattedLocalDate,
  getDateFormatFromEpoch,
  showErrorMessage,
  showInfoMessage,
} from '../../../../utilities';
import SearchInput from '../../../../shared/Table/SearchInput';
import CampaignContentModal from '../Components/CampaignContentModal';
import useModal from '../../../../hooks/useModal';
import {
  campaignStripePortal,
  useAddCampaign,
  useDeleteCampaign,
  useGetCampaign,
  useGetCampaignContentById,
  useGetStripeCoupons,
} from '../../../../services/campaign';
import styles from '../Campaign.module.scss';
import StripeCouponModal from '../Components/StripeCouponModal';
import { STRIPE_STANDARD_PLANS } from '../../../../utilities/constants';
import FAIcon from '../../../../components/ui/fa-icons';
import { Button, Flex, Input, ModalButton, Muted, Panel, Skeleton } from '@/ui';
import useCampaign from '../useCampaign';
import AddCampaignSection from '../Components/AddCampaignSection';
import { Table } from '@Components';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { useGetCoupons } from '@Services/purchase';
import ContentCard from '../Components/ContentCard';
import { useUser } from '@Services/auth';
// TODO: coupon panel design

const Campaign = () => {
  const campaignProps = useCampaign();
  const [domain, setDomain] = useState('');
  const { setAllStates, priceId, coupon, content, enableCoupon } = campaignProps;

  const { execute } = useAddCampaign();
  const { refetch } = useGetCampaign();

  const handleSubmit = () => {
    execute({
      data: {
        domain,
        contentId: content?.id,
        couponCode: coupon?.id,
        priceId: priceId!,
      },
    }).then(() => {
      refetch();
      setDomain('');
      setAllStates({
        priceId: undefined,
        coupon: undefined,
        content: undefined,
        enableCoupon: false,
      });
    });
  };

  const disabled = isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !coupon);

  return (
    <>
      <Panel
        classNames={{
          footer: 'flex justify-end',
        }}
        header="Campaign For Domain"
        description="When a user from a specific domain enters the Campaign page, the event plan is automatically applied."
        footer={
          <>
            <Muted>
              The Stripe plans provided in the campaign will automatically renew once the plan's
              duration has ended. A plan priced at $0 means that once a user subscribes, they can
              use it for free indefinitely. Avoid using the $0 plan and use a coupon instead.
            </Muted>
            <Button color="green" onClick={handleSubmit} disabled={disabled}>
              Add New Campaign
            </Button>
          </>
        }
      >
        <Table
          options={[
            {
              label: 'Email Domain *',
              component: (
                <Input
                  value={domain}
                  onChange={e => setDomain(e.target.value)}
                  placeholder="ex) chartmetric.com"
                />
              ),
            },
          ]}
        />
        <AddCampaignSection {...campaignProps} />
      </Panel>
      <br />
      <CampaignTable />
    </>
  );
};

const CampaignTable = () => {
  const { data, isLoading, refetch } = useGetCampaign();
  const { stripeProducts } = useCampaign();
  const { data: coupons } = useGetStripeCoupons();
  const { authData } = useUser();

  const { execute: deleteCampaign, isLoading: isLoadingDelete } = useDeleteCampaign();

  const getCouponName = couponId => coupons?.find(coupon => coupon.id === couponId)?.name ?? '';

  const getProductItem = productId => {
    return stripeProducts?.products?.find(e => e.id === productId)!;
  };

  //   getPriceItem(productId, priceId) {
  //     return this.props.optionData.prices?.[productId]?.find(e => e.id === priceId);
  //   }

  //   checkFormFilled = () => {
  //     const { domain, enableCoupon, couponCode, productId, priceId } = this.state;
  //     return isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !couponCode);
  //   };

  const getPriceItemName = priceId => {
    const item = Object.values(stripeProducts?.prices ?? {})
      .flat()
      .find(e => e.id === priceId);
    if (!item) return { nickname: '', prodNickname: '' }; //for local test
    const { name: prodNickname } = getProductItem(item.product);
    return { nickname: item.nickname, prodNickname };
  };

  //   deleteCampaign = id => this.props.deleteCampaign({ id }).then(() => this.props.getCampaignList());

  const redirectStripePortal = item => {
    const { coupon_code, price_id, domain } = item;

    campaignStripePortal({
      priceId: price_id,
      email: authData?.email,
      artistIds: undefined,
      couponCode: isEmpty(coupon_code) ? undefined : coupon_code,
    });
  };

  const columns: Column<any>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 100,
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      width: 150,
    },
    {
      Header: 'Price Plan',
      accessor: 'price_id',
      Cell: ({ value }) => {
        const item = getPriceItemName(value);
        return (
          <Flex vertical>
            <span>
              {item?.prodNickname} - {item?.nickname}
            </span>
            <Muted>{value}</Muted>
          </Flex>
        );
      },
    },
    {
      Header: 'Coupon Code',
      accessor: 'coupon_code',
      Cell: props => {
        const name = getCouponName(props.value);
        return (
          <Flex vertical>
            {name}
            <Muted>{props.value}</Muted>
          </Flex>
        );
      },
    },
    {
      Header: 'Content',
      accessor: 'content',
      width: 100,
      Cell: ({ value }) => {
        if (value)
          return (
            <ModalButton trigger={<Button size="sm" icon={<FAIcon name="farEye" />} />}>
              <ContentModalContent id={value} />
            </ModalButton>
          );
        return null;
      },
    },
    {
      Header: 'Date',
      accessor: 'timestp',
      formatter: value => getFormattedLocalDate(value),
      width: 100,
    },
    {
      Header: 'Delete',
      accessor: 'id',
      Cell: ({ value }) => (
        <Button
          loading={isLoadingDelete}
          variant="outlineDestructive"
          onClick={() =>
            deleteCampaign({
              path: {
                id: value,
              },
            }).then(() => refetch())
          }
          icon={<FAIcon name="farTrash" />}
        />
      ),
      width: 60,
    },
    {
      Header: 'Test Stripe URL',
      accessor: 'id',
      Cell: props => (
        <Button onClick={() => redirectStripePortal(props.original)}>Test Stripe URL</Button>
      ),
      width: 150,
    },
  ];

  return (
    <Panel
      header="Campaigns"
      description="After adding the email domain to the Campaign feature, users should log in to the Chartmetric website and access https://app.chartmetric.com/campaign."
    >
      <AntdTable loading={isLoading} data={data} columns={columns} />
    </Panel>
  );
};

export default Campaign;

const ContentModalContent = ({ id }) => {
  const { data } = useGetCampaignContentById({ path: { id } });

  if (data) {
    return <ContentCard content={data} />;
  }
  return <Skeleton className="w-[300px] h-[300px]" />;
};

// class Campaign extends Component {
//   state = {
//     // display state
//     enableCoupon: false,
//     productId: undefined,
//     // parameters
//     domain: '',
//     priceId: undefined,
//     couponCode: undefined,
//     content: undefined,
//     // result
//     url: undefined,
//     // table
//     filteredDataCouponCode: undefined,
//     filteredDataCampaign: undefined,
//   };

//   componentDidMount() {
//     // this.props.getStripeProducts();
//     // this.props.getCampaignList();
//     // this.props.getStripeCoupons();
//   }

//   getPlanProductOptions() {
//     const { optionData } = this.props;
//     return optionData.products
//       ?.reduce((all, one) => {
//         all.push({
//           text: one.name,
//           value: one.id,
//           label: {
//             color: STRIPE_STANDARD_PLANS.includes(one.id) ? 'blue' : 'white',
//             empty: true,
//             circular: true,
//           },
//         });
//         return all;
//       }, [])
//       .sort((a, b) => (STRIPE_STANDARD_PLANS.includes(a.value) ? -1 : 1));
//   }

//   getPlanPriceOptions() {
//     const { optionData } = this.props;
//     const { productId } = this.state;
//     return optionData.prices?.[productId]
//       ?.reduce((all, one) => {
//         all.push({
//           value: one.id,
//           text: ` ${one.nickname} - $${one.unit_amount / 100} per ${
//             one.recurring?.interval_count
//           } ${one.recurring?.interval}`,
//           label: {
//             color: STRIPE_STANDARD_PLANS.includes(one.id) ? 'blue' : 'white',
//             empty: true,
//             circular: true,
//           },
//         });
//         return all;
//       }, [])
//       .sort((a, b) => (STRIPE_STANDARD_PLANS.includes(a.value) ? -1 : 1));
//   }

//   handleCopy = e => {
//     showInfoMessage('Copied!');
//     navigator.clipboard.writeText(e.target.id);
//   };

//   handleSearchCouponCode = filteredData => {
//     this.setState({
//       filteredDataCouponCode: filteredData,
//     });
//   };

//   handleSearchCampaign = filteredData => {
//     this.setState({
//       filteredDataCampaign: filteredData,
//     });
//   };

//   handleClick = () => {
//     const { domain, couponCode, priceId, content } = this.state;
//     this.props.addCampaign({ domain, couponCode, priceId, contentId: content?.id }).then(value => {
//       this.props.getCampaignList();
//       this.setState({
//         // display state
//         enableCoupon: false,
//         productId: undefined,
//         // parameters
//         domain: '',
//         priceId: undefined,
//         couponCode: undefined,
//       });
//     });
//   };

//   handleChangeInput = (e, { value, name }) => this.setState({ [name]: value });

//   handleChangePlan = (e, { value, name }) => {
//     this.setState({ [name]: value, couponCode: undefined });
//   };

//   handleChangeCouponCheckbox = (e, { checked, name }) => {
//     if (!checked) {
//       this.setState({ couponCode: undefined });
//     }
//     this.setState({ [name]: checked });
//   };

//   handleSelectCoupon =
//     ({ id }) =>
//     () => {
//       this.setState({ couponCode: id });
//     };

//   handleClickCreateCoupon = () => {
//     window.open('https://dashboard.stripe.com/coupons/create', '_blank');
//   };

//   handleClickRefreshCouponList = () => {
//     this.props.deleteCacheKey(COUPON_LIST_CACHE_KEY).then(() => this.props.getStripeCoupons());
//   };

//   getCouponName = couponId =>
//     this.props.couponData.find(coupon => coupon.id === couponId)?.name ?? '';

//   getProductItem(productId) {
//     return this.props.optionData.products?.find(e => e.id === productId);
//   }

//   getPriceItem(productId, priceId) {
//     return this.props.optionData.prices?.[productId]?.find(e => e.id === priceId);
//   }

//   checkFormFilled = () => {
//     const { domain, enableCoupon, couponCode, productId, priceId } = this.state;
//     return isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !couponCode);
//   };

//   getPriceItemName = priceId => {
//     const item = Object.values(this.props.optionData.prices ?? {})
//       .flat()
//       .find(e => e.id === priceId);
//     if (!item) return { nickname: '', prodNickname: '' }; //for local test
//     const { name: prodNickname } = this.getProductItem(item.product);
//     return { nickname: item.nickname, prodNickname };
//   };

//   deleteCampaign = id => this.props.deleteCampaign({ id }).then(() => this.props.getCampaignList());

//   redirectStripePortal = item => {
//     const { coupon_code, price_id, domain } = item;

//     this.props.stripeCreateCheckoutSession(
//       price_id,
//       `hi@${domain}`,
//       undefined,
//       isEmpty(coupon_code) ? undefined : coupon_code
//     );
//   };

//   handleSelectContent = content => {
//     this.setState({ content });
//   };

//   render() {
//     const {
//       campaignData,
//       couponData,
//       isLoadingCoupon,
//       isFetching,
//       isLoadingOption,
//       isLoadingCampaign,
//     } = this.props;
//     const { couponCode, domain, productId, priceId, content } = this.state;

//     return (
//       <>
//         <Panel
//           title="Campaign For Domain"
//           description={
//             <>
//               When a user from a specific domain enters the Campaign page, the event plan is
//               automatically applied.
//               <a
//                 href="https://app.chartmetric.com/campaign"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 https://app.chartmetric.com/campaign
//               </a>
//             </>
//           }
//         >
//           <Table>
//             <Table.Body>
//               <Table.Row>
//                 <Table.Cell>Email Domain</Table.Cell>
//                 <Table.Cell>
//                   <Input
//                     fluid
//                     label={{ icon: 'mail' }}
//                     name="domain"
//                     onChange={this.handleChangeInput}
//                     placeholder="ex) chartmetric.com"
//                     size="large"
//                     value={domain}
//                   />
//                 </Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Plan</Table.Cell>
//                 <Table.Cell>
//                   <Dropdown
//                     loading={isLoadingOption}
//                     options={this.getPlanProductOptions()}
//                     value={this.state.productId}
//                     onChange={this.handleChangePlan}
//                     name="productId"
//                     floating
//                     labeled
//                     className="icon"
//                     search
//                     selection
//                     placeholder="Select Product"
//                   />
//                   <Dropdown
//                     loading={isLoadingOption}
//                     options={this.getPlanPriceOptions()}
//                     value={this.state.priceId}
//                     onChange={this.handleChangePlan}
//                     name="priceId"
//                     floating
//                     labeled
//                     className="icon"
//                     search
//                     selection
//                     placeholder="Select Price"
//                     disabled={!productId}
//                   />
//                 </Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Coupon</Table.Cell>
//                 <Table.Cell>
//                   <Checkbox
//                     name="enableCoupon"
//                     onChange={this.handleChangeCouponCheckbox}
//                     checked={this.state.enableCoupon}
//                     label={`${this.getCouponName(couponCode)} ${
//                       couponCode ? `(${couponCode})` : 'Coupon Not selected'
//                     }`}
//                     toggle
//                   />
//                 </Table.Cell>
//               </Table.Row>
//               <Table.Row>
//                 <Table.Cell>Page Content</Table.Cell>
//                 <Table.Cell>
//                   {content && (
//                     <>
//                       <div>Title :{content.title}</div>
//                       <div>Sentence : {content.sentence}</div>
//                       <div>Image : {content.image_url && <img src={content.image_url} />}</div>
//                       <div>
//                         Button : {content.button_label && <img src={content.button_label} />}
//                       </div>
//                       <br />
//                     </>
//                   )}
//                   <CampaignContentModal onSelect={this.handleSelectContent} />
//                 </Table.Cell>
//               </Table.Row>
//             </Table.Body>
//           </Table>
//           <Divider hidden />
//           <Flex style={{ width: '100%' }} gap="middle">
//             The Stripe plans provided in the campaign will automatically renew once the plan's
//             duration has ended. A plan priced at $0 means that once a user subscribes, they can use
//             it for free indefinitely. Avoid using the $0 plan and use a coupon instead.
//             <Button
//               loading={isFetching}
//               fluid
//               size="large"
//               color="green"
//               onClick={this.handleClick}
//               disabled={this.checkFormFilled()}
//             >
//               Add New Campaign
//             </Button>
//           </Flex>
//           {this.state.url && (
//             <Segment className="text-wrap">
//               <a href={this.state.url} target="_blank" rel="noopener noreferrer">
//                 Generated Link
//               </a>{' '}
//               :{' '}
//               <Button id={this.state.url} className="green" onClick={this.handleCopy}>
//                 Copy
//               </Button>
//             </Segment>
//           )}
//         </Panel>
//         <StripeCouponPanel
//           selectedCoupon={{ id: couponCode }}
//           enableCoupon={this.state.enableCoupon}
//           onSelectCoupon={this.handleSelectCoupon}
//           priceId={priceId}
//           productId={productId}
//           products={this.props.optionData.products}
//         />
//         <Panel
//           loading={isLoadingCampaign}
//           title="Campaigns"
//           description="After adding the email domain to the Campaign feature, users should log in to the Chartmetric website and access https://app.chartmetric.com/campaign."
//         >
//           <SearchInput originalData={campaignData} onChange={this.handleSearchCampaign} />
//           <br />
//           <DataTable
//             key="campaigns"
//             data={
//               this.state.handleSearchCampaign !== undefined
//                 ? this.state.filteredDataCampaign
//                 : campaignData
//             }
//             columns={COLUMNS_CAMPAIGN(
//               this.getPriceItemName.bind(this),
//               this.getCouponName.bind(this),
//               this.deleteCampaign.bind(this),
//               this.redirectStripePortal.bind(this)
//             )}
//           />
//         </Panel>
//       </>
//     );
//   }
// }
