import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../actions';

import Info from './Artist/Info';
import { Segments } from '../../../shared/Style';
import ExternalLinksPanel from '../../../components/external-links-panel/ExternalLinksPanel';
import ArtistLinksDetails from './Artist/LinksDetails';
import ArtistLinksHistory from './Artist/LinksHistory';
import { EditEntityProps } from '.';
import { useGetEntity } from '@Services/search';
import UpdateTrackNAlbum from './Artist/UpdateTrackOrAlbum';
import { ENTITY_ICON } from '@Constants/entity';
import { EditGenrePanel } from '@Components';

interface Props extends EditEntityProps {}

const EditArtist = ({ q }: Props) => {
  const { data, refetch } = useGetEntity({
    data: {
      q,
    },
  });

  const { id, tags, urls } = data;

  return (
    <Segments
      options={[
        {
          title: 'Artist Info',
          content: <Info q={q} />,
          icon: 'farInfoCircle',
        },
        {
          title: 'DSP & Social Links',
          content: (
            <ExternalLinksPanel
              type="artist"
              id={id}
              urls={urls}
              callback={() => refetch()}
              meta={{
                artistName: data?.name,
              }}
            />
          ),
          icon: 'farLink',
        },
        {
          title: 'Genre',
          icon: ENTITY_ICON.GENRE,
          content: <EditGenrePanel entity="artist" id={id} tags={tags} callback={refetch} />,
        },
        {
          title: 'Update Tracks',
          icon: ENTITY_ICON.TRACK,
          content: <UpdateTrackNAlbum type="tracks" q={q} />,
        },
        {
          title: 'Update Albums',
          icon: ENTITY_ICON.ALBUM,
          content: <UpdateTrackNAlbum type="albums" q={q} />,
        },
        {
          title: 'Artist Links Details',
          content: <ArtistLinksDetails {...data} />,
          icon: 'farFile',
        },
        {
          title: 'Artist Links History',
          content: <ArtistLinksHistory {...data} />,
          icon: 'farHistory',
        },
      ]}
    />
  );
};

export default EditArtist;
