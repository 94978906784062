import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  USER: '/admin/user',
  LINKS_BY_USER: {
    DETAILS: `/admin/links-by-user/details`,
    HISTORY: `/admin/links-by-user/history`,
  },
  USER_SESSION_COUNTS: `/admin/user/sessions/:type`,
  USER_FOLLOWED_ARTISTS: `/admin/user/:id/followed/artists`,
  USER_VIEWABLE_ARTISTS: `/admin/user/:id/viewable/artists`,
  TRUSTED_USER: '/admin/trusted-user',
  ADMIN_USER: '/admin/admin-user',
  SIGN_UPS: '/admin/user/sign-ups',
  UPDATE_SOURCE_UPDATED: '/admin/user/sign-ups/source-updated',
  RECENTLY_VISITED: '/admin/recentlyVisited/:id',
  PUSH_NOTIFICATION: '/admin/user/push-notification',
  PUSH_NOTIFICATION_ALL: '/admin/user/push-notification/all',
  SEND_VERIFY_EMAIL: '/admin/send-verify-email',
  VERIFY_USER_EMAIL: '/admin/user/:id/verify-last-login',
  UPDATE_EMAIL: '/admin/user/email/update',
  UPDATE_NAME: '/admin/user/name/update',
  SEND_EMAIL_REPORT: '/admin/email-report/general',
  TRANSFER_FAVORITES: '/admin/transfer/favorites',
};

export const useUnblockLast2FA = mutateToUrl<{
  path: {
    id: number;
  };
}>('PUT', ENDPOINT.VERIFY_USER_EMAIL);
export const useGetUser = persistGetToUrl<{
  data: { q: string };
}>(ENDPOINT.USER);
export const useGetLinksByUserDetails = persistGetToUrl<{
  data: { q: string; fromDaysAgo: number; toDaysAgo: number };
}>(ENDPOINT.LINKS_BY_USER.DETAILS);
export const useGetLinksByUserHistory = persistGetToUrl(ENDPOINT.LINKS_BY_USER.HISTORY);
export const useGetUserSessionCounts = persistGetToUrl<{
  path: {
    type: string;
  };
  data: {
    email: string;
    date: string | Date;
  };
}>(ENDPOINT.USER_SESSION_COUNTS);

export const useGetRecentlyVisited = persistGetToUrl<{
  path: {
    id: number;
  };
  data: {
    since: string | null;
    until: string | null;
  };
}>(ENDPOINT.RECENTLY_VISITED, {
  manual: true,
});

export const useGetUserFollowedArtist = persistGetToUrl<{
  path: {
    id: number;
  };
}>(ENDPOINT.USER_FOLLOWED_ARTISTS);
export const useAddUserFollowedArtist = mutateToUrl('POST', ENDPOINT.USER_FOLLOWED_ARTISTS);
export const useDeleteUserFollowedArtist = mutateToUrl('DELETE', ENDPOINT.USER_FOLLOWED_ARTISTS);

export const useGetUserViewableArtist = persistGetToUrl(ENDPOINT.USER_VIEWABLE_ARTISTS);
export const useAddUserViewableArtist = mutateToUrl('POST', ENDPOINT.USER_VIEWABLE_ARTISTS);
export const useDeleteUserViewableArtist = mutateToUrl('DELETE', ENDPOINT.USER_VIEWABLE_ARTISTS);

export const useGetTrustedUser = persistGetToUrl(ENDPOINT.TRUSTED_USER);
export const useAddTrustedUser = mutateToUrl<{
  data: { q: string };
}>('POST', ENDPOINT.TRUSTED_USER);
export const useDeleteTrustedUser = mutateToUrl<{
  data: { q: string };
}>('PUT', ENDPOINT.TRUSTED_USER);

export const useGetSignUps = persistGetToUrl<{
  data: { since: string; until: string; subscriptionLevel: number };
}>(ENDPOINT.SIGN_UPS);

export const useUpdateSourceUpdatedForUser = mutateToUrl<{
  data: { userId: number; updated: boolean };
}>('PATCH', ENDPOINT.UPDATE_SOURCE_UPDATED);

export const useSendPushNotification = mutateToUrl<{
  data: { emails: string[]; message: string; data: object; title: string };
}>('POST', ENDPOINT.PUSH_NOTIFICATION);

export const useSendPushNotificationAll = mutateToUrl<{
  data: { message: string; data: object; title: string };
}>('POST', ENDPOINT.PUSH_NOTIFICATION_ALL);

export const useSendVerifyEmail = mutateToUrl<{
  data: { email: string };
}>('PUT', ENDPOINT.SEND_VERIFY_EMAIL);

export const useVerify2FA = mutateToUrl<{
  path: { id: number };
}>('PUT', ENDPOINT.VERIFY_USER_EMAIL);

export const useUpdateUserEmail = mutateToUrl<{
  data: {
    id: number;
    email: string;
  };
}>('PUT', ENDPOINT.UPDATE_EMAIL);

export const useUpdateUserName = mutateToUrl<{
  data: {
    id: number;
    name: string;
  };
}>('PUT', ENDPOINT.UPDATE_NAME);

export const useGetAdminUsers = persistGetToUrl(ENDPOINT.ADMIN_USER);

export const useAddAdminUser = mutateToUrl<{
  data: { q: string };
}>('POST', ENDPOINT.ADMIN_USER);

export const useDeleteAdminUser = mutateToUrl<{
  data: { q: number };
}>('PUT', ENDPOINT.ADMIN_USER);

export const useSendEmailReport = mutateToUrl<{
  data: {
    id?: number;
    email?: string;
  };
}>('POST', ENDPOINT.SEND_EMAIL_REPORT);

export const useTransferFavorites = mutateToUrl<{
  data: {
    from_email: string;
    to_email: string;
  };
}>('PUT', ENDPOINT.TRANSFER_FAVORITES);