import React, { useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Select, Popconfirm, Modal } from 'antd';
import Subscription from '@Components/UserSubscription';

import * as actions from '../../../actions';
import { ResponsiveSection, WhatsThis } from '../../../shared/Style';
import {
  useSendVerifyEmail,
  useUnblockLast2FA,
  useUpdateUserEmail,
  useUpdateUserName,
} from '../../../services/user';

import {
  generateInternalLink,
  getDateFormatFromDatePicker,
  showInfoMessage,
} from '../../../utilities';

import { useUserProfileContext } from './useUserProfileContext';
import { capitalize, isEmpty } from 'lodash';
import FAIcon from '@Components/ui/fa-icons';
import {
  Flex,
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Input,
  Label,
  ModalButton,
  Panel,
  ModalClose,
} from '@/ui';
import { Muted } from '@Components/ui/typography';
import { Table } from '@Components';

// TODO: refresh check
const BlockUserButton = connect(
  null,
  actions
)(({ blocked, q, blockUser, unblockUser }) => {
  const [blockedUser, setBlockedUser] = useState(blocked);

  const handleClick = () => {
    let promise;
    if (blockedUser) {
      /* @ts-ignore */

      promise = unblockUser(q);
    } else {
      /* @ts-ignore */

      promise = blockUser(q);
    }
    /* @ts-ignore */

    promise.then(() => setBlockedUser(old => !old));
  };

  return (
    <Button
      variant={!blockedUser ? 'outlineDestructive' : 'destructive'}
      icon={<FAIcon name="farXmark" />}
      className={blockedUser ? 'green' : ''}
      onClick={handleClick}
    >
      {blockedUser ? 'Click to unblock' : 'Click to block'} User
    </Button>
  );
});

const DeleteUserButton = connect(
  null,
  actions
)(({ q, deleteUser }) => (
  <Popconfirm
    title="Are you sure you want to delete this user?"
    onConfirm={() => {
      /* @ts-ignore */

      deleteUser(q);
    }}
  >
    <Button variant="outlineDestructive" icon={<FAIcon name="farTrash" />}>
      Delete User
    </Button>
  </Popconfirm>
));

const VerifyUserEmailButton = connect(
  null,
  actions
)(({ q, verifyUserEmail, fetchUserInfo }) => {
  /* @ts-ignore */

  const handleClick = () => verifyUserEmail(q).then(() => fetchUserInfo(q));
  return (
    <Button
      icon={<FAIcon name="farCheck" />}
      onClick={handleClick}
      title="Verify User Email"
      variant="outlineSubmit"
    >
      Verify User
    </Button>
  );
});

function UnblockLast2FAButton({ id }) {
  const { execute, isLoading } = useUnblockLast2FA();

  const handleClick = () => {
    execute({ path: { id } });
  };
  return (
    <Button
      variant="outlineSubmit"
      icon={<FAIcon name="fasCheckCircle" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Verify Last 2FA Device And Unblock
      <WhatsThis>
        When user fails to login with 2FA, the user is blocked. This will unblock the user
      </WhatsThis>
    </Button>
  );
}

const SendVerifyEmailButton = connect(
  null,
  actions
)(({ q }) => {
  const { execute, isLoading } = useSendVerifyEmail();
  return (
    <Button
      loading={isLoading}
      icon={<FAIcon name="farPaperPlane" />}
      variant="outlinePrimary"
      onClick={() => execute({ data: { email: q } })}
    >
      Send Verify Email
    </Button>
  );
});

const GrantUserAPIAccessButton = connect(
  null,
  actions
)(({ q, grantAPIAccess, fetchUserInfo, token }) => {
  const { refetch } = useUserProfileContext();
  /* @ts-ignore */

  const handleClick = () => grantAPIAccess(q).then(() => refetch());
  return (
    <Button variant="outlinePrimary" icon={<FAIcon name="farPaperPlane" />} onClick={handleClick}>
      {token ? 'Send To User' : 'Generate'}
    </Button>
  );
});

const RemoveUserAPIAccessButton = connect(
  null,
  actions
)(({ q, removeAPIAccess, fetchUserInfo }) => {
  const { refetch } = useUserProfileContext();
  /* @ts-ignore */
  const handleClick = () => removeAPIAccess(q).then(() => refetch());

  return (
    <Button variant="outlineDestructive" icon={<FAIcon name="farTrash" />} onClick={handleClick}>
      Delete Refresh Token
    </Button>
  );
});

const RemoveSSOLoginButton = connect(
  null,
  actions
)(({ q, removeSSOLogin, fetchUserInfo }) => {
  /* @ts-ignore */

  const handleClick = () => removeSSOLogin(q).then(() => fetchUserInfo(q));
  return (
    <Button variant="outlineDestructive" icon={<FAIcon name="farTrash" />} onClick={handleClick}>
      Remove 3th party
    </Button>
  );
});

const AddTrustedUserButton = connect(
  null,
  actions
)(({ q, addTrustedUser, fetchUserInfo }) => {
  /* @ts-ignore */

  const handleClick = () => addTrustedUser(q && q.toString()).then(() => fetchUserInfo(q));
  return (
    <Button variant="outlineSubmit" icon={<FAIcon name="fasCheckCircle" />} onClick={handleClick}>
      Add To Trusted User
    </Button>
  );
});
const ResetArtistPlanSelectionButton = connect(
  null,
  actions
)(({ userId, resetArtistPlanSelection }) => {
  /* @ts-ignore */

  const handleClick = () => resetArtistPlanSelection(userId);
  return (
    <Button
      variant="outlineDestructive"
      icon={<FAIcon name="farRotateLeft" />}
      onClick={handleClick}
    >
      Reset Artist Plan Selection
    </Button>
  );
});

const ResetPasswordPasswordButton = connect(
  null,
  actions
)(({ q, resetUserPassword }) => {
  /* @ts-ignore */

  const handleClick = () => resetUserPassword(q);
  return (
    <Button
      variant="outlineDestructive"
      icon={<FAIcon name="farRotateLeft" />}
      onClick={handleClick}
    >
      Reset User Password (chartmetric!)
    </Button>
  );
});

const ChangeUserEmailButton = ({ id, email }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useUpdateUserEmail();

  const handleSubmit = () => {
    if (email !== newEmail) {
      execute({
        data: {
          id,
          email: newEmail,
        },
      }).then(() => refetch(newEmail));
    }
  };
  const [newEmail, setNewEmail] = useState(email);
  return (
    <ModalButton
      header="Change User Email"
      trigger={<Button loading={isLoading} icon={<FAIcon name="farEdit" />} variant="ghost" />}
      footer={
        <ModalClose asChild>
          <Button
            onClick={handleSubmit}
            variant="submit"
            icon={<FAIcon name="farCircleCheck" />}
            loading={isLoading}
            disabled={!newEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newEmail)}
          >
            Change Email
          </Button>
        </ModalClose>
      }
    >
      <Input
        placeholder="New Email"
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
        prefix={<FAIcon name="farEnvelope" />}
      />
    </ModalButton>
  );
};

const ChangeUserNameButton = ({ id, name }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useUpdateUserName();
  const [newName, setNewName] = useState(name);

  const handleSubmit = () => {
    if (name !== newName) {
      execute({
        data: {
          id,
          name: newName,
        },
      })
        .then(() => showInfoMessage('Successfully changed user name.'))
        .then(() => refetch());
    }
  };
  return (
    <Flex align="center" justify="between" gap={2}>
      {name}
      <ModalButton
        footer={
          <ModalClose asChild>
            <Button
              onClick={handleSubmit}
              variant="submit"
              icon={<FAIcon name="farCircleCheck" />}
              loading={isLoading}
              disabled={isEmpty(newName)}
            >
              Change User Name
            </Button>
          </ModalClose>
        }
        header="Change User Name"
        trigger={<Button variant="ghost" loading={isLoading} icon={<FAIcon name="farEdit" />} />}
      >
        <Input
          placeholder="New Name"
          value={newName}
          onChange={e => setNewName(e.target.value)}
          prefix={<FAIcon name="farUser" />}
        />
      </ModalButton>
    </Flex>
  );
};

function Info({
  id,
  name,
  email,
  blocked,
  reason = 'Unspecified',
  customerInfo,
  register_date,
  image_url,
  verified,
  trusted,
  inTeam,
  teamInfo,
  refreshToken,
  source,
  ip_addresses,
  churnInfo,
}) {
  return (
    <ResponsiveSection>
      <Panel
        classNames={{
          content: 'gap-8 flex flex-col',
        }}
        header={
          <Flex align="center" gap={4}>
            <Avatar className="h-7 w-7">
              <AvatarImage src={image_url} alt={name} />
              <AvatarFallback>
                <Muted>{name?.slice(0, 2)}</Muted>
              </AvatarFallback>
            </Avatar>
            <h2>{name}</h2>
          </Flex>
        }
      >
        <Table
          title="User Info"
          options={[
            {
              label: 'User Name',
              component: <ChangeUserNameButton name={name} id={id} />,
              labelIcon: 'farTag',
            },
            {
              label: 'User Email',
              labelIcon: 'farEnvelope',
              component: (
                <Flex justify="between" align="center" gap={2}>
                  {email}
                  <ChangeUserEmailButton email={email} id={id} />
                </Flex>
              ),
            },
            {
              label: 'User ID',
              component: (
                <Flex align="center" justify="between" gap={2}>
                  {id}
                  <Button
                    variant="ghost"
                    icon={<FAIcon name="farCopy" />}
                    onClick={() => {
                      navigator.clipboard.writeText(id);
                      showInfoMessage('Copied!');
                    }}
                  />
                </Flex>
              ),
              labelIcon: 'farIdCard',
            },
            {
              label: 'Joined at',
              component: getDateFormatFromDatePicker(register_date),
              labelIcon: 'farCalendar',
            },
            {
              label: 'Verified Email',
              component: (
                <Flex justify="between" gap={12} align="center">
                  <FAIcon color="green" name={verified && 'fasCheckCircle'} />
                  {/* @ts-ignore */}
                  <VerifyUserEmailButton q={id} />
                </Flex>
              ),
              labelIcon: 'fasCheckCircle',
            },
            {
              labelIcon: 'farUserTie',
              label: 'Trusted',
              component: <FAIcon color="green" name={trusted && 'fasCheckCircle'} />,
            },
            {
              label: 'Signed up with',
              component: (
                <Flex wrap gap={8} align="center" justify="between">
                  {capitalize(source) || 'EMAIL'}
                  {/* @ts-ignore */}
                  {source && <RemoveSSOLoginButton q={id} />}
                </Flex>
              ),

              labelIcon: 'farEnvelope',
            },
            {
              label: 'Refresh Token',
              description: 'User should have API plan to use this token',
              component: (
                <Flex gap={8} wrap align="center" justify="between">
                  {!refreshToken.refresh_token && 'No refresh token'}
                  {refreshToken.refresh_token && (
                    <Button
                      variant="ghost"
                      icon={<FAIcon name="farCopy" />}
                      onClick={() => {
                        navigator.clipboard.writeText(refreshToken.refresh_token);
                        showInfoMessage('Copied!');
                      }}
                    />
                  )}
                  {/* @ts-ignore */}

                  <GrantUserAPIAccessButton q={id} token={refreshToken.refresh_token} />
                </Flex>
              ),
              labelIcon: 'fadLockKeyhole',
            },
            {
              label: 'Blocked',
              component: (
                <Flex gap={8} justify="between" align="center">
                  {blocked ? 'Blocked' : 'Not Blocked'}
                  {blocked && `This user is currently blocked (Reason: ${reason})`}
                  {/* @ts-ignore */}

                  <BlockUserButton blocked={blocked} reason={reason} q={id} />
                </Flex>
              ),
              labelIcon: 'farXmark',
            },
          ]}
        />
        {teamInfo?.managerEmail && (
          <Table
            title="Team Info"
            aside={
              <Button variant="link" asChild size="auto">
                <a
                  target="_blank"
                  href={generateInternalLink(`purchase/team-manager`, {
                    q: email,
                  })}
                >
                  See details
                </a>
              </Button>
            }
            options={[
              {
                label: 'Team Role',
                component:
                  teamInfo &&
                  (teamInfo.managerEmail === email
                    ? 'Account Manager'
                    : teamInfo.admin
                    ? 'ADMIN'
                    : 'MEMBER'),
                labelIcon: 'farTag',
              },
              {
                label: 'Manager Email',
                component: teamInfo && teamInfo.managerEmail,
                labelIcon: 'farEnvelope',
              },
            ]}
          />
        )}
        <Panel header="Actions" variant="bordered">
          <Flex wrap gap={2}>
            {/* @ts-ignore */}
            {!trusted && <AddTrustedUserButton q={id} />}
            {/* @ts-ignore */}

            <ResetArtistPlanSelectionButton userId={id} />
            <UnblockLast2FAButton id={id} />
            {/* @ts-ignore */}

            <SendVerifyEmailButton q={email} />
            {/* @ts-ignore */}

            <ResetPasswordPasswordButton q={email} />
            {/* @ts-ignore */}

            <RemoveUserAPIAccessButton q={id} />
            {/* @ts-ignore */}

            <DeleteUserButton q={id} />
          </Flex>
        </Panel>
        {ip_addresses && (
          <Panel header="Recent IP Addresses" variant="bordered">
            <ul className="text-sm">
              {ip_addresses.map(e => (
                <li>{e}</li>
              ))}
            </ul>
          </Panel>
        )}
      </Panel>
      <Subscription email={email} customerInfo={customerInfo} churnInfo={churnInfo} userId={id} />
    </ResponsiveSection>
  );
}

export default Info;
