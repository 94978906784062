import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { capitalize } from 'lodash';
import * as actions from '../../../../actions';
import { generateCmUrl } from '../../../../utilities';
import GlobalSearch from '../../../../shared/GlobalSearch/GlobalSearch';
import WhatsThis from '../../../../shared/Style/WhatsThis';
import {
  useGetArtistAlbums,
  useGetArtistTracks,
  useUpdateArtistAlbums,
  useUpdateArtistTracks,
} from '@Services/artist';
import { Button, Flex, Skeleton, Typography } from 'antd';
import FAIcon from '@Components/ui/fa-icons';
import { AntdTable, SearchInput } from '@Shared';
import { useGetEntity } from '@Services/search';
import { Panel } from '@/ui';

const COLUMNS = {
  tracks: [
    {
      Header: 'Name',
      accessor: 'name', // String-based value accessors!
      Cell: props => (
        <Flex gap="small" align="center">
          <img
            style={{ width: '38px', height: '38px', borderRadius: 6 }}
            src={props.original.image_url}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={generateCmUrl('track', { id: props.original.id })}
          >
            {props.value}
          </a>
        </Flex>
      ),
    },
    {
      Header: 'Album',
      accessor: 'album',
      Cell: props => <span>{Array.isArray(props.value) ? props.value[0].label : ''}</span>,
    },
    {
      Header: 'ISRC',
      accessor: 'isrc',
    },
    {
      Header: 'Artist',
      accessor: 'artist_names',
      Cell: props => <span>{props.value && props.value.join(', ')}</span>,
    },
    {
      Header: 'Release Date',
      accessor: 'release_dates',
      Cell: props => <span>{!props.value ? '' : props.value[0]?.split('T')[0]}</span>,
    },
  ],
  albums: [
    {
      Header: 'Name',
      accessor: 'name', // String-based value accessors!
      Cell: props => (
        <Flex gap="small" align="center">
          <img
            style={{ width: '38px', height: '38px', borderRadius: 6 }}
            src={props.original.image_url}
          />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={generateCmUrl('album', { id: props.original.cm_album })}
          >
            {props.value}
          </a>
        </Flex>
      ),
    },
    {
      Header: 'Label',
      accessor: 'label',
    },
    {
      Header: 'Number of tracks',
      accessor: 'num_tracks',
    },
    {
      Header: 'Release Date',
      accessor: 'release_date',
      Cell: props => <span>{!props.value ? '' : props.value.split('T')[0]}</span>,
    },
  ],
};

interface Props {
  type: 'albums' | 'tracks';
  q: string;
}

const UpdateTrackNAlbum = ({ type, q }: Props) => {
  const {
    data: { id },
  } = useGetEntity({ data: { q } });
  const { data, isLoading } = {
    tracks: useGetArtistTracks,
    albums: useGetArtistAlbums,
  }[type](
    { path: { id } },
    {
      disable: !id,
    }
  );

  const { execute: updateArtistTracks, isLoading: isLoadingUpdateTrack } = useUpdateArtistTracks();
  const { execute: updateArtistAlbums, isLoading: isLoadingUpdateAlbum } = useUpdateArtistAlbums();
  const [filteredData, setFilteredData] = useState(data);

  const handleUpdate = (useApi: boolean) => () => {
    if (type === 'tracks') {
      updateArtistTracks({ data: { q, useApi } });
    } else if (type === 'albums') {
      updateArtistAlbums({ data: { q } });
    }
  };

  return (
    <>
      <Panel
        header={`Update ${capitalize(type)}`}
        description="This feature syncs the artist's tracks or albums with the DSP platform (reflected approximately 10 minutes later). If incorrect tracks are still present after activating this feature, it is likely that the artist's links are incorrect. Otherwise, the DSP platform may be providing incorrect tracks."
        aside={
          type === 'tracks' ? (
            <Flex gap="middle" align="center">
              <Button
                onClick={handleUpdate(false)}
                loading={isLoadingUpdateTrack}
                className="green"
                type="primary"
                icon={<FAIcon name="farPencil" />}
              >
                <Flex align="center">
                  Update Tracks
                  <WhatsThis title="Update Track" color="white">
                    It will update Tracks using the Spotify data held by Chartmetric.
                    <br />
                    Unnecessary data (such as albums and deleted data) will not be updated.
                    <br />
                    The data from Spotify and the updated results may not perfectly match.
                  </WhatsThis>
                </Flex>
              </Button>
              <Button onClick={handleUpdate(true)} loading={isLoadingUpdateTrack} type="primary">
                <Flex align="center">
                  Sync tracks with Spotify
                  <WhatsThis title="Sync tracks with Spotify" color="white">
                    It will retrieve all Track data of the corresponding Artist from Spotify and
                    update the Chartmetric database.
                    <br /> There is a possibility that unnecessary data, including deleted data, may
                    be inserted.
                    <br /> Please use this if a specific Track data is not in the Chartmetric
                    database.
                  </WhatsThis>
                </Flex>
              </Button>
            </Flex>
          ) : (
            <Button
              onClick={handleUpdate(false)}
              loading={isLoadingUpdateAlbum}
              className="green"
              type="primary"
              icon={<FAIcon name="farPencil" />}
            >
              Update Albums
            </Button>
          )
        }
      >
        <SearchInput onChange={setFilteredData} originalData={data} />
        <AntdTable columns={COLUMNS[type]} data={filteredData ?? []} loading={isLoading} />
      </Panel>
    </>
  );
};

export default UpdateTrackNAlbum;
