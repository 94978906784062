import { cn } from 'lib/utils';
import React from 'react';

interface Props extends React.HTMLProps<HTMLHeadingElement> {}

export function H1({ className, ...props }: Props) {
  return (
    <h1
      className={cn(`scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl`, className)}
      {...props}
    />
  );
}

export function H2({ className, ...props }: Props) {
  return (
    <h2
      className={cn(`scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0`, className)}
      {...props}
    />
  );
}

export function H3({ className, ...props }: Props) {
  return (
    <h3 className={cn(`scroll-m-20 text-2xl font-semibold tracking-tight`, className)} {...props} />
  );
}

export function H4({ className, ...props }: Props) {
  return (
    <h4
      className={cn(
        `scroll-m-20 text-xl font-semibold tracking-tight text-typography mb-2`,
        className
      )}
      {...props}
    />
  );
}

export function P({ className, ...props }: Props) {
  return <p className={cn(`leading-7 [&:not(:first-child)]:mt-6`, className)} {...props} />;
}

export function Blockquote({ className, ...props }: Props) {
  // @ts-ignore
  return <blockquote className={cn(`mt-6 border-l-2 pl-6 italic`, className)} {...props} />;
}

export function Small({ className, ...props }: Props) {
  return <small className={cn(`text-sm font-medium leading-none`, className)} {...props} />;
}

export function Muted({ className, ...props }: Props) {
  return <p className={cn(`text-sm text-muted-foreground`, className)} {...props} />;
}

export function SectionTitle({ className, ...props }: Props) {
  return (
    <h2
      className={cn(`font-semibold leading-none tracking-tight text-card-title mb-2`, className)}
      {...props}
    />
  );
}
