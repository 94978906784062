import {
  useAddCompanyUnivRelative,
  useDeleteCompanyUnivRelative,
  useGetCompanyUnivPools,
  useGetCompanyUnivRelatives,
} from '@Services/userPool';
import { Panel, Table } from '@Shared/Style';
import Tree from '@Shared/Hierarchy/Hierarchy';
import { Flex, Select, Skeleton, Typography } from 'antd';
import React from 'react';
import { getDomainStr, truncatedAddress } from './utils';
import EditAddressButton from './Components/EditAddressModal';
import AddHierarchyModal from '@Shared/Hierarchy/AddHierarchyModalButton';
import { capitalize } from 'lodash';
import useURLParams from '@Hooks/useURLParams';

const CompanyUnivAccessHierarchy = () => {
  const { params } = useURLParams();
  const [selectedOrg, setSelectedOrg] = React.useState<number | undefined>(
    params?.id ? Number(params?.id) : undefined
  );
  const {
    data: relatives,
    isLoading: isLoadingRelatives,
    refetch,
  } = useGetCompanyUnivRelatives(
    {
      path: {
        id: selectedOrg!,
      },
    },
    {
      disable: !selectedOrg,
    }
  );

  console.log(selectedOrg);

  const { data: list, isLoading: isLoadingList } = useGetCompanyUnivPools();

  const { execute: addRelative } = useAddCompanyUnivRelative();
  const { execute: deleteRelative, isLoading: isLoadingDelete } = useDeleteCompanyUnivRelative();

  const data = list?.find(({ id }) => id === selectedOrg);

  return (
    <Panel title="Company-wide University Access Hierarchy" icon="farSitemap">
      <Typography.Text type="secondary">Select Organization</Typography.Text>
      <Select
        showSearch
        loading={isLoadingList}
        options={list?.map(({ id, name }) => ({ label: name, value: id }))}
        style={{ width: 200 }}
        placeholder="Select an organization"
        optionFilterProp="children"
        onChange={setSelectedOrg}
        filterOption={(input, option) =>
          JSON.stringify(option).toLowerCase().includes(input.toLowerCase())
        }
        value={selectedOrg}
      />
      {isLoadingRelatives ? (
        <>
          <br />
          <Skeleton.Node
            active
            style={{
              width: '100% !important',
              minHeight: '400px',
            }}
          />
        </>
      ) : data && relatives ? (
        <>
          <br />
          <Flex gap="middle" className="mobile-vertical">
            <Flex vertical gap="middle" style={{ marginBottom: 16, width: 350 }}>
              <Table
                layout="horizontal"
                options={[
                  {
                    label: 'Name',
                    value: data.name,
                    span: 3,
                  },
                  {
                    label: 'ID',
                    value: data.id.toString(),
                    span: 3,
                  },
                  {
                    label: 'Domains',
                    component: (
                      <Flex align="center" gap="small">
                        {truncatedAddress(getDomainStr(data.domains))}
                        <EditAddressButton
                          type="domain"
                          id={data.id}
                          addresses={getDomainStr(data.domains)}
                        />
                      </Flex>
                    ),
                    span: 3,
                  },
                  {
                    label: 'IP Range',
                    component: (
                      <Flex align="center" gap="small">
                        {truncatedAddress(data.ip_ranges)}
                        <EditAddressButton type="ip" id={data.id} addresses={data.ip_ranges} />
                      </Flex>
                    ),
                    span: 3,
                  },
                  {
                    label: 'Stripe ID',
                    value: data.stripe_customer_id,
                    span: 3,
                  },
                ]}
              />
              <AddHierarchyModal
                rootId={data.id}
                options={list?.map(({ id, name }) => ({ label: name, value: id }))}
                onSubmit={async values => {
                  await Promise.all(
                    values.map(item =>
                      addRelative({
                        data:
                          item.position === 'parent'
                            ? {
                                parent: item.id,
                                child: selectedOrg!,
                              }
                            : {
                                parent: selectedOrg!,
                                child: item.id,
                              },
                      })
                    )
                  ).finally(() => refetch());
                }}
                title={`Add Organization Relatives to ${capitalize(data.name)}`}
              />
            </Flex>
            <Tree
              name={data.name}
              id={data.id}
              items={relatives}
              onClickTag={setSelectedOrg}
              action={{
                popconfirmMessage: 'Are you sure you want to delete this relative?',
                icon: 'farTrash',
                onClick: (id, position) =>
                  deleteRelative({
                    data:
                      position === 'parent'
                        ? { parent: id, child: selectedOrg! }
                        : { parent: selectedOrg!, child: id },
                  }).finally(() => refetch()),
              }}
            />
          </Flex>
        </>
      ) : null}
    </Panel>
  );
};

export default CompanyUnivAccessHierarchy;
