import { Panel, Badge, Checkbox, Flex, Radio } from '@/ui';
import { useRequestTable } from '@Hooks';
import { useGetTrackArtistRequest, useSubmitTrackArtistRequest } from '@Services/request';
import { AntdTable, RequestSubmitButton, SearchInput } from '@Shared';
import FAIcon from '@Components/ui/fa-icons';
import { Column } from '@Shared/Table/AntdTable';
import {
  generateCmUrl,
  generateInternalLink,
  getFormattedDateString,
  getFormattedLocalDate,
} from '@Utils';
import React from 'react';
import { Link } from 'react-router-dom';

function TrackArtistRequest() {
  const {
    data,
    isLoading,
    state,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    onSearch,
    selected,
    isSubmitting,
    submit,
  } = useRequestTable(useGetTrackArtistRequest, useSubmitTrackArtistRequest);

  const handleSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } })
    );

  const columns: Column<any>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
    },
    {
      Header: 'Track',
      accessor: 'cm_track',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('track', { id: props.original.cm_track })}
        >
          {props.original.track_name}
        </a>
      ),
    },
    {
      Header: 'Old Artists',
      accessor: 'old_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: (
        <span>
          <FAIcon name="farPlus" />
          Add Artists
        </span>
      ),
      accessor: 'add_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: (
        <span>
          <FAIcon name="farTrash" />
          Delete Artists
        </span>
      ),
      accessor: 'delete_artists',
      Cell: ({ value }) =>
        value?.map(({ id, name }, index) => (
          <>
            <a
              key={id}
              target="_blank"
              rel="noreferrer noopener"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </a>
            {index !== value.length - 1 && ', '}
          </>
        )),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
      Cell: ({ value }) => (
        <Link
          to={generateInternalLink('user/user-profile', {
            qs: value,
          })}
        >
          {value}
        </Link>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      formatter: value => getFormattedLocalDate(value),
      width: 100,
      sorter: true,
    },
    {
      Header: (
        <Flex justify="center" align="center" gap={2}>
          Approve
          <Checkbox
            checked={state.approves.length === data?.length && data.length > 0}
            onClick={onClickSelectApproveAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Flex justify="center">
          <Radio
            id={`approve-${props.original.id}`}
            checked={state.approves.includes(props.original.id)}
            onClick={() => onClickApprove(props.original.id)}
          />
        </Flex>
      ),
      width: 100,
    },
    {
      Header: (
        <Flex justify="center" align="center" gap={2}>
          Decline
          <Checkbox
            checked={state.declines.length === data?.length && data.length > 0}
            onClick={onClickSelectDeclineAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Flex justify="center">
          <Radio
            id={`decline-${props.original.id}`}
            checked={state.declines.includes(props.original.id)}
            onClick={() => onClickDecline(props.original.id)}
          />
        </Flex>
      ),
      width: 100,
    },
  ];

  return (
    <Panel
      icon="farCompactDisc"
      aside={<SearchInput onChange={onSearch} originalData={data} />}
      header={
        <Flex align="center" gap={2}>
          Track&apos;s Artist Change Request
          <Badge color="blue">{data?.length ?? '0'}</Badge>
        </Flex>
      }
      footer={
        <RequestSubmitButton
          //   @ts-ignore
          columns={columns.slice(0, columns.length - 3)}
          onClickSubmit={handleSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      }
      classNames={{
        footer: 'justify-center',
      }}
      description="Temporary disabled request from web app."
    >
      <AntdTable columns={columns} data={state.filteredData} loading={isLoading} />
    </Panel>
  );
}

export default TrackArtistRequest;
