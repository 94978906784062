import React from 'react';
import { isEmpty } from 'lodash';
import { showInfoMessage, getFormattedDateString } from '../../../../utilities';
import { Button, Card, CardContent, CardHeader, CardTitle, Flex, Muted, Panel } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import useCampaign from '../useCampaign';
import AddCampaignSection from '../Components/AddCampaignSection';

function CampaignForUser() {
  const campaignProps = useCampaign();
  const { artistIds, content, coupon, expiredDate, priceId, enableCoupon } = campaignProps;

  const query = btoa(
    `priceId=${priceId as string}${content ? `&content=${content['id']}` : ''}${
      coupon ? `&couponCode=${coupon.id}` : ''
    }${artistIds.length > 0 ? `&artistIds=${artistIds.join(',')}` : ''}${
      expiredDate ? `&expiredDate=${getFormattedDateString(expiredDate)}` : ''
    }`
  );

  const url = `https://app.chartmetric.com/campaign#code=${query}`;

  const handleCopy = url => () => {
    showInfoMessage('Copied!');
    navigator.clipboard.writeText(url);
  };

  const checkFormFilled = () => !(isEmpty(priceId) || !expiredDate || (enableCoupon && !coupon));

  return (
    <>
      <Panel
        header="Campaign For User"
        description="When users approach the link, they will be directed to the Chartmetric
          website. Once there, they can log in or sign up and then navigate to the Campaign page.
        "
      >
        <AddCampaignSection {...campaignProps} includeExpiredDate />

        {url && checkFormFilled() && (
          <Card>
            <CardHeader className="flex-row items-center gap-2">
              <CardTitle>Campaign Link</CardTitle>
              <Button
                id={url}
                className="green"
                onClick={handleCopy(url)}
                icon={<FAIcon name="farCopy" />}
              />
            </CardHeader>
            <CardContent>
              <Flex vertical>
                <a href={url} target="_blank" rel="noopener noreferrer" className="text-sm">
                  {url}
                </a>
                <br />
                <Muted type="secondary">
                  The Stripe plans provided in the campaign will automatically renew once the plan's
                  duration has ended. A plan priced at $0 means that once a user subscribes, they
                  can use it for free indefinitely. Avoid using the $0 plan and use a coupon
                  instead.
                </Muted>
                <Muted>
                  Please make sure to test the campaign link to ensure it works as expected.
                </Muted>
              </Flex>
            </CardContent>
          </Card>
        )}
      </Panel>
    </>
  );
}

export default CampaignForUser;
