import React, { useState } from 'react';
import { useSendPushNotification, useSendPushNotificationAll } from '@Services/user';
import GlobalSearch from '../../../shared/GlobalSearch/GlobalSearch';
import { showInfoMessage } from '@Utils';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Checkbox,
  Flex,
  Input,
  Label,
  Muted,
  Panel,
  Popconfirm,
  Badge,
  SectionTitle,
} from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import { Table } from '@Components';
import { capitalize } from 'lodash';
import { cn } from '@/lib/utils';

interface Data {
  path?: string;
}

const TARGET_SCREEN_SUGGESTIONS = {
  artist: {
    info: '/artist/:id/info',
    stats: '/artist/:id/stats',
    tracks: '/artist/:id/tracks',
    playlist: '/artist/:id/playlist',
    chart: '/artist/:id/chart',
    audience: '/artist/:id/audience',
  },
  track: {
    info: '/track/:id/info',
    albums: '/track/:id/albums',
    playlists: '/track/:id/playlists',
  },
  album: {
    info: '/album/:id/info',
    tracks: '/album/:id/tracks',
    playlists: '/album/:id/playlists',
  },
  playlist: {
    info: '/playlist/:platform/:id/info',
    tracks: '/playlist/:platform/:id/tracks',
  },
  curator: {
    info: '/curator/:platform/:id/info',
    playlists: '/curator/:platform/:id/playlists',
  },
  'bottom tabs': {
    milestones: '/(tabs)/milestones',
    comparisons: '/(tabs)/comparisons',
    search: '/(tabs)/search',
    playlists: '/(tabs)/playlists',
    charts: '/(tabs)/charts',
  },
  user: {
    settings: '/settings',
    notification: '/notification',
  },
};

function PushNotificationManager() {
  const [emails, setEmails] = useState<string[]>([]);
  const [allUsers, setAllUsers] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [data, setData] = useState<Data>({
    path: undefined,
  });
  const [originPath, setOriginPath] = useState<string>('');

  const { execute: sendPushNotification, isLoading } = useSendPushNotification();
  const { execute: sendPushNotificationAll, isLoading: isLoadingAll } =
    useSendPushNotificationAll();

  const handleSubmit = () => {
    if (allUsers)
      sendPushNotificationAll({
        data: { title, message, data },
      }).then(
        ({
          data: {
            obj: { message },
          },
        }) => showInfoMessage(message)
      );
    else
      sendPushNotification({
        data: { emails, title, message, data },
      }).then(
        ({
          data: {
            obj: { message },
          },
        }) => showInfoMessage(message)
      );
  };

  const handleSearchSubmit = userId => {
    if (emails.includes(userId)) return;
    setEmails(old => [...old, userId]);
  };

  const handleChangeCheckbox = () => {
    setAllUsers(old => !old);
  };

  const paramFields = originPath ? originPath?.split('/')?.filter(e => e.includes(':')) : [];

  const handleClearTargetScreen = (value?: string) => {
    if (!value || originPath === value) {
      setOriginPath('');
      setData(old => ({ ...old, path: undefined }));
      return;
    }
    setOriginPath(value);
    setData(old => ({ ...old, path: value }));
  };

  const disabled =
    title === '' ||
    message === '' ||
    (emails.length === 0 && !allUsers) ||
    data?.path?.includes(':') ||
    data?.path?.includes('//');

  return (
    <Panel header="Push Notification Manager" icon="farMobile">
      <Flex vertical gap={2} justify="end" className="mb-1">
        <SectionTitle>Target Users</SectionTitle>
        <Checkbox checked={allUsers} onCheckedChange={handleChangeCheckbox}>
          Send to all Users
        </Checkbox>
        <GlobalSearch
          types={['user']}
          onSubmit={handleSearchSubmit}
          name="Search User"
          buttonIcon="farSearch"
          buttonLabel="Add"
          icon="fadMobile"
          onChange={() => {}}
          disabled={allUsers}
          size="middle"
        />
        {emails.length > 0 && (
          <Flex gap={2} vertical className="mt-1">
            {emails.map((userId, idx) => (
              <Label key={userId} className="flex flex-row items-center gap-2">
                {idx + 1}. {userId}
                <FAIcon
                  name="farXmark"
                  color="red"
                  onClick={() => setEmails(old => old.filter(id => id !== userId))}
                />
              </Label>
            ))}
          </Flex>
        )}
      </Flex>
      <div>
        <SectionTitle>Details</SectionTitle>
        <Table
          withoutBorder
          options={[
            {
              label: 'Title *',
              component: (
                <Input placeholder="Title" value={title} onChange={e => setTitle(e.target.value)} />
              ),
            },
            {
              label: 'Message *',
              component: (
                <Input
                  placeholder="Message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
              ),
            },
            {
              label: 'Target Screen',
              component: (
                <>
                  <div className={cn(data?.path ? 'hidden' : '')}>
                    <Muted className="mb-2">Click to select target screen</Muted>
                    <div className="grid grid-cols-2 gap-2">
                      {Object.entries(TARGET_SCREEN_SUGGESTIONS).map(([key, value]) => (
                        <Card>
                          <CardHeader className="py-4 border-b border-border bg-decorative text-decorative-foreground">
                            <CardTitle>{capitalize(key)}</CardTitle>
                          </CardHeader>
                          <CardContent className="p-2">
                            {Object.entries(value).map(([key, value]) => (
                              <Button
                                variant={originPath === value ? 'outlineSubmit' : 'ghost'}
                                onClick={() => handleClearTargetScreen(value)}
                              >
                                {capitalize(key)}
                              </Button>
                            ))}
                          </CardContent>
                        </Card>
                      ))}
                    </div>
                  </div>
                  <div className={cn(data?.path ? '' : 'hidden')}>
                    <Flex align="center" justify="between">
                      <Badge color="blue">{data?.path}</Badge>
                      <Button variant="link" onClick={() => handleClearTargetScreen()}>
                        Clear
                      </Button>
                    </Flex>
                    {paramFields.length > 0 && (
                      <div className="grid grid-cols-2 gap-2 mt-2">
                        {paramFields.map(field => (
                          <Flex key={field} align="center" gap={2}>
                            <Label className="capitalize">{field.replace(':', '')}</Label>
                            <Input
                              value={
                                data?.path
                                  ?.split('/')
                                  .find(e => e.includes(field))
                                  ?.includes(':')
                                  ? undefined
                                  : data?.path?.split('/').find(e => e.includes(field))
                              }
                              placeholder={field}
                              onChange={e => {
                                const paramIndex = originPath
                                  ?.split('/')
                                  .findIndex(e => e.includes(field));
                                if (!paramIndex || paramIndex === -1) return;
                                const path = data?.path?.split('/');
                                if (!path) return;
                                path[paramIndex] = e.target.value;
                                setData(old => ({ ...old, path: path.join('/') }));
                              }}
                            />
                          </Flex>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              ),
            },
          ]}
        />
      </div>
      <Flex justify="center">
        <Popconfirm
          loading={isLoadingAll || isLoading}
          title="Be careful this action"
          onConfirm={handleSubmit}
          message={
            <div>
              Are you sure you want to send push notification to{' '}
              {allUsers ? 'all users using mobile app?' : 'selected users?'}
            </div>
          }
        >
          <Button
            variant="submit"
            loading={isLoadingAll || isLoading}
            icon={<FAIcon name="farPaperPlane" />}
            disabled={disabled}
          >
            Send Push notification
          </Button>
        </Popconfirm>
      </Flex>
    </Panel>
  );
}

export default PushNotificationManager;
