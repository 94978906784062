import { EditArtistProps } from 'screens/EditData/EditEntity/Artist/useArtistInfo';
import { persistGetToUrl } from './helpers';

const ENDPOINT = {
  ENTITY: '/admin/search',
};

export interface ArtistDataResponse extends Omit<EditArtistProps, 'fetchData'> {}

export const useGetEntity = persistGetToUrl<{
  data: {
    q: string;
  };
}>(ENDPOINT.ENTITY, {});
