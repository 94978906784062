import { capitalize } from 'lodash';
import React, { useState } from 'react';
import ReactTable from 'react-table';
import {
  useGetClaimRequestStatus,
  useGetClaimRequest,
  useApproveClaimRequest,
  useDeclineClaimRequest,
} from '../../../../services/request';
import { getFormattedLocalDate } from '../../../../utilities';
import useRequestTable from '../../../../hooks/useRequstTable';
import { ActionInput } from '../../../../shared/Style';
import DataTableSearch from '../../../../shared/Table/SearchInput';
import RequestSubmitButton from '../../../../shared/Table/SubmitModal';
import { CLAIM_COLUMNS, CLAIM_SUBMIT_MODAL_COLUMNS } from './columns';
import { REASON_TEMPLATES } from './constants';
import { AntdTable } from '@Shared';
import { Badge, Flex, Panel, Separator } from '@/ui';
import CheckClaimStatus from './CheckClaimStatus';

const SEARCH_FILTERS = [
  {
    label: 'User Email',
    value: 'user_email',
  },
  {
    label: 'Verification Code',
    value: 'verification_code',
  },
];

function ClaimRequest() {
  const {
    data,
    isLoading,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    onSearch,
    refetch,
    selected,
    state,
  } = useRequestTable(useGetClaimRequest, null);

  const [reasonTemplates, setReasonTemplates] = useState(REASON_TEMPLATES);
  const { execute: approve, isLoading: isApproving } = useApproveClaimRequest();
  const { execute: decline, isLoading: isDeclining } = useDeclineClaimRequest();
  const [reasons, setReasons] = useState({});

  const onClickSubmit = async () => {
    await Promise.all([
      ...state.approves.map(id => approve({ path: { id } })),
      ...state.declines.map(id =>
        decline({
          path: { id },
          data: { reason: reasons[id] ?? '' },
        })
      ),
    ]);
    refetch();
  };

  const handleAddReasonOption = (reason: string) => {
    setReasonTemplates([...reasonTemplates, reason]);
  };

  const onChangeReason = (id: number, reason: string) => {
    setReasons({ ...reasons, [id]: reason });
  };

  return (
    <>
      <CheckClaimStatus />
      <Panel
        icon="farHand"
        aside={<DataTableSearch filters={SEARCH_FILTERS} originalData={data} onChange={onSearch} />}
        header={
          <Flex align="center" gap={2}>
            Claim Request
            <Badge color="blue">{data?.length}</Badge>
          </Flex>
        }
      >
        <AntdTable
          key="table"
          loading={isLoading}
          data={!state.filteredData ? data : state.filteredData}
          columns={CLAIM_COLUMNS({
            onClickApprove,
            onClickDecline,
            approves: state.approves,
            declines: state.declines,
            onClickSelectApproveAll,
            onClickSelectDeclineAll,
            data,
          })}
        />
        <Separator />
        <Flex justify="center" gap={2}>
          <RequestSubmitButton
            columns={CLAIM_SUBMIT_MODAL_COLUMNS({
              declines: state.declines,
              onChangeReason,
              reasons,
              handleAddReasonOption,
              reasonTemplates,
            })}
            onClickSubmit={onClickSubmit}
            approveRequest={state.approves}
            declineRequest={state.declines}
            data={data ?? []}
            loading={isApproving || isDeclining}
            selected={selected}
          />
        </Flex>
      </Panel>
    </>
  );
}

export default React.memo(ClaimRequest);
