import React from 'react';

/**
 * @deprecated use Panel grid prop instead
 */
function ResponsiveSection({ children }) {
  return (
    <section
      className="grid 
      xxl:grid-cols-3
    xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4"
    >
      {children}
    </section>
  );
}

export default ResponsiveSection;
